/**
 * Created by Hennadiy on 05.03.2023
 */

import {getRoutes, Routing} from 'gears-react';

import {CheckQuizSessionRole} from 'processes';

import {create, list, public_quiz, rooms} from 'pages/quiz';
import {path} from 'shared/const';

import {client_routes, creator_routes} from './session';
import {question_routers} from './questions';

const protectedRoutes = getRoutes([
    ['*', [<div>error</div>]],
    [`/:${path.quiz.session_id}`, [<CheckQuizSessionRole client_routes={client_routes}
                                                                 creator_routes={creator_routes}/>]],
    ['/', list],
    [path.quiz.create, create],

    [`${path.quiz.list}/:${path.quiz.quiz_id}${path.quiz.questions}/*`, [<Routing
        routes={question_routers}/>]],
    [`${path.quiz.list}/:${path.quiz.quiz_id}${path.quiz.questions}/*`, [<Routing
        routes={question_routers}/>]],
    [`${path.quiz.list}/:${path.quiz.quiz_id}${path.quiz.public}`, public_quiz]
]);

export const quiz = {protectedRoutes};
