/**
 * Created by Hennadiy on 15.02.2023
 */

import {$axios} from './instances';
import {AxiosResponse} from 'axios';

import {urls} from './urls';
import * as types from './types';

export default class Services {
    static async getQuizSessionList(
        {
            signal
        }: AbortController,
        {
            category,
            creator,
            history,
            i_org,
            i_part,
            limit,
            offset,
            ordering,
            question_time,
            search,
            type
        }: types.IGetQuizSessionListParams): Promise<AxiosResponse<types.IListDataResponse<types.IGetQuizSession>>> {
        return await $axios.get<types.IListDataResponse<types.IGetQuizSession>>(urls.getQuizSessionList(), {
            signal: signal,
            params: {
                category,
                creator,
                history,
                i_org,
                i_part,
                limit,
                offset,
                ordering,
                question_time,
                search,
                type
            }
        });
    }
}