
import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import {options} from './mui-theme-options';


const theme = createTheme(options);
export const withMuiTheme = (component: () => React.ReactNode) => () => (
    <ThemeProvider theme={theme}>
        {component()}
    </ThemeProvider>
);