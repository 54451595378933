/**
 * Created by Hennadiy on 13.02.2023
 */
import {ServiceStore} from 'gears-react';
import {action, makeAutoObservable} from 'mobx';

import Services from '../api/services';
import * as types from '../api/types';

export class Store {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly getIsQuizSessionCreator = new ServiceStore<[AbortController, types.IGetIsCreatorParams],
        types.IGetIsCreatorResponse>(Services.getIsQuizSessionCreator, true);
}