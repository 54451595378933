import React, {CSSProperties} from 'react';
import styled from 'styled-components';
import {useWindowSize} from 'usehooks-ts';


type Props = {
    children?: React.ReactNode;
    style?: CSSProperties;
}
export const MinHeightBlock = ({children, style}: Props) => {
    const {height} = useWindowSize();

    return (
        <Root height={height} style={style}>
            {children}
        </Root>
    );
};


type RootProps = {
    height: number;
}

const Root = styled.div`
  width: 100%;
  padding: 16px;
    //min-height: ${({height}: RootProps) => height}px;
  //height: 100%;
`;