import React, {useContext} from 'react';
import {Button} from '@mui/material';
import {Global} from 'iconsax-react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';

import {Context} from "../lib/context";

import {useTranslationButton} from "../lib/hooks/useTranslationButton";

import {MenuRoot} from "./MenuRoot";
import {Backdrop} from "./Backdrop";
import {TBRoot} from "./TBRoot";

type Props = {
    color?: string;
}

export const TranslationButtonV1 = observer(({color}: Props) => {
    const {
        open,
        menu_width,
        menu_height,
        refBtn,
        language,
        x, y,
        handleOpenUserMenu,
        handleCloseUserMenu,
        setLanguage,
    } = useTranslationButton();

    const {store} = useContext(Context);

    return (
        <TBRoot>
            <TextButton ref={refBtn} color={color} onClick={handleOpenUserMenu}>
                <Global
                    size="24"
                    color={color || "#262626"}
                />
                <span>
                   {store.lang.get(language)?.label}
                </span>
            </TextButton>

            {open && <Backdrop onClick={handleCloseUserMenu}>
                <Menu x={x} y={y} w={menu_width} h={menu_height}>
                    {store.lang.getAll().map((option) => (
                        <MenuItem
                            onClick={(e) => {
                                setLanguage(option.value);
                                store.lang.changeLanguage(option.value);
                                handleCloseUserMenu();
                            }}
                            key={option.value}>
                            <Button
                                variant="text" fullWidth disableElevation color={'inherit'} sx={{
                                borderRadius: 0,
                                height: '56px',
                                textTransform: 'inherit',
                                justifyContent: 'center',
                                padding: '0 16px',
                                fontSize: '16px',
                                fontWeight: '400',
                                gap: '8px',
                                textAlign: 'center'
                            }}>
                                {option.label}
                            </Button>
                        </MenuItem>
                    ))}
                </Menu>
            </Backdrop>}
        </TBRoot>
    );
});

const TextButton = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: ${({color}: Props) => `${color}` || "#262626"};
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;

  * {
    font-weight: 400;
    font-size: 16px;
    color: ${({color}: Props) => `${color}` || "#262626"};
  }
`;

const Menu = styled(MenuRoot)`
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.26);
  border-radius: 6px;
`;

const MenuItem = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: #262626;
`;