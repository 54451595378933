/**
 * Created by Hennadiy on 18.02.2023
 */
import styled from "styled-components";

type MenuProps = {
    y?: number;
    x?: number;
    w?: number;
    h?: number;
}

export const MenuRoot = styled.div`
  position: absolute;

  width: ${({w}: MenuProps) => w || 0}px;
  height: ${({h}: MenuProps) => h || 0}px;
  top: ${({y}: MenuProps) => y || 0}px;
  left: ${({x}: MenuProps) => x || 0}px;

  z-index: 99999999;

  overflow-x: hidden;
  overflow-y: scroll;
  
  animation-name: growMenu;
  animation-duration: .2s;

  @keyframes growMenu {
    from {
      top: 0;
      transform: scale(0.1);
    }
    to {
      top: ${({y}: MenuProps) => y || 0}px;
      transform: scale(1);
    }
  }

`;