import {withGears} from 'gears-react';
import compose from 'compose-function';

import {withTranslationStore} from 'features/general';

import {ums} from 'shared/api/ums';
import {smms} from 'shared/api/smms';
import {qms} from 'shared/api/qms';
import {gms} from 'shared/api/gms';

import {withMuiTheme} from './mui-theme-provider/with-mui-theme';
import {withAlert} from './with-alert';
import {withRouter} from './with-router';
import {withStore} from './with-store';
import {WithMuiLocalization} from './with-mui-localization';

export const withProviders = compose(
    withMuiTheme,
    WithMuiLocalization,
    withGears,
    withStore,
    withTranslationStore,
    ums.account.provider,
    gms.client.provider,
    smms.client.provider,
    qms.client.provider,
    withRouter,
    withAlert,
);