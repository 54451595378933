import React from 'react';
import styled from 'styled-components';
import {useWindowSize} from 'usehooks-ts';

type Props = {
    children: React.ReactNode;
}

const SizeProperties = ({children}: Props) => {
    const {height} = useWindowSize();

    return (
        <Root className={'MAX_WIDTH_PAGE'} height={height}>
            {children}
        </Root>
    );
};

export default SizeProperties;

type RootProps = {
    height: number;
}

const Root = styled.div`
  position: relative;
  background-color: var(--bg-gray);
  min-height: ${({height}: RootProps) => height}px;
`;