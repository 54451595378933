import React, {useContext, useEffect, useState} from 'react';

import {Context as globalContext} from 'shared/lib/context';
import {Context as UmsAccountContext} from 'shared/api/ums/account';
import {checkIsPhone, containsOnlyNumbers} from 'shared/lib/helpers';

import {Context} from './context';
import {Props} from './props';

export const useAuth = (props: Props) => {
    const abortController = new AbortController();
    const {store} = useContext(Context);

    const global = useContext(globalContext);
    const account = useContext(UmsAccountContext);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, []);

    const [error, setError] = useState(false);
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        store.postAuth.request(abortController, containsOnlyNumbers(password) && password.trim().length <= 6 ?
            checkIsPhone(login) ?
                {login_params: 'phone_pvc', phone: login, pvc: password} :
                {login_params: 'username_pvc', username: login, pvc: password}
            : checkIsPhone(login) ?
                {login_params: 'phone_password', phone: login, password: password} :
                {login_params: 'username_password', username: login, password: password}
        ).then(r => {
            console.log(r);
            if (r?.status === 201) {

                if (!store.postAuth.get().is_new_user) {
                    localStorage.setItem('access', r!.data.access);
                    localStorage.setItem('refresh', r!.data.refresh);

                    account.store.details.request().then(r => {
                        if (r.status !== 200) {
                            account.store.setIsAuth(false);
                        } else {
                            account.store.setIsAuth(true);
                        }
                    });

                } else {
                    setError(true);
                }

                setError(false);
            } else {
                if (!abortController.signal.aborted) {
                    global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                }
            }
        });
    };

    return {
        login,
        setLogin,
        password,
        setPassword,
        error,
        handleSubmit,
        loader: store.postAuth.loader.get(),
    };
};
