import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';

import {Card, CardsLabel} from 'shared/ui/components/quiz';
import {InfiniteScroll} from 'shared/ui/components/general';
import {checkNull, getFormatDate} from 'shared/lib/helpers';
import {NoData} from 'shared/ui/components/client';

import {useList} from '../lib/hooks';
import {Context} from '../lib/context';
import {withProviders} from '../providers';
import no_data_img from '../assets/no_data_img.svg';
import {useNavigate} from 'react-router-dom';
import {path} from 'shared/const';

const List = observer(() => {
    const {store} = useContext(Context);
    useList();
    const loaders = [1, 2];
    const navigate = useNavigate();

    if (store.loading) {
        return <Root>
            <CardsLabel>
                {loaders.map((e, i) => <Card key={i} isLoading={true}/>)}
            </CardsLabel>
        </Root>;
    }

    return (
        <React.Fragment>
            <Root>
                <InfiniteScroll offset={store.offset} nextOffset={store.getQuizSessionList.get().next_offset}
                                setOffset={(offset) => {
                                    store.setOffset(offset);
                                }}
                                isLoading={store.getQuizSessionList.loader.get()}
                                totalCount={store.getQuizSessionList.get().count}>

                    <CardsLabel>
                        {store.getList().map((item) =>
                            <Card
                                onClick={() => navigate(path.quiz.getSession(item.id))}
                                key={item.id}
                                isLoading={false}
                                contentProps={{
                                    src: `${checkNull(item.quiz.images[0]) ? item.quiz.images[0].file : ''}`,
                                    title: `${item.quiz.name}`,
                                    creator: `${checkNull(item.org) || item.creator}`,
                                    category: 'category',
                                    question_number: item.question_number,
                                    time: getFormatDate(item.expected_date),
                                    users: `${item.participant_number}/${item.expected_participants || '∞'}`,
                                    type: checkNull(item.type)
                                }}
                                actionsProps={{
                                    title: 'Entrance fee',
                                    entrance_fee: `Free ${item.is_code ? '(with code)' : ''} `,
                                    blue_label_title: `Join Room #${item.id}`
                                }}
                            />)}

                        {
                            store.getQuizSessionList.loader.get() &&
                            loaders.map((e, i) => <Card key={i} isLoading={true}/>)
                        }
                    </CardsLabel>

                </InfiniteScroll>

                {
                    !store.getQuizSessionList.loader.get() &&
                    store.getQuizSessionList.get().results !== undefined &&
                    store.getQuizSessionList.get().results.length <= 0 &&
                    <NoData
                        title={'No session found'}
                        subtitle={'Please reload page'}
                        src={no_data_img}
                        img_width={'160px'}
                    />
                }
            </Root>
        </React.Fragment>
    );
});

const Lazy = () => {
    return <List/>;
};

export default withProviders(Lazy);

const Root = styled.div`
  width: 100%;
  padding: 0 16px calc(var(--header-height) + 16px) 16px;
  position: relative;
`;