import {Navigate} from 'react-router-dom';
import {Routing, getRoutes} from 'gears-react';

import * as page from 'pages';

import {BottomBar} from 'features/client';
import {path} from 'shared/const';

import {quiz} from './quiz';
import {client} from './client';

export const authRoutes = getRoutes([
    ['*', [<BottomBar/>, <Routing routes={client.publicRoutes}/>]],
    [path.auth.login, page.auth.login],
    [path.auth.signup, page.auth.signup],

    ['/', page.client.landing],
]);

export const protectedRoutes = getRoutes([
    ['*', [<BottomBar/>, <Routing routes={client.protectedRoutes}/>]],
    [path.auth.login, [<Navigate to={'/'}/>]],
    [path.auth.signup, [<Navigate to={'/'}/>]],

    [`${path.quiz.main}/*`, [<Routing routes={quiz.protectedRoutes}/>]],
]);