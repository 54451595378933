import * as React from 'react';
import styled from '@emotion/styled';
import {TextField, TextFieldProps} from '@mui/material';

export function TextArea({...props}: TextFieldProps) {
    return (
        <StyledTextField
            {...props}
            multiline
            rows={4}
            variant={'filled'}
            InputProps={{disableUnderline: true, autoComplete: 'off', autoFocus: false}}
            fullWidth
        />
    );
}

const StyledTextField = styled(TextField)`
  background-color: rgba(255, 255, 255, 0) !important;

  .MuiInputBase-root {
    background-color: ${(props) => props.style?.backgroundColor || '#F2F2F2'};
    border-radius: 10px;
    border: 1px solid ${(props) => props.error ? 'var(--error)' : 'rgba(127, 146, 160, 0.2)'};
    transition: all .2s;
    
    :hover {
      background-color: ${(props) => props.style?.backgroundColor || '#F2F2F2'};
    }
  }

  .MuiInputBase-root.Mui-focused {
    border: 1px solid ${(props) => props.error ? 'var(--error)' : 'var(--main)'};
    background-color: ${(props) => props.style?.backgroundColor || '#F2F2F2'};
  }
`;