import React, {CSSProperties} from 'react';
import styled from 'styled-components';

type Props = {
    children?: React.ReactNode;
    style?: CSSProperties;
}

export const Footer = ({children, style}: Props) => {
    return <Root style={style} className={'MAX_WIDTH_PAGE_FIXED'}>
        {children}
    </Root>;
};

const Root = styled.div`
  width: 100%;
  z-index: 999999;
  bottom: 0;
  left: 0;
  position: fixed;
  height: var(--header-height);

  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);

`;
