/**
 * Created by Hennadiy on 14.02.2023
 */
import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {ICategoryDrawerValue, IQuestionTimeDrawerValue} from 'features/quiz';

import {qms} from 'shared/api/qms';
import {Context} from 'shared/lib/context';
import {IError, useErrors} from 'shared/lib/hooks';
import {checkNull} from 'shared/lib/helpers';
import {path} from 'shared/const';

export const useCreateQuiz = () => {
    const {store} = useContext(qms.client.Context);
    const global = useContext(Context);
    const navigate = useNavigate();

    const error_array: IError[] = [
        {field: 'name', error: false},
        {field: 'category', error: false},
        {field: 'description', error: false},
        {field: 'question_time', error: false},
        {field: 'reward', error: false},
    ];

    const {errors, getError, findErrorFromArray, setErrors} = useErrors(error_array, true);

    const [category, setCategory] = useState<ICategoryDrawerValue>({value: '', id: 0});
    const [question_time, setQuestionTime] = useState<IQuestionTimeDrawerValue>({value: 5, id: 1});

    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [reward, setReward] = useState<string>('');

    const [image, setImage] = useState<any>(null);


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        store.quiz.create.request({
            category: checkNull(category?.id),
            name,
            add_images: checkNull(image),
            description,
            question_time: checkNull(question_time?.value),
            reward
        }).then(r => {
            console.log(r);
            if (r!.status === 201) {
                navigate(path.quiz.getQuestions(store.quiz.create.get().id));
                setErrors(error_array);
            } else {
                global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                findErrorFromArray(r.data);
            }

        });
    };

    return {
        handleSubmit,
        loader: store.quiz.create.loader.get(),
        category, setCategory,
        errors, getError,
        name, setName,
        description, setDescription,
        question_time, setQuestionTime,
        reward, setReward,
        image, setImage
    };
};