import React, {useContext, useEffect, useState} from 'react';

import {Context as globalContext} from 'shared/lib/context';

import {Context} from './context';
import {Props} from './props';

export const useCheckUsername = (props: Props) => {
    const abortController = new AbortController();
    const {store} = useContext(Context);
    const global = useContext(globalContext);

    const [error, setError] = useState(false);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, []);

    const [disabled, setDisabled] = useState<boolean>(true);

    const [is_existed, setIsExisted] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        setDisabled(true);
        setIsExisted(undefined);

        if (props.username.trim() !== '') {
            const timer = setTimeout(() => {
                store.getCheckUsername.request(abortController, {username: props.username}).then(r => {
                    console.log(r);

                    if (r?.status === 200) {

                        setIsExisted(store.getCheckUsername.get().is_existed);
                        setError(false);

                        if (store.getCheckUsername.get().is_existed) {
                            global.store.alert.set(true, 'error', 'Пользователь существует');
                        } else {
                            setDisabled(false);
                        }

                    } else {
                        if (!abortController.signal.aborted) {
                            global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                        }
                        setError(true);
                    }

                });
            }, 500);

            return () => clearTimeout(timer);

        }

    }, [props.username]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!is_existed && !disabled) {
            props.next();
        }
    };

    return {
        error,
        loader: store.getCheckUsername.loader.get(),
        handleSubmit,
        is_existed,
        disabled
    };
};
