import React from "react";
import styled from "styled-components";

import {AnimText} from "../animText/AnimText";
import {Container} from "../../shared/StyledComponents";

import style from './Main.module.css'

export const Main = () => {
    return (
        <Root>
            <Container>
                <div className={style.mainPage} id='main'>
                    <AnimText/>
                    <div className={style.mainTextContainer}>
                        <div className={style.mainText}>
                            Create your future with us, move forward with DWED!
                            Don`t worry everything is done!
                        </div>
                    </div>
                </div>
            </Container>
        </Root>
    )
}

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

