import {ServiceStore} from 'gears-react';
import {action, makeAutoObservable} from 'mobx';

import {Account} from './services';
import {
    AccountCreateRequest,
    AccountCreateResponse,
    AccountDetail,
    AuthParams,
    AuthRequest,
    AuthResponse, ChangePasswordRequest,
    CheckUserNameParams,
    CheckUserNameResponse,
    CheckUserResponse
} from './types';


export class Store {
    constructor() {
        makeAutoObservable(this);
    }

    private isAuth = false;

    @action
    public getIsAuth() {
        return this.isAuth;
    }

    @action
    public setIsAuth(isAuth: boolean) {
        this.isAuth = isAuth;
    }

    public readonly details = new ServiceStore<[], AccountDetail>(Account.get, true);
    public readonly get_another_user = new ServiceStore<[], AccountDetail>(Account.get_another_user, false);
    public readonly auth = new ServiceStore<[AuthParams, AuthRequest], AuthResponse>(Account.auth, false);
    public readonly auth_another_user = new ServiceStore<[AuthParams, AuthRequest], AuthResponse>(Account.auth, false);
    public readonly check_user = new ServiceStore<[AuthParams, AuthRequest], CheckUserResponse>(Account.check_user, false);
    public readonly create = new ServiceStore<[AccountCreateRequest], AccountCreateResponse>(Account.create, false);

    public readonly refresh_token = new ServiceStore<[{ refresh: string }], AuthResponse>(Account.refresh_token, false);
    public readonly check_username = new ServiceStore<[CheckUserNameParams], CheckUserNameResponse>(Account.check_username, false);

    public readonly change_password = new ServiceStore<[ChangePasswordRequest], any>(Account.change_password, false);
}