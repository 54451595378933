import React, {useContext, useEffect, useState} from 'react';
import {Button, Skeleton} from '@mui/material';
import {Carousel} from 'antd';
import {ArrowLeft2} from 'iconsax-react';
import {observer} from 'mobx-react-lite';
import {smms} from 'shared/api/smms';
import {IGetPostAdvertParams} from 'shared/api/smms/client/types';
import {FullScreenDialog} from 'shared/ui/components/general/FullScreenDialog/components/FullScreenDialog';
import styled from 'styled-components';

export const Ads = observer(({place}: IGetPostAdvertParams) => {
    const {store} = useContext(smms.client.Context);
    const [open, setOpen] = useState<boolean>(false);
    const [flag, setFlag] = useState<boolean>(false);
    const [time, setTime] = useState<number>(1);

    useEffect(() => {
        if (flag && !open) {
            store.getPostAdvert.request({place: place}).then(r => {
                console.log(r);
                setFlag(false);
                if (r.status === 200) {
                    setTime(store.getPostAdvert.get().length);
                }
            });
        }
    }, [flag, open]);

    useEffect(() => {
        if (!flag) {
            const timer = setTimeout(() => {
                if (time <= 0) {
                    setFlag(true);
                    return;
                }
                setTime(time - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [time, flag]);

    const handleOpen = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (store.getPostAdvert.get().post !== undefined) {
            setOpen(true);
        }
    };

    const handleClose = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(false);
    };

    return (
        <Root
            loaded={store.getPostAdvert.get().post !== undefined ? store.getPostAdvert.get().post.media_first.image : ''}
            onClick={handleOpen}>

            <FullScreenDialog
                open={open}
            >
                <BackBar>
                    <StyledButton onClick={handleClose}>
                        <ArrowLeft2
                            size="24"
                            color="#262626"
                        />
                        <span>Back</span>
                    </StyledButton>

                </BackBar>
                <Container>
                    {/*<Company>*/}
                    {/*    {store.getPostAdvert.get().post !== undefined ? store.getPostAdvert.get().post.author_user : '...'}*/}
                    {/*</Company>*/}

                    <Carousel infinite autoplay>
                        {store.getPostAdvert.get().post !== undefined && store.getPostAdvert.get().post.media.map(e =>
                            <Root
                                key={e.id}
                                loaded={e.image || ''}/>
                        )}
                    </Carousel>

                    {/*<Root*/}
                    {/*    loaded={store.getPostAdvert.get().post !== undefined ? store.getPostAdvert.get().post.media_first.image : ''}>*/}
                    {/*    <Phone onClick={(e) => {*/}
                    {/*        e.preventDefault();*/}
                    {/*        e.stopPropagation();*/}
                    {/*        // window.open('tel:+998998204828');*/}
                    {/*    }}>*/}
                    {/*        For advertising: (99) 820-48-28*/}
                    {/*    </Phone>*/}
                    {/*</Root>*/}

                    <Text>
                        {store.getPostAdvert.get().post !== undefined ? store.getPostAdvert.get().post.text : ''}
                    </Text>

                </Container>
            </FullScreenDialog>

            {store.getPostAdvert.get().post === undefined &&
                <Loader/>
            }
            <Phone onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // window.open('tel:+998998204828');
            }}>
                For advertising: (99) 820-48-28
            </Phone>

        </Root>
    );
});

type RootProps = {
    loaded?: any;
}

const Root = styled.div`
  width: 100%;
  height: 280px;
  border-radius: 10px;
  background-image: url(${({loaded}: RootProps) => loaded});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  position: relative;
  overflow: hidden;
`;
const Phone = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 9px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Container = styled.div`
  padding: 16px;
  overflow: scroll;
`;

const Company = styled.div`
  width: 100%;
  height: 64px;
  background: #F5F5F5;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BackBar = styled.div`
  width: 100%;
  //background-color: rgba(38, 38, 38, 0.23);
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: 500;
  font-size: 16px;
  color: var(--black);

  * {
    font-weight: 500;
    font-size: 16px;
    color: var(--black);
  }

`;

const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: var(--black);
  margin-top: 16px;
  white-space: pre-line;
`;

const Loader = styled(Skeleton)`
  position: absolute;
  width: 100%;
  height: 100% !important;
  left: 0;
  top: 0;
  display: block !important;
  transform: none !important;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 !important;
  background: none !important;


  * {
    font-weight: 500;
    font-size: 16px;
    color: var(--black);
    text-transform: initial;
    background: none !important;

  }
`;