import React, {Dispatch, SetStateAction, useState} from 'react';
import {InputAdornment} from '@mui/material';
import {Eye, EyeSlash} from 'iconsax-react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';

import {Button, Input} from 'shared/ui/components/client';

type Props = {
    error?: boolean;
    loader?: boolean;
    handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
    login: string;
    setLogin: Dispatch<SetStateAction<string>>;
    password: string;
    setPassword: Dispatch<SetStateAction<string>>;
}

export const Form = observer(({error, handleSubmit, loader, login, setLogin, password, setPassword}: Props) => {

    const [showPassword, setShowPassword] = useState(false);

    return (
        <Root onSubmit={handleSubmit}>
            <Input
                error={error}
                required={true}
                label={'Phone number or username'}
                value={login.toLowerCase()}
                onChange={(e) => {
                    setLogin(e.target.value.toLowerCase());
                }}
            />
            <Input
                error={error}
                required={true}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label={'Password or PVC code'}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        {
                            showPassword ?
                                <Eye
                                    size="24"
                                    color="#7F92A0"
                                    onClick={() => setShowPassword(false)}
                                />
                                :
                                <EyeSlash
                                    size="24"
                                    color="#7F92A0"
                                    onClick={() => setShowPassword(true)}
                                />
                        }
                    </InputAdornment>

                }}
            />
            <Button
                fullWidth
                type={'submit'}
                loading={loader}
                variant={'contained'}
                color={'primary'}
                disableElevation>
                LOG IN
            </Button>
        </Root>
    );
});

const Root = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;