/**
 * Created by Hennadiy on 13.02.2023
 */

import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {Context as globalContext} from 'shared/lib/context';
import {path} from 'shared/const';
import {IsNumber} from 'shared/lib/helpers';

import {Context} from './context';
import {useNavigate} from 'react-router-dom';
import {Props} from './props';

type QuizQuestionsParams = {
    quiz_id: string;
    question_id: string;
};

export const useList = (props: Props) => {

    const abortController = new AbortController();
    const {store} = useContext(Context);
    const {quiz_id, question_id} = useParams<QuizQuestionsParams>();

    const global = useContext(globalContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        store.setList([]);
        setLoading(true);
        store.setOffset(0);

        if (props.setFindNextQuestionIdByIndex) {
            props.setFindNextQuestionIdByIndex({
                request: findNextQuestionIdByIndex,
                reload: reload,
            });
        }

        reload();

        return () => {
            abortController.abort();
            store.setList([]);
            store.setOffset(0);
        };

    }, [props.findFirstQuestionLoading]);

    useEffect(() => {
        if (!store.getQuizQuestionList.loader.get()) {
            add();
        }
    }, [store.offset]);

    const reload = () => {
        if (!props.findFirstQuestionLoading) {
            if (IsNumber(quiz_id)) {
                store.getQuizQuestionList.request(abortController, {
                    limit: store.limit,
                    offset: 0,
                    quiz_id: Number(quiz_id),
                }).then(r => {
                    console.log(r);

                    if (!abortController.signal.aborted) {
                        setLoading(false);
                    }
                    if (r?.status === 200) {
                        store.setList(store.getQuizQuestionList.get().results);
                    } else {
                        if (!abortController.signal.aborted) {
                            global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                        }
                    }
                });
            }
        }
    };

    const add = () => {
        if (!props.findFirstQuestionLoading) {
            if (IsNumber(quiz_id)) {
                store.getQuizQuestionList.request(abortController, {
                    limit: store.limit,
                    offset: store.offset,
                    quiz_id: Number(quiz_id),
                }).then(r => {
                    console.log(r);
                    if (r?.status === 200) {
                        const new_list = store.getQuizQuestionList.get().results.filter((question) =>
                            store.getList().every(item => item.id !== question.id));

                        if (store.offset <= 0) {
                            store.setList(store.getQuizQuestionList.get().results);
                        } else {
                            store.setList([...store.getList(), ...new_list]);
                        }
                    } else {
                        if (!abortController.signal.aborted) {
                            global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                        }
                    }
                });
            }
        }

    };

    const changeQuestion = (question_id: number) => {
        navigate(path.quiz.getQuestion(quiz_id, question_id));
    };

    const goToCreate = () => {
        navigate(path.quiz.getQuestion(quiz_id, path.quiz.create));
    };

    const findNextQuestionIdByIndex = (index: number) => {
        for (let i = 0; i < store.getList().length; i++) {
            if (store.getList()[i].index === index) {
                if (i + 1 < store.getList().length) {
                    changeQuestion(store.getList()[i + 1].id);
                } else {
                    goToCreate();
                }
            }
        }
    };

    return {
        question_id,
        changeQuestion,
        goToCreate,
        loading
    };
};