/**
 * Created by Hennadiy on 04.03.2023
 */

import React from 'react';
import {observer} from 'mobx-react-lite';

import {LogoWithTitle} from 'shared/ui/components/client';

import {useCheckPvc} from '../lib/hooks';
import {Props} from '../lib/props';

import {Form} from './Form';

export const CheckPvc = observer((props: Props) => {
    const {error, loader, handleSubmit} = useCheckPvc(props);

    return (<React.Fragment>
            <LogoWithTitle
                title={'Connect with your friends today!'}
                logoTitle={'Create account'}
                subLogoTitle={'Confirm your phone number'}
                subtitle={'Enter the 6-digit confirmation code that we sent you by SMS.'}
            />
            <Form
                handleSubmit={handleSubmit}
                loader={loader}
                value={props.pvc}
                setValue={props.setPvc}
                error={error}
            />
        </React.Fragment>
    );
});