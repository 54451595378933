/**
 * Created by Hennadiy on 15.02.2023
 */
import {$axios} from './instances';
import {AxiosResponse} from 'axios';
import {urls} from './urls';
import {IGetQuizSessionCount} from './types';

export default class Services {
    static async getQuizSessionList({signal}: AbortController): Promise<AxiosResponse<IGetQuizSessionCount>> {
        return await $axios.get<IGetQuizSessionCount>(urls.getQuizSessionList(), {
            signal: signal,
            params: {
                i_part: false,
                limit: 1,
                offset: 0,
            }
        });
    }


}