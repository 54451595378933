/**
 * Created by Hennadiy on 28.02.2023
 */
import React from 'react';

import {Props} from '../lib/props';
import {withProviders} from '../providers';

import {PublicQuizDrawer} from './PublicQuizDrawer';

const Lazy = ({...props}: Props) => {
    return <PublicQuizDrawer {...props}/>;
};

export default withProviders(Lazy);