/**
 * Created by Hennadiy on 05.03.2023
 */
import {getRoutes} from 'gears-react';
import * as page from 'pages';
import {path} from 'shared/const';

const publicRoutes = getRoutes([
    ['*', [<div>error</div>]],
    [`/:${path.post.post_id}`, [<div>post id</div>]],
]);

const protectedRoutes = getRoutes([
    ['/', page.post.list],
    [path.post.create, [<div>post create</div>]],
]).concat(publicRoutes);

export const post = {protectedRoutes, publicRoutes};