import React, {useEffect} from 'react'

import {Content} from "./components/content/Content";
import {Header} from "./components/header/Header";
import {Footer} from "./components/footer/Footer";
import {Main} from "./components/main/Main";

import './styles/index.css';

function LandingDwedModule() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header/>
            <Main/>
            <Content/>
            <Footer/>
        </>

    )
}

export default LandingDwedModule;
