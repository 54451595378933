import React from 'react';
import {TPages} from 'gears-react';
import {observer} from 'mobx-react-lite';

import {QuizPageLinkCard} from 'features/client';

import {HeaderLogo} from 'shared/ui/components/client';
import {Header, MinHeightBlock} from 'shared/ui/components/general';

const Body = observer(() => {
        return <div>
            <Header>
                <HeaderLogo/>
            </Header>
            <MinHeightBlock>
                <QuizPageLinkCard/>
            </MinHeightBlock>
        </div>;
    }
);

export const main: TPages = [<Body/>];