import React, {Dispatch, SetStateAction, useEffect, useRef} from 'react';

type Props = {
    children?: React.ReactNode;
    offset: number;
    setOffset: (offset: number) => void | Dispatch<SetStateAction<number>>;
    isLoading: boolean;
    totalCount?: number;
    nextOffset?: number | null;
    root?: string;
    lastElementCurrent?: HTMLElement | null;
}

export const InfiniteScroll = ({
    children,
    offset,
    setOffset,
    isLoading,
    totalCount,
    nextOffset,
    root,
    lastElementCurrent
}: Props) => {

    const _lastElement = useRef<any>();
    const observer = useRef<any>();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isLoading) {
                return;
            } else {
                if (observer.current) observer.current.disconnect();

                const options = {
                    root: document.querySelector(root!),
                    // rootMargin: '0px',
                    // threshold: 1.0
                };
                const callback = (entries: any, observer: any) => {
                    if (entries[0].isIntersecting && nextOffset !== null && totalCount !== undefined && (offset < totalCount)) {
                        if (nextOffset) {
                            if (nextOffset !== totalCount) {
                                setOffset(nextOffset);
                            }
                        }
                    }
                };
                observer.current = new IntersectionObserver(callback, root !== undefined ? options : undefined);

                if (lastElementCurrent !== undefined && lastElementCurrent !== null) {
                    observer.current.observe(lastElementCurrent);
                } else {
                    observer.current.observe(_lastElement.current);
                }
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [isLoading]);


    return (
        <>
            {children}
            {lastElementCurrent === undefined && <div ref={_lastElement}
                // style={{backgroundColor: 'red', height: 20, width: '100%'}}
            />}
        </>
    );
};