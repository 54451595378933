/**
 * Created by Hennadiy on 13.02.2023
 */

import React, {useContext} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react-lite';
import {Dialog} from 'shared/ui/components/general';
import {Context} from '../lib/context';
import {useList} from '../lib/hooks';
import {Props} from '../lib/props';
import {Button, MySwitch} from 'shared/ui/components/client';

export const PublicQuizDrawer = observer((props: Props) => {
    const {store} = useContext(Context);
    const {open, setOpen, type, setType, useCode, setUseCode} = useList();
    const loaders = [1, 2, 3];

    return (
        <Root>
            <Dialog button={
                <Button
                    onClick={(e) => {
                        setOpen(true);
                    }}
                    fullWidth
                    variant={'contained'}
                    color={'primary'}
                    disableElevation>
                    Public Quiz
                </Button>

            }
                    open={open}
                    setOpen={setOpen}
                    label={'Public Quiz'}
            >
                <StartLabel onClick={() => setType(type === 1 ? 2 : 1)}>
                    <StartLabelTitle>Автоигра</StartLabelTitle>
                    <MySwitch checked={type === 2}/>
                </StartLabel>

                <StartLabel onClick={() => setUseCode(!useCode)}>
                    <StartLabelTitle>Вход с кодом</StartLabelTitle>
                    <MySwitch checked={useCode}/>
                </StartLabel>

                <Button
                    onClick={(e) => {

                    }}
                    fullWidth
                    variant={'contained'}
                    color={'primary'}
                    disableElevation>
                    Accept
                </Button>
            </Dialog>
        </Root>
    );
});


const Root = styled.div`
  width: 100%;
  cursor: pointer;

  * {
    cursor: pointer;
  }
`;

const StartLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;
const StartLabelTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: var(--black);
  user-select: none;
  -webkit-user-select: none;
`;
