import React from 'react';
import {TPages} from 'gears-react';
import {observer} from 'mobx-react-lite';

import {Ads} from 'features/general';
import {TimerLine} from 'features/quiz';

import {QuestionDetail} from 'entities/quiz';

import {Input} from 'shared/ui/components/client';
import {CB, CC, CT, DebugRoot} from 'shared/ui/components/general';
import {AnswerButton, HourglassLoader, OnlineParticipant, PrizeArea, TextArea} from 'shared/ui/components/quiz';

const Body = observer(() => {
        return <DebugRoot className={'MAX_WIDTH_PAGE'}>
            <CC>
                <CT style={{marginBottom: 0}}>
                    # Quiz Components
                </CT>
            </CC>

            <CC>
                <CT>
                    # TimerLine
                </CT>
                <CB>
                    <TimerLine/>
                </CB>
            </CC>
            <CC>
                <CT>
                    OnlineParticipant
                </CT>
                <CB>
                    <OnlineParticipant/>
                </CB>
            </CC>
            <CC style={{background: 'white'}}>
                <CT>
                    HourglassLoader
                </CT>
                <CB>
                    <HourglassLoader/>
                </CB>
            </CC>
            <CC>
                <CT>
                    QuestionDetail without image
                </CT>
                <CB>
                    <QuestionDetail/>
                </CB>
            </CC>
            <CC>
                <CT>
                    QuestionDetail with image
                </CT>
                <CB>
                    <QuestionDetail questionNumber={10} totalQuestionNumber={30}
                                    src={'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'}/>
                </CB>
            </CC>
            <CC style={{background: 'white'}}>
                <CT>
                    PrizeArea
                </CT>
                <CB>
                    <PrizeArea text={'Grand Prize: IPHONE 14 MAX '}/>
                    <PrizeArea/>
                </CB>
            </CC>
            <CC>
                <CT>
                    AnswerButtons
                </CT>
                <CB>
                    <AnswerButton>
                        Answer
                    </AnswerButton>
                    <AnswerButton isActive={true}>
                        Answer
                    </AnswerButton>
                    <AnswerButton buttonProps={{loading: true}}>
                        Answer
                    </AnswerButton>
                    <AnswerButton isActive={true} buttonProps={{loading: true}}>
                        Answer
                    </AnswerButton>
                </CB>
            </CC>
            <CC style={{background: 'white'}}>
                <CT>
                    # Inputs #F2F2F2
                </CT>
                <CB>
                    <Input label={'Test label'}/>
                    <Input label={'Test label'} error/>
                </CB>
            </CC>
            <CC>
                <CT>
                    # Inputs #FFF
                </CT>
                <CB>
                    <Input label={'Test label White'} style={{backgroundColor: '#fff'}}/>
                    <Input label={'Test label'} style={{backgroundColor: '#fff'}} error/>
                </CB>
            </CC>
            <CC style={{background: 'white'}}>
                <CT>
                    TextArea
                </CT>
                <CB>
                    <TextArea label={'Type your answer here'}/>
                    <TextArea label={'Type your answer here'} error/>
                </CB>
            </CC>
            <CC>
                <CT>
                    TextArea White
                </CT>
                <CB>
                    <TextArea label={'Type your answer here'} style={{backgroundColor: '#fff'}}/>
                    <TextArea label={'Type your answer here'} style={{backgroundColor: '#fff'}} error/>
                </CB>
            </CC>
            <CC>
                <CT>
                    Ads
                </CT>
                <CB>
                    <Ads place={'quiz'}/>
                </CB>
            </CC>
        </DebugRoot>;
    }
);

export const debug:TPages = [<Body/>];
