/**
 * Created by Hennadiy on 13.02.2023
 */

import {action, makeAutoObservable} from 'mobx';
import {ServiceStore} from 'gears-react';

import Services from '../api/services';
import * as types from '../api/types';

export class Store {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly getQuizQuestionList = new ServiceStore<[AbortController, types.IGetQuizQuestionAllParams],
        types.IListDataResponse<types.IQuizQuestionResponse>>(Services.getQuizQuestionList, true);
}