/**
 * Created by Hennadiy on 16.02.2023
 */

export const urls = {
    getQuizQuestion(quiz_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${id}/`;
    },

    patchQuizQuestion(quiz_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${id}/`;
    },

    postQuizQuestion(quiz_id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/`;
    },
};
