import React from 'react';
import styled from 'styled-components';

type Props = {
    title?: string;
    subtitle?: string;
    img?: string;
    icon?: string;
    props?: React.HTMLAttributes<HTMLDivElement>
}

export const PageLinkCard = ({props, subtitle, title, img, icon}: Props) => {
    return <Root {...props} style={{
        backgroundImage: `url(${img})`,
    }}>
        <Title>
            {title}
        </Title>
        <Subtitle>
            {subtitle}
        </Subtitle>
        <Icon style={{
            backgroundImage: `url(${icon})`,
        }}/>
    </Root>;
};

const Root = styled.div`
  width: 100%;
  height: 96px;
  background-color: var(--gray);
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 16px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  position: relative;
  overflow: hidden;
  user-select: none;
  -webkit-user-select: none;

  * {
    user-select: none;
    -webkit-user-select: none;
  }
`;
const Icon = styled.div`
  position: absolute;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  right: 0;
  width: 100px;
  height: 95%;
`;

const Title = styled.div`
  width: 100%;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
`;

const Subtitle = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  font-size: 12px;
`;