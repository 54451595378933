/**
 * Created by Hennadiy on 10.03.2023
 */

const auth = {
    login: '/login',
    signup: '/signup',
};

const post = {
    main: '/post',
    create: '/create',
    post_id: 'post_id',
};

const stream = {
    main: '/stream',
    stream_id: 'stream_id',
};
const quiz = {
    main: '/quiz',
    quiz_id: 'quiz_id',

    list: '/list',
    create: '/create',

    sessions: '/sessions',
    session_id: 'session_id',

    questions: '/questions',
    question_id: 'question_id',

    public: '/public',

    getSession: (session_id: any) => {
        return `${quiz.main}/${session_id}`;
    },

    getQuestions: (quiz_id: any) => {
        return `${quiz.main}${quiz.list}/${quiz_id}${quiz.questions}`;
    },

    getPublic: (quiz_id: any) => {
        return `${quiz.main}${quiz.list}/${quiz_id}${quiz.public}`;
    },

    getQuestion: (quiz_id: any, question_id: any) => {
        return `${quiz.main}${quiz.list}/${quiz_id}${quiz.questions}/${question_id}`;
    },

    getCreate: () => {
        return `${quiz.main}${quiz.create}`;
    },

    getList: () => {
        return `${quiz.main}${quiz.list}`;
    }
};

const profile = {
    main: '/profile',
};

const client = {
    services: '/services',
    quiz: () => {
        return `${client.services}${quiz.main}`;
    },
};

export const path = {
    auth,
    quiz,
    post,
    stream,
    client,
    profile,
};