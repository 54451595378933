/**
 * Created by Hennadiy on 04.03.2023
 */

import React from 'react';
import {observer} from 'mobx-react-lite';

import {Props} from '../lib/props';

import {Form} from './Form';
import {useAuth} from '../lib/hooks';

export const Auth3PU = observer((props: Props) => {
    const {login, setLogin, password, setPassword, error, handleSubmit, loader} = useAuth(props);

    return (<React.Fragment>
            <Form
                handleSubmit={handleSubmit}
                error={error}
                login={login}
                setLogin={setLogin}
                password={password}
                setPassword={setPassword}
                loader={loader}
            />
        </React.Fragment>
    );
});