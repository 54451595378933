/**
 * Created by Hennadiy on 16.02.2023
 */

import {$axios} from './instances';
import {AxiosResponse} from 'axios';

import {urls} from './urls';
import * as types from './types';

export default class Services {
    static async getCheckPassword(
        {
            signal
        }: AbortController,
        {
            password
        }: types.ICheckPasswordParams): Promise<AxiosResponse<types.ICheckPasswordResponse>> {
        return await $axios.get<types.ICheckPasswordResponse>(urls.getCheckPassword(password), {
            signal:signal
        });
    }
}