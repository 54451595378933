/**
 * Created by Hennadiy on 04.03.2023
 */

import React from 'react';
import {observer} from 'mobx-react-lite';

import {LogoWithTitle} from 'shared/ui/components/client';

import {useCheckUsername} from '../lib/hooks';
import {Props} from '../lib/props';

import {Form} from './Form';

export const CheckUsername = observer((props: Props) => {
    const {error, loader, handleSubmit, is_existed, disabled} = useCheckUsername(props);

    return (<React.Fragment>
            <LogoWithTitle
                title={'Connect with your friends today!'}
                logoTitle={'Create account'}
                subLogoTitle={'Choose a username'}
                subtitle={'Username не должен содержать символы +!@#$% и тд.'}
            />
            <Form
                handleSubmit={handleSubmit}
                loader={loader}
                value={props.username}
                setValue={props.setUsername}
                error={error}
                is_existed={is_existed}
                disabled={disabled}
            />
        </React.Fragment>
    );
});