/**
 * Created by Hennadiy on 14.02.2023
 */

import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react-lite';

import {CategoryDrawer, QuestionTimeDrawer} from 'features/quiz';

import {ImageUpload} from 'shared/ui/components/general';
import {Button, Input} from 'shared/ui/components/client';

import {useCreateQuiz} from '../hooks';

export const CreateQuizForm = observer(() => {

    const {
        handleSubmit,
        getError,
        loader,

        category,
        setCategory,

        question_time,
        setQuestionTime,

        description,
        setDescription,

        name,
        setName,

        setReward,
        reward,

        image,
        setImage,

    } = useCreateQuiz();

    return <Form onSubmit={handleSubmit}>

        <ImageUpload image={image} setImage={setImage}/>

        <Input
            error={getError('name')}
            required={true}
            label={'Quiz name'}
            value={name}
            onChange={(e) => {
                setName(e.target.value);
            }}
        />

        <CategoryDrawer
            error={getError('category')}
            value={category}
            setValue={setCategory}
            required={true}
        />

        <Input
            error={getError('description')}
            required={true}
            label={'Description'}
            multiline
            rows={3}
            value={description}
            onChange={(e) => {
                setDescription(e.target.value);
            }}
        />

        <QuestionTimeDrawer
            error={getError('question_time')}
            value={question_time}
            setValue={setQuestionTime}
            required={true}
        />

        <Input
            error={getError('reward')}
            required={true}
            label={'Prize'}
            value={reward}
            onChange={(e) => {
                setReward(e.target.value);
            }}
        />

        <Button
            fullWidth
            type={'submit'}
            loading={loader}
            variant={'contained'}
            color={'primary'}
            disableElevation>
            Create quiz
        </Button>

    </Form>;
});

const Form = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;