/**
 * Created by Hennadiy on 14.02.2023
 */


import React from 'react';
import styled from 'styled-components';
import {useWindowSize} from 'usehooks-ts';

type Props = {
    children:React.ReactNode;
}

const OffsetHeight = ({children}:Props) => {
    const {height, width} = useWindowSize()

    return (
        <Root height={height}>
            {children}
        </Root>
    );
};

export default OffsetHeight;

type heightProps = {
    height: number;
}

export const Root = styled.div`
  height: ${({height}: heightProps) => height}px;
  width: 100%;
  overflow: hidden scroll;
`;