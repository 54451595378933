import React from 'react';
import {GradientLink,LogoWithTitle} from 'shared/ui/components/client';
import {path} from 'shared/const';
import styled from 'styled-components';
import {useWindowSize} from 'usehooks-ts';

type Props = {
    children?: React.ReactNode;
    leftTop?: React.ReactNode;
}

export const LoginLayout = ({children, leftTop}: Props) => {
    const {height} = useWindowSize();

    return (
        <Root height={height}>
            <div>
                <Top>
                    {leftTop}
                </Top>

                <LogoWithTitle title={'Добро пожаловать в'} logoTitle={'DWED'}
                    subtitle={'Авторизуйтесь чтобы продолжить'}/>

                {children}
            </div>

            <RegisterLink>
                Don’t have an account yet?
                <GradientLink path={path.auth.signup} title={'Sign up now'}/>
            </RegisterLink>

        </Root>
    );
};


type RootProps = {
    height: number;
}

const Root = styled.div`
  width: 100%;
  padding: 32px 16px;
  background-color: white;
  min-height: ${({height}: RootProps) => height}px;
  position: relative;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const RegisterLink = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 24px;

  font-weight: 400;
  font-size: 16px;
  color: #262626;

  user-select: none;
  -webkit-user-select: none;
  
  span {
    margin-left: 10px;
  }
`;

const Top = styled.div`
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;