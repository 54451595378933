/**
 * Created by Hennadiy on 13.02.2023
 */

import React from 'react';
import {observer} from 'mobx-react-lite';

import {Header, SubHeader} from 'shared/ui/components/general';

import {withProviders} from '../providers';

import {SearchButton} from './SearchButton';
import {SessionFilters} from './SessionFilters';
import {SessionCategories} from './SessionCategories';

const SessionHeader = observer(() => {
    return (
        <React.Fragment>
            <Header>
                <SearchButton/>
                <SessionFilters/>
            </Header>
            <SubHeader>
                <SessionCategories/>
            </SubHeader>
        </React.Fragment>
    );
});

const Lazy = () => {
    return <SessionHeader/>;
};

export default withProviders(Lazy);