import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {SplideSlide} from '@splidejs/react-splide';

import {Card} from 'shared/ui/components/quiz';
import {InfiniteScroll} from 'shared/ui/components/general';
import {checkNull, getFormatDate} from 'shared/lib/helpers';
import {HorizontalScroll} from 'shared/ui/components/general';

import {useList} from '../lib/hooks';
import {Context} from '../lib/context';
import {withProviders} from '../providers';
import {useNavigate} from 'react-router-dom';


const List = observer(() => {
    const {store} = useContext(Context);
    useList();
    const loaders = [1];
    const navigate = useNavigate();

    return (
        <>
            {store.getList().length > 0 ?
                <Root>
                    <Title>Participating Quizzes</Title>

                    <HorizontalScroll fullWidth>

                        {store.loading ? loaders.map((e, i) =>
                                <SplideSlide key={i}><Card key={i} isLoading={true}/></SplideSlide>) :

                            <InfiniteScroll offset={store.offset}
                                            nextOffset={store.getQuizSessionList.get().next_offset}
                                            setOffset={(offset) => {
                                                store.setOffset(offset);
                                            }}
                                            isLoading={store.getQuizSessionList.loader.get()}
                                            totalCount={store.getQuizSessionList.get().count}>


                                {store.getList().map((item) =>
                                    <SplideSlide key={item.id}>
                                        <Card
                                            onClick={() => navigate(`${item.id}`)}

                                            isLoading={false}
                                            contentProps={{
                                                src: `${checkNull(item.quiz.images[0]) ? item.quiz.images[0].file : ''}`,
                                                title: `${item.quiz.name}`,
                                                creator: `${checkNull(item.org) || item.creator}`,
                                                category: 'category',
                                                question_number: item.question_number,
                                                time: getFormatDate(item.expected_date),
                                                users: `${item.participant_number}/${item.expected_participants || '∞'}`,
                                                type: checkNull(item.type)
                                            }}
                                            actionsProps={{
                                                title: 'Entrance fee',
                                                entrance_fee: `Free ${item.is_code ? '(with code)' : ''} `,
                                                blue_label_title: `Join Room #${item.id}`
                                            }}
                                        />
                                    </SplideSlide>
                                )}

                                {
                                    store.getQuizSessionList.loader.get() &&
                                    store.getQuizSessionList.get().results !== undefined &&
                                    loaders.map((e, i) =>
                                        <SplideSlide key={i}><Card key={i} isLoading={true}/></SplideSlide>)
                                }

                            </InfiniteScroll>
                        }


                    </HorizontalScroll>

                    <Title>All Quizzes</Title>
                </Root>
                : <Space/>
            }
        </>
    );
});

const Lazy = () => {
    return <List/>;
};

export default withProviders(Lazy);


const Root = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: var(--black);
  padding: 16px;
`;

const Space = styled.div`
  margin-bottom: 16px;
`;
