import styled from '@emotion/styled';
import {LoadingButton} from '@mui/lab';

export const SmallButton = styled(LoadingButton)`
  font-size: 13px;
  border-radius: 8px;
  font-weight: 400;
  padding: 4px 10px;
  text-transform: initial;
  line-height: 16px;
  height: 48px;
  min-width: 140px;
`;
