import * as React from 'react';
import styled from '@emotion/styled';
import {TextField, TextFieldProps} from '@mui/material';

export function Input({...props}: TextFieldProps) {
    return (
        <StyledTextField
            {...props}
            variant={'filled'}
            InputProps={{disableUnderline: true, autoComplete: 'off', autoFocus: false, ...props.InputProps}}
            fullWidth
        />
    );
}

const StyledTextField = styled(TextField)`
  background-color: rgba(255, 255, 255, 0) !important;

  .MuiInputBase-root {
    background-color: ${(props) => props.style?.backgroundColor || '#FFF'};
    border-radius: 10px;
    border: 1px solid ${(props) => props.error ? 'var(--error)' : '#D5E5FB'};
    transition: all .2s;

    :hover {
      background-color: ${(props) => props.style?.backgroundColor || '#FFF'};
    }
  }

  .MuiInputBase-root.Mui-focused {
    border: 1px solid ${(props) => props.error ? 'var(--error)' : 'var(--main)'};
    background-color: ${(props) => props.style?.backgroundColor || '#FFF'};
  }

`;