import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {ErrorBoundary} from 'react-error-boundary';

import {ErrorFallback} from 'shared/ui/components/general';
import {PageLinkCard} from 'shared/ui/components/client';
import {path} from 'shared/const';

import {withProviders} from '../providers';
import {useGetQuizSessionCount} from '../lib/hooks';
import icon from '../assets/icon.svg';
import img from '../assets/bg.svg';
import {Context} from '../lib/context';

const QuizPageLinkCard = observer(() => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    useGetQuizSessionCount();

    return <>
        <PageLinkCard
            title={'QUIZ'}
            subtitle={`${store.getQuizSessionList.loader.get() ? '...' : store.getQuizSessionList.get().count} quizzes`}
            img={img}
            icon={icon}
            props={{
                onClick: () => navigate(path.client.quiz())
            }}
        />
    </>;
});

const Lazy = () => {
    return <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QuizPageLinkCard/>
    </ErrorBoundary>;
};

export default withProviders(Lazy);