/**
 * Created by Hennadiy on 04.03.2023
 */

import React from 'react';
import {observer} from 'mobx-react-lite';

import {LogoWithTitle} from 'shared/ui/components/client';

import {useCheckPassword} from '../lib/hooks';
import {Props} from '../lib/props';

import {Form} from './Form';

export const CheckPassword = observer((props: Props) => {
    const {error, loader, handleSubmit, is_existed, disabled, password, setPassword} = useCheckPassword(props);

    return (<React.Fragment>
            <LogoWithTitle
                title={'Connect with your friends today!'}
                logoTitle={'Create account'}
                subLogoTitle={'Choose a password'}
                subtitle={'Create a password with at least 8 characters and numbers.'}
            />
            <Form
                handleSubmit={handleSubmit}
                error={error}
                loader={loader}
                password1={props.password}
                setPassword1={props.setPassword}
                password2={password}
                setPassword2={setPassword}
                is_existed={is_existed}
                disabled={disabled}
            />
        </React.Fragment>
    );
});