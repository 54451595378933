import React from 'react';
import styled from '@emotion/styled';
import {LoadingButton, LoadingButtonProps} from '@mui/lab';

type Props = {
    isActive?: boolean;
    children?: React.ReactNode;
    buttonProps?: LoadingButtonProps;
}

export const AnswerButton = ({isActive, children, buttonProps}: Props) => {
    return (isActive ? <Active {...buttonProps} variant={'contained'} color={'primary'} disableElevation>
        {children}
    </Active> :
        <Root  {...buttonProps} variant={'contained'} color={'primary'} disableElevation>
            {children}
        </Root>
    );
};

const Root = styled(LoadingButton)`
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-transform: initial;

  background: #FFFFFF;
  color: #262626;

  span {
    color: #000;

    * {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  :disabled {
    background: #FFFFFF;
  }
  
  :hover {
    background-color: rgb(255, 255, 255);
  }
`;

const Active = styled(LoadingButton)`
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  
  overflow: hidden;
  text-transform: initial;

  background: linear-gradient(143.49deg, #1DA1F2 0%, #003CC5 125.35%);
  color: #fff;

  span {
    color: rgba(255, 255, 255, 0);

    * {
      color: rgb(255, 255, 255);
    }
  }

  :disabled {
    background: linear-gradient(143.49deg, #1DA1F2 0%, #003CC5 125.35%);
  }

  :hover {
    background-color: rgba(255, 255, 255, 0);
  }
`;
