/**
 * Created by Hennadiy on 13.02.2023
 */

import {Dispatch, SetStateAction, useContext} from 'react';
import * as React from 'react';
import styled from 'styled-components';
import {SplideSlide} from '@splidejs/react-splide';

import {HorizontalScroll, InfiniteScroll} from 'shared/ui/components/general';

import {withProviders} from '../providers';
import {observer} from 'mobx-react-lite';
import {Context} from '../lib/context';
import {useList} from '../lib/hooks';
import {Skeleton} from '@mui/material';

type Props = {
    category: number;
    setCategory: (category: number) => void | Dispatch<SetStateAction<number>>;
}

const CategoryTabs = observer(({setCategory, category}: Props) => {
    const {store} = useContext(Context);
    useList();
    const loaders = [1, 2, 3];

    return (
        <StyledBox>
            <HorizontalScroll>
                <InfiniteScroll offset={store.offset} nextOffset={store.getQuizCategoryList.get().next_offset}
                                setOffset={(offset) => {
                                    store.setOffset(offset);
                                }}
                                isLoading={store.getQuizCategoryList.loader.get()}
                                totalCount={store.getQuizCategoryList.get().count}>


                    <SplideSlide onClick={() => setCategory(0)}>
                        <StyledTab style={category === 0 ? {
                            background: 'linear-gradient(143.49deg, #1DA1F2 0%, #003CC5 125.35%)',
                            color: 'white',
                            border: '0px solid white'
                        } : {
                            color: 'var(--gray)',
                            background: `white`,
                            border: '1px solid #D5E5FB'
                        }}>
                            All
                        </StyledTab>
                    </SplideSlide>

                    {
                        store.loading ? <>
                            {loaders.map((e, i) => <SplideSlide key={i}>
                                <Skeleton sx={{borderRadius: '100px', transform: 'none'}} width={91} height={42}/>
                            </SplideSlide>)}
                        </> : <>
                            {store.getList().map((e) =>
                                <SplideSlide key={e.id} onClick={() => setCategory(e.id)}>
                                    <StyledTab style={category === e.id ? {
                                        background: 'linear-gradient(143.49deg, #1DA1F2 0%, #003CC5 125.35%)',
                                        color: 'white',
                                        border: '0px solid white'
                                    } : {
                                        color: 'var(--gray)',
                                        background: `white`,
                                        border: '1px solid #D5E5FB'
                                    }}>
                                        {e.name}
                                    </StyledTab>
                                </SplideSlide>
                            )}

                            {store.getQuizCategoryList.loader.get() && loaders.map((e, i) => <SplideSlide key={i}>
                                <Skeleton sx={{borderRadius: '100px', transform: 'none'}} width={91} height={42}/>
                            </SplideSlide>)}
                        </>
                    }

                </InfiniteScroll>
            </HorizontalScroll>
        </StyledBox>
    );
});

const Lazy = ({setCategory, category}: Props) => {
    return <CategoryTabs category={category} setCategory={setCategory}/>;
};

export default withProviders(Lazy);

const StyledTab = styled.div`
  padding: 13px 16px;
  min-width: 91px;
  height: 42px;
  border-radius: 100px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition-property: background, color;
  transition-duration: .3s;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledBox = styled.div`
  width: 100%;
  overflow: hidden;
  height: 42px;
`;