import React, {Dispatch, SetStateAction} from 'react';
import VerificationInput from 'react-verification-input';
import {observer} from 'mobx-react-lite';
import {Button} from 'shared/ui/components/client';
import styled from 'styled-components';

import classes from './verificationInput.module.css';


type Props = {
    error?: boolean;
    loader?: boolean;
    handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
    value: string;
    setValue: Dispatch<SetStateAction<string>>;
}
export const Form = observer(({error, setValue, value, handleSubmit, loader}: Props) => {

    return (
        <Root onSubmit={handleSubmit}>
            <VerificationInput
                value={value}
                onChange={(e) => {
                    setValue(e);
                }}
                validChars={'0-9'}
                placeholder={''}
                autoFocus={true}
                inputProps={{
                    type: 'tel'
                }}
                classNames={{
                    container: classes.container,
                    character: error ? classes.characterError : classes.character,
                    characterInactive: classes.characterInactive,
                    characterSelected: classes.characterSelected,
                }}
            />
            <Button
                fullWidth
                type={'submit'}
                loading={loader}
                variant={'contained'}
                color={'primary'}
                disableElevation>
                CONFIRM
            </Button>
        </Root>
    );
});

const Root = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;