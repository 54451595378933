/**
 * Created by Hennadiy on 18.02.2023
 */


import React, {Dispatch, SetStateAction, useContext, useState} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {FormControl, InputLabel, MenuItem, Select, Skeleton} from '@mui/material';

import {Button, Input, MySwitch, WhiteButton} from 'shared/ui/components/client';

import {Footer, ImageUpload} from 'shared/ui/components/general';
import {useGetQuizQuestion} from '../lib/hooks';
import {Context} from '../lib/context';

import {withProviders} from '../providers';
import {Props} from '../lib/props';

const ChangeQuestionForm = observer((props: Props) => {
    const {store} = useContext(Context);

    const {
        notFound,
        setQuestionText,
        question_text,
        handleSubmit,
        image,
        setImage, previewLink,
        answers,
        answer,
        type,
        setType,
        onChangeCorrectAnswer,
        onChange,
        onChangeAnswer,
        goToPublicQuiz,
        isCorrectAnswerExists,
        loading
    } = useGetQuizQuestion(props);

    if (notFound) return (<div>404 Not Found </div>);

    return (
        <Form onSubmit={handleSubmit}>
            {loading || props.findFirstQuestionLoading ?
                <Skeleton sx={{borderRadius: '20px', transform: 'none'}} width={'100%'} height={150}/> :
                <ImageUpload preview_link={previewLink} image={image} setImage={setImage}/>
            }

            <div>

                <Title>
                    {loading || props.findFirstQuestionLoading ?
                        <Skeleton sx={{borderRadius: '20px', transform: 'none'}} width={'100%'} height={19}/>
                        : 'Question'
                    }
                </Title>

                {loading || props.findFirstQuestionLoading ?
                    <Skeleton sx={{borderRadius: '20px', transform: 'none'}} width={'100%'} height={104}/> : <Input
                        // error={getError('description')}
                        // required={true}
                        label={'Enter your question'}
                        multiline
                        rows={3}
                        value={question_text}
                        onChange={(e) => {
                            setQuestionText(e.target.value);
                        }}
                    />}

            </div>


            <div>
                <Row>
                    {loading || props.findFirstQuestionLoading ?
                        <Skeleton sx={{borderRadius: '20px', transform: 'none'}} width={'100%'} height={19}/> :
                        <>
                            <Title style={{marginBottom: 0}}>Answers</Title>

                            <Label style={{width: '127px'}}>
                                <LabelTitle>Варианты</LabelTitle>
                                <MySwitch onChange={() => {
                                    setType(type === 1 ? 2 : 1);
                                }} checked={type === 1}/>
                            </Label>
                        </>
                    }
                </Row>

                {type === 1 ? <Block>
                    {
                        answers.map((answer, index) => <React.Fragment key={index}>
                            {loading || props.findFirstQuestionLoading ?
                                <Skeleton sx={{borderRadius: '20px', transform: 'none'}} width={'100%'} height={58}/>
                                :
                                <>

                                    <Input
                                        // error={getError('name')}
                                        required={true}
                                        label={`Add choice answer ${index + 1}`}
                                        value={answers[index].text}
                                        onChange={(e) => {
                                            onChange(e, index);
                                        }}
                                    />
                                    <Label>
                                        <LabelTitle>Correct answer</LabelTitle>
                                        <MySwitch required={!isCorrectAnswerExists()}
                                                  onChange={(e) => onChangeCorrectAnswer(e, index)}
                                                  checked={answer.correct_answer}
                                        />
                                    </Label>
                                </>
                            }
                        </React.Fragment>)
                    }

                </Block> : <Block>
                    {loading || props.findFirstQuestionLoading ?
                        <Skeleton sx={{borderRadius: '20px', transform: 'none'}} width={'100%'} height={58}/>
                        : <Input
                            // error={getError('name')}
                            required={true}
                            label={'Enter correct answer'}
                            value={answer[0].text}
                            onChange={(e) => {
                                onChangeAnswer(e, 0);
                            }}
                        />}
                </Block>}
            </div>

            <Footer style={{height: 'auto'}}>
                <BottomPos>
                    <Button
                        fullWidth
                        type={'submit'}
                        loading={loading || props.findFirstQuestionLoading || store.patchQuizQuestion.loader.get() || store.postQuizQuestion.loader.get()}
                        variant={'contained'}
                        color={'primary'}
                        disableElevation>
                        Next Question
                    </Button>

                    <WhiteButton
                        fullWidth
                        loading={loading || props.findFirstQuestionLoading}
                        onClick={() => {
                            if (!store.getQuizQuestion.loader.get()) {
                                goToPublicQuiz();
                            }
                        }}
                        variant={'contained'}
                        color={'primary'}
                        disableElevation>
                        Complete Quiz
                    </WhiteButton>
                </BottomPos>
            </Footer>
        </Form>
    );
});

const Lazy = ({...props}: Props) => {
    return <ChangeQuestionForm {...props}/>;
};

export default withProviders(Lazy);

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 176px;
  min-height: 811px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: var(--black);
  margin-bottom: 12px;
`;

const Row = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;
const LabelTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: var(--black);
  user-select: none;
  -webkit-user-select: none;
`;

const BottomPos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 32px 16px;
`;

//мусорка
