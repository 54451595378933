import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';


type Props = {
    title: string;
    path: string;
}
export const GradientLink = ({title, path}: Props) => {
    const navigate = useNavigate();

    return (
        <Root onClick={() => navigate(path)}>
            {title}
        </Root>
    );
};

const Root = styled.span`
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  background: linear-gradient(165.92deg, #1DA1F2 3.35%, #003CC5 72.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  user-select: none;
  -webkit-user-select: none;

  :hover {
    ::before {
      content: "";
      position: absolute;
      top: 100%;
      width: 100%;
      left: 0;
      height: 1px;
      border-radius: 2px;
      background: linear-gradient(165.92deg, #1DA1F2 3.35%, #003CC5 72.58%);
    }
  }
`;
