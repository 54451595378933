/**
 * Created by Hennadiy on 14.02.2023
 */

import styled from 'styled-components';
import {observer} from 'mobx-react-lite';

import {Button} from 'shared/ui/components/client';
import {useNavigate} from 'react-router-dom';
import {path} from 'shared/const';

export const ListQuizBottomBar = observer(() => {
    const navigate = useNavigate();

    return <Root>
        <Button fullWidth onClick={() => navigate(path.quiz.getCreate())}>
            Create new Quiz
        </Button>
    </Root>;
});


const Root = styled.div`
  width: 100%;
  z-index: 9999999999;
  bottom: 0;
  left: 0;
  position: fixed;
  height: 96px;

  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  padding: 16px;

`;
