/**
 * Created by Hennadiy on 18.02.2023
 */
import {useLocalStorage} from 'gears-react';
import React, {useLayoutEffect, useRef, useState} from 'react';
import {useWindowSize} from 'usehooks-ts';

export const useTranslationButton = () => {

    const [language, setLanguage] = useLocalStorage('language', 'en');
    const windowSize = useWindowSize();
    const [open, setOpen] = useState<boolean>(false);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(true);
    };
    const handleCloseUserMenu = () => {
        setOpen(false);
    };

    const refBtn = useRef<HTMLDivElement>(null);

    const menu_width = 150;
    const menu_height = 168;

    const [x, setX] = useState<number>();
    const [y, setY] = useState<number>();

    useLayoutEffect(() => {
        if (refBtn.current) {
            if (refBtn.current.offsetLeft + refBtn.current.offsetWidth + menu_width >= windowSize.width) {
                setX((refBtn.current.offsetLeft + refBtn.current.offsetWidth) - menu_width);
            } else {
                setX(refBtn.current.offsetLeft);
            }

            if (refBtn.current.offsetTop + refBtn.current.offsetHeight + 16 >= windowSize.height) {
                setY(refBtn.current.offsetTop - menu_height - 16);
            } else {
                setY(refBtn.current.offsetTop + refBtn.current.offsetHeight + 16);
            }
        }
    }, [
        refBtn.current?.offsetWidth,
        refBtn.current?.offsetTop,
        refBtn.current?.offsetLeft,
        windowSize?.width,
        windowSize?.height
    ]);

    return {
        open,
        menu_width,
        menu_height,
        refBtn,
        language,
        x, y,
        setLanguage,
        handleOpenUserMenu,
        handleCloseUserMenu,
    };
};