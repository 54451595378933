import React, {CSSProperties} from 'react';
import styled from 'styled-components';

import img from '../assets/prize_bg.svg';


type Props = {
    text?: string;
    style?: CSSProperties;

}
export const PrizeArea = ({text, style}: Props) => {
    return (
        <Root src={img} style={style}>
            <Text>
                {text || '...'}
            </Text>
        </Root>
    );
};

type RootProps = {
    src: string;
}

const Root = styled.div`
  width: 100%;
  height: 56px;
  background-color: #706FD3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 5px;

  background-image: url(${({src}: RootProps) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
const Text = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
  text-align: center;
  width: 100%;
  white-space: nowrap;
`;

