/**
 * Created by Hennadiy on 24.02.2023
 */


import React, {CSSProperties} from 'react';
import styled from 'styled-components';

type Props = {
    title?: string;
    style?: CSSProperties;

}

export const DividerLabel = ({title, style}: Props) => {
    return (
        <Root style={style}>
            <Line/>
            <Title>
                {title}
            </Title>
            <Line/>
        </Root>
    );
};


const Root = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  width: 35%;
  height: 1px;
  background-color: #7F92A0;
  flex-shrink: 0;  
`;

const Title = styled.div`
  width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 2px;
  text-align: center;

  font-weight: 400;
  font-size: 14px;
  color: #7F92A0;
`;


