/**
 * Created by Hennadiy on 13.02.2023
 */
import {makeAutoObservable} from 'mobx';

import {ServiceStore} from 'gears-react';

import Services from '../api/services';
import {IGetQuizSessionCount} from '../api/types';

export class Store {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly getQuizSessionList = new ServiceStore<[AbortController], IGetQuizSessionCount>
    (Services.getQuizSessionList, true);
}