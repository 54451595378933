import React, {useContext, useEffect, useState} from 'react';

import {Context as globalContext} from 'shared/lib/context';
import {containsOnlyNumbers} from 'shared/lib/helpers';

import {Context} from './context';
import {Props} from './props';


export const useCheckPassword = (props: Props) => {
    const abortController = new AbortController();
    const {store} = useContext(Context);
    const global = useContext(globalContext);

    const [error, setError] = useState(false);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, []);

    const [password, setPassword] = useState<string>('');

    const [disabled, setDisabled] = useState<boolean>(true);

    const [is_existed, setIsExisted] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        setDisabled(true);
        setIsExisted(undefined);
        setError(false);

        // console.log(props.password);
        // console.log(containsOnlyNumbers(props.password));

        if (props.password.trim().length >= 8 && !containsOnlyNumbers(props.password)) {

            if (password === props.password) {
                setDisabled(false);
                setIsExisted(false);
                setError(false);
            }

            // const timer = setTimeout(() => {
            //     store.getCheckUsername.request(abortController, {username: props.username}).then(r => {
            //         console.log(r);
            //
            //         if (r?.status === 200) {
            //
            //             setIsExisted(store.getCheckUsername.get().is_existed);
            //             setError(false);
            //
            //             if (store.getCheckUsername.get().is_existed) {
            //                 global.store.alert.set(true, 'error', 'Пользователь существует');
            //             } else {
            //                 setDisabled(false);
            //             }
            //
            //         } else {
            //             global.store.alert.set(true, 'error', `${r?.data[0].message}`);
            //             setError(true);
            //         }
            //
            //     });
            // }, 500);
            //
            // return () => clearTimeout(timer);

        } else {
            if (props.password.trim() !== '') {
                setIsExisted(true);
                setDisabled(true);
                // setError(true);
            }
        }

    }, [props.password, password]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!is_existed && !disabled) {
            props.next();
        }
    };

    return {
        error,
        loader: false,
        handleSubmit,
        is_existed,
        disabled,
        password,
        setPassword
    };
};
