import React, {Dispatch, SetStateAction} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

import {Button, Input} from 'shared/ui/components/client';

type Props = {
    error?: boolean;
    loader?: boolean;
    handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
    value: string;
    setValue: Dispatch<SetStateAction<string>>;
}
export const Form = observer(({error, setValue, value, handleSubmit, loader}: Props) => {

    return (
        <Root onSubmit={handleSubmit}>
            <InputMask
                mask={'+\\9\\98 (99) 999-99-99'}
                value={value}
                disabled={false}
                maskPlaceholder={'_'}
                onChange={(e) => {
                    setValue(e.target.value.replace(/[^0-9]+/g, ''));
                }}
            >
                <Input
                    error={error}
                    required={true}
                    label={'Телефон'}
                    type={'tel'}
                />
            </InputMask>

            {/*<Agreement>*/}
            {/*    <span>*/}
            {/*        R*/}
            {/*    </span>*/}
            {/*    <span>*/}
            {/*    By tapping <b>Sign Up</b>, you agree to our <AgreementBlue>Terms</AgreementBlue>, <AgreementBlue>Data Policy</AgreementBlue> and <AgreementBlue>Cookies Policy</AgreementBlue>.*/}
            {/*    </span>*/}
            {/*</Agreement>*/}

            <Button
                fullWidth
                type={'submit'}
                loading={loader}
                variant={'contained'}
                color={'primary'}
                disableElevation>
                NEXT
            </Button>

        </Root>
    );
});

const Root = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

const Agreement = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 12px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7F92A0;
`;

const AgreementBlue = styled.span`
  font-weight: 400;
  font-size: 14px;
  background: linear-gradient(165.92deg, #1DA1F2 3.35%, #003CC5 72.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  cursor: pointer;
`;