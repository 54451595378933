export const quiz = {
    get_all() {
        return `/QMS/api/v1.0/public/quiz/`;
    },
    post() {
        return `/QMS/api/v1.0/public/quiz/`;
    },
    get_quiz(id: number) {
        return `/QMS/api/v1.0/public/quiz/${id}/`;
    },
    put_quiz(id: number) {
        return `/QMS/api/v1.0/public/quiz/${id}/`;
    },
    patch_quiz(id: number) {
        return `/QMS/api/v1.0/public/quiz/${id}/`;
    },
    delete_quiz(id: number) {
        return `/QMS/api/v1.0/public/quiz/{id}/`;
    },
    post_quiz(id: number) {
        return `/QMS/api/v1.0/public/quiz/${id}/new_session/`;
    }
};
export const quiz_categori = {
    get_all() {
        return `/QMS/api/v1.0/public/category/`;
    },
    get_categori(id: number) {
        return `/QMS/api/v1.0/public/category/${id}/`;
    }
};
export const quiz_question = {
    get_all(quiz_id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/`;
    },
    post(quiz_id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/`;
    },
    get_question(quiz_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${id}/`;
    },
    put_question(quiz_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${id}/`;
    },
    patch_question(quiz_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${id}/`;
    },
    delete_question(quiz_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${id}/`;
    }
};
export const quiz_question_answer = {
    get_all(quiz_id: number, question_id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${question_id}/answer/`;
    },
    post(quiz_id: number, question_id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${question_id}/answer/`;
    },
    get_answer(quiz_id: number, question_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${question_id}/answer/${id}/`;
    },
    put_answer(quiz_id: number, question_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${question_id}/answer/${id}/`;
    },
    patch_answer(quiz_id: number, question_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${question_id}/answer/${id}/`;
    },
    delete_answer(quiz_id: number, question_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${question_id}/answer/${id}/`;
    }
};
export const quiz_session = {
    get_all() {
        return `/QMS/api/v1.0/public/session/`;
    },
    get(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/`;
    },
    patch_calculate_places(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/calculate_places/`;
    },
    patch_close_registration(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/close_registration/`;
    },
    patch_close_session(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/close_session/`;
    },
    post_connect(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/connect/`;
    },
    get_is_creator(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/is_creator/`;
    },
    patch_participant(id:number) {
        return `/QMS/api/v1.0/public/session/{id}/participant/`;
    },
    get_reconnect(id:number){
        return `/QMS/api/v1.0/public/session/${id}/reconnect/`;
    },
    get_show_my_result(id:number){
        return `/QMS/api/v1.0/public/session/${id}/show_my_result/`;
    },
    get_show_question_result(id:number){
        return `/QMS/api/v1.0/public/session/${id}/show_question_result/`;
    },
    get_show_result(id:number){
        return `/QMS/api/v1.0/public/session/${id}/show_result/`;
    },
    get_show_winner(id:number){
        return `/QMS/api/v1.0/public/session/${id}/show_winner/`;
    } 
};
export const quiz_session_sync = {
    post(id:number){
        return `/QMS/api/v1.0/public/session-sync/${id}/answer/`;
    },
    get_check_answers(id:number){
        return `/QMS/api/v1.0/public/session-sync/${id}/check_answers/`;
    },
    get_check_timer(id:number){
        return `/QMS/api/v1.0/public/session-sync/${id}/check_timer/`;
    },
    get_is_answered(id:number){
        return `/QMS/api/v1.0/public/session-sync/${id}/is_answered/`;
    },
    patch_next_question(id:number){
        return `/QMS/api/v1.0/public/session-sync/${id}/next_question/`;
    },
    get_show_question(id:number){
        return `/QMS/api/v1.0/public/session-sync/${id}/show_question/`;
    },
    patch_start_timer(id:number){
        return `/QMS/api/v1.0/public/session-sync/${id}/start_timer/`;
    }
};
export const quiz_session_async = {
    post(id:number){
        return `/QMS/api/v1.0/public/session-async/${id}/answer/`;
    },
    get_check_timer(id:number){
        return `/QMS/api/v1.0/public/session-async/${id}/check_timer/`;
    },
    get_is_answered(id:number){
        return `/QMS/api/v1.0/public/session-async/${id}/is_answered/`;
    },
    patch_next_question(id:number){
        return `/QMS/api/v1.0/public/session-async/${id}/next_question/`;
    },
    get_show_question(id:number){
        return `/QMS/api/v1.0/public/session-async/${id}/show_question/`;
    },
    patch_start_timer(id:number){
        return `/QMS/api/v1.0/public/session-async/${id}/start_timer/`;
    }
};
export const quiz_image = {
    get_all(quiz_id:number){
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/image/`;
    },
    post(quiz_id:number){
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/image/`;
    },
    get(quiz_id:number, id:number){
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/image/${id}/`;
    },
    patch(quiz_id:number, id:number){
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/image/${id}/`;
    },
    delete(quiz_id:number, id:number){
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/image/${id}/`;
    }
};
export const session = {
    get(){
        return `/QMS/api/v1.0/public/session/creators/`;
    }
};
export const quiz_stat = {
    get_all(){
        return `/QMS/api/v1.0/public/stat/`;
    },
    get(id:number){
        return `/QMS/api/v1.0/public/stat/${id}/`;
    },
    get_my_stat(){
        return `/QMS/api/v1.0/public/stat/my/`;
    }
};