/**
 * Created by Hennadiy on 13.02.2023
 */
import {makeAutoObservable} from 'mobx';
import {ServiceStore} from 'gears-react';

import Services from '../api/services';
import * as types from '../api/types';

export class Store {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly getQuizQuestion = new ServiceStore<[AbortController, types.IQuizQuestionParams], types.IQuizQuestionResponse>(Services.getQuizQuestion, true);
    public readonly patchQuizQuestion = new ServiceStore<[types.IQuizQuestionParams, types.IQuizQuestionRequestBody],
        types.IQuizQuestionResponse>(Services.patchQuizQuestion, false);

    public readonly postQuizQuestion = new ServiceStore<[types.IPostQuizQuestionParams, types.IQuizQuestionRequestBody],
        types.IQuizQuestionResponse>(Services.postQuizQuestion, false);
}