import React, {useContext, useEffect, useState} from 'react';

import {Context as globalContext} from 'shared/lib/context';
import {Context as UmsAccountContext} from 'shared/api/ums/account';

import {Context} from './context';
import {Props} from './props';


export const useCreateUser = (props: Props) => {
    const abortController = new AbortController();
    const {store} = useContext(Context);
    const global = useContext(globalContext);
    const account = useContext(UmsAccountContext);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, []);

    const [error, setError] = useState(false);

    const [name, setName] = useState<string>('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        store.postCreate.request(abortController, {
            name: name,
            password: props.password,
            phone: props.phone,
            username: props.username,
        }).then(r => {
            console.log(r);
            if (r?.status === 201) {
                setError(false);
                localStorage.setItem('access', store.postCreate.get().access);
                localStorage.setItem('refresh', store.postCreate.get().refresh);

                account.store.details.request().then(r => {
                    if (r.status !== 200) {
                        account.store.setIsAuth(false);
                    } else {
                        account.store.setIsAuth(true);
                    }
                });

            } else {
                if (!abortController.signal.aborted) {
                    global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                }
                setError(true);
            }
        });

    };

    return {
        error,
        name,
        setName,
        handleSubmit,
        loader: store.postCreate.loader.get(),
    };
};
