/**
 * Created by Hennadiy on 16.02.2023
 */

import {$axios} from './instances';
import {AxiosResponse} from 'axios';

import {urls} from './urls';
import * as types from './types';

export default class Services {
    static async postAuth(
        {
            signal
        }: AbortController,
        {
            login_params,
            phone,
            pvc,
            password,
            username,
        }: types.IAuthParams,
    ): Promise<AxiosResponse<types.IAuthResponse>> {
        return await $axios.post<types.IAuthResponse>(urls.postAuth(), {
            phone,
            pvc,
            username,
            password,
        }, {
            signal: signal,
            params: {
                login_params
            },
        });
    }
}