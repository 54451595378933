/**
 * Created by Hennadiy on 28.02.2023
 */
import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';

import {ErrorFallback} from 'shared/ui/components/general';

import {withProviders} from '../providers';
import {Props} from '../lib/props';

import {CheckPassword} from './CheckPassword';

const Lazy = ({...props}: Props) => {
    return <ErrorBoundary FallbackComponent={ErrorFallback}><CheckPassword {...props}/></ErrorBoundary>;
};

export default withProviders(Lazy);