import React from 'react';
import {observer} from 'mobx-react-lite';
import {GetImage} from 'shared/ui/components/general/GetImage';
import styled from 'styled-components';

type Props = {
    src?: string;
    questionNumber?: number;
    totalQuestionNumber?: number;
}
export const QuestionDetail = observer(({src, questionNumber, totalQuestionNumber}: Props) => {
    return (
        <Root>
            <QuesionNumber>Quesion {questionNumber || '...'} of {totalQuestionNumber || '...'}</QuesionNumber>
            {src !== undefined && <GetImage
                src={src}
                style={{
                    width: '100%',
                    height: '175px',
                    borderRadius: '8px',
                    flexShrink: 0,
                    margin: '0 auto 16px auto',
                }}
            />}
            <Text>
                    II jahon urushi davrida nemislar Leningradni bosib olganlarida bir itlar fermasiga duch keladi. Undagi
                    barcha itlarni o’ldirmoqchi bo’ladilar. Lekin nemislar bu fikridan qaytadi. Agarda bunda hech qanday
                    harbiy maqsadlar ko’zlanmagan bo’lsa, unda ularning bunday qarorga kelishining sababi nimada edi?
                    Agarda bunda hech qanday harbiy maqsadlar ko’zlanmagan bo’lsa, unda ularning bunday qarorga kelishining
                    sababi nimada edi?
            </Text>
        </Root>
    );
}
);

const Root = styled.div`
  width: 100%;

`;
const QuesionNumber = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #7F92A0;
  margin-bottom: 8px;
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #262626;
`;

