/**
 * Created by Hennadiy on 05.03.2023
 */

import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Calendar} from 'iconsax-react';
import {Dayjs} from 'dayjs';
import {TextField} from '@mui/material';
import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker';

import {InputDrawer} from 'entities/general';

type Props = {
    value: Dayjs | null;
    setValue: Dispatch<SetStateAction<Dayjs | null>>;
    required?: boolean;
    error?: boolean;
}

export const AgeInput = ({value, setValue, required, error}: Props) => {

    const [open, setOpen] = useState(false);

    const months = [
        '01', '02', '03', '04', '05', '06', '07',
        '08', '09', '10', '11', '12'
    ];

    useEffect(() => {
        // console.log(value?.toISOString())
        // console.log(value)
        // if(value !== null) {
        //     console.log(`${value?.year()}-${months[value?.month()! | 0]}-${value?.date()}`);
        // }
    }, [value]);


    return (<>

        <InputDrawer
            open={open}
            setOpen={setOpen}
            label={'Date birth'}
            icon={<Calendar
                size="24"
                color="#7F92A0"
            />}
            required={required}
            value={value !== null ? `${value?.date()}.${months[value?.month()! | 0]}.${value?.year()}` : ''}
            error={error}
        >
            <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="year"
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                    // setAnchorEl(null);
                }}
                // renderInput={(params) => <TextField {...params} />}
            />
        </InputDrawer>

    </>);
};