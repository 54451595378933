import React, {Dispatch, SetStateAction, useState} from 'react';
import {InputAdornment} from '@mui/material';
import {Eye, EyeSlash} from 'iconsax-react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';

import {AgeInput} from 'features/general';

import {Button, Input} from 'shared/ui/components/client';

import dayjs, {Dayjs} from 'dayjs';


type Props = {
    error?: boolean;
    loader?: boolean;
    handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;

    name: string;
    setName: Dispatch<SetStateAction<string>>;
}

export const Form = observer(({error, handleSubmit, loader, setName, name}: Props) => {

    const [showPassword, setShowPassword] = useState(false);

    const [birthday, setBirthday] = React.useState<Dayjs | null>(null);
    // const [birthday, setBirthday] = React.useState<Dayjs | null>(dayjs(Date.now()));

    return (
        <Root onSubmit={handleSubmit}>
            <Input
                error={error}
                required={true}
                label={'Имя'}
                value={name}
                onChange={(e) => {
                    setName(e.target.value);
                }}
            />

            <Input
                error={error}
                required={false}
                label={'Фамилия'}
                // value={login.toLowerCase()}
                // onChange={(e) => {
                //     setLogin(e.target.value.toLowerCase());
                // }}
            />

            <Input
                error={error}
                required={false}
                label={'Отчество'}
                // value={login.toLowerCase()}
                // onChange={(e) => {
                //     setLogin(e.target.value.toLowerCase());
                // }}
            />

            <AgeInput
                required={true}
                // error={error}
                value={birthday}
                setValue={setBirthday}
            />

            {/*<Input*/}
            {/*    // error={error}*/}
            {/*    required={true}*/}
            {/*    label={'Дата Рождения'}*/}
            {/*    // value={login.toLowerCase()}*/}
            {/*    // onChange={(e) => {*/}
            {/*    //     setLogin(e.target.value.toLowerCase());*/}
            {/*    // }}*/}
            {/*/>*/}

            <Input
                // error={error}
                required={false}
                label={'Profession'}
                // value={login.toLowerCase()}
                // onChange={(e) => {
                //     setLogin(e.target.value.toLowerCase());
                // }}
            />

            <Input
                // error={error}
                required={false}
                label={'Location'}
                // value={login.toLowerCase()}
                // onChange={(e) => {
                //     setLogin(e.target.value.toLowerCase());
                // }}
            />

            <Button
                fullWidth
                type={'submit'}
                loading={loader}
                variant={'contained'}
                color={'primary'}
                disableElevation>
                SIGN UP
            </Button>
        </Root>
    );
});

const Root = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;