import React from "react";
import styled from "styled-components";

import icon1 from './assets/iconApto.svg';
import icon2 from './assets/appleLogo.svg';
import icon3 from './assets/googlePlayIcon.svg';

import style from './Footer.module.css';
import {Container} from "../../shared/StyledComponents";

export const Footer = () => {
    return (
        <div className={style.footer}>
            <Container>
                <div className={style.footerTitle}>
                    <div className={style.footerTitleText}>
                        Download for Aptoide/iOS/Android
                    </div>
                    <div className={style.footerTitleIcon}>
                        <a><Logo src={icon1} /></a>
                        <a><Logo src={icon2} /></a>
                        <a href={'https://play.google.com/store/apps/details?id=com.t_med.t_med_web&hl=ru&gl=US'} target="_blank"><Logo src={icon3} /></a>
                    </div>
                </div>
                <div className={style.footerInfoContainer}>
                    <div className={style.footerInfo}>
                        <div className={style.footerInfoH}>info@dwed.biz</div>
                        <div className={style.footerInfoC}>Uzbekistan, Tashkent, Labzak 12/1 100000</div>
                        <div className={style.footerInfoF}>
                            Privacy Terms API
                        </div>
                    </div>
                    <div className={style.footerInfoRecent}>
                        <div className={style.footerInfoRecentF}>
                            All right recieved by DWED 2023
                        </div>
                        <div className={style.footerInfoRecentH}>
                            <a href={'https://t-med.uz/'} target="_blank">T-MED.UZ</a>
                            <a href={'https://mpd.co.com/'} target="_blank">MPD.CO.COM</a>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
type LogoProps = {
    src: string;
}

const Logo = styled.div`
  width: 300px;
  height: 100px;
  background-image: url(${({src}: LogoProps) => src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #231F20;
  border-radius: 15px;
  
  @media(max-width: 1111px){
    height: 80px;
    background-size: 270px;
  }
`