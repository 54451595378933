import React from 'react';
import styled from 'styled-components';

import clock from './assets/clock.svg';
import usersImg from './assets/users.svg';

type Props = {
    time?: string;
    users?: string;
}

export const TimeUsers = ({users, time}: Props) => {
    return <>
        {
            time && users && <SpaceBetween>
                <Row style={{gap: '4px'}}>
                    <Icon src={clock}/>
                    <Subtitle>{time}</Subtitle>
                </Row>
                <Row style={{gap: '4px'}}>
                    <Icon src={usersImg}/>
                    <Subtitle>{users}</Subtitle>
                </Row>
            </SpaceBetween>
        }
    </>;
};

const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: var(--gray);

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`;