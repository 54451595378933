import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";


const Paralax = React.lazy(()=> import('../paralax/Paralax') );

export const Content = () => {
    const [scrollY, setScrollY] = useState<number>(0);

    const handleScroll = () => {
        const positionY = window.scrollY;
        setScrollY(positionY);
    };

    const element = useRef<HTMLDivElement>(null);

    useEffect(() => {

        let observerRefValue: HTMLDivElement | null = null; // <-- variable to hold ref value

        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                window.addEventListener('scroll', handleScroll, {passive: true});
            } else {
                if (scrollY >= 10000) {
                    setScrollY(11590)
                } else {
                    setScrollY(0)
                }
                window.removeEventListener('scroll', handleScroll);
            }
        }, {
            root: null,
            threshold: 1,
        });

        if (element.current) {
            observer.observe(element.current);
            observerRefValue = element.current; // <-- save ref value
        }

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue); // <-- use saved value
            window.removeEventListener('scroll', handleScroll);
        };

    }, [element, scrollY]);


    return (
        <Root>
            <Sticky ref={element}>
                <Container>
                    <React.Suspense>
                    <Paralax scrollY={scrollY >= 1000 ? scrollY - 1000 : 0} setScrollY={setScrollY}/>
                    </React.Suspense>
                </Container>
            </Sticky>
        </Root>
    )
}

const Root = styled.div`
  width: 100%;
  height: 11590px;
  z-index: 1;
`;

const Sticky = styled.div`
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  align-items: center;
  gap: 2px;
  padding: 10px;
  height: 100vh;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1792px;
  margin: 0 auto;
  padding: 0px 32px 0px 32px;
  @media (max-width: 1652px) {
    padding: 32px;
  }
  @media (max-width: 460px) {
    padding: 10px;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  zoom: 75%;

`;