export const publicRegion = {
    list() {
        return `/GMS/api/v1.0/public/region/`;
    },
    get(id: number) {
        return `/GMS/api/v1.0/public/region/${id}/`;
    },
};

export const publicRegionPath = {
    get(id: number) {
        return `/GMS/api/v1.0/public/region/${id}/path/`;
    },
};

export const publicRegionType = {
    list() {
        return `/GMS/api/v1.0/public/region_type/`;
    },
    get(id: number) {
        return `/GMS/api/v1.0/public/region_type/${id}/`;
    },
};