import React from 'react';
import {UserOctagon} from 'iconsax-react';
import styled from 'styled-components';

export const OnlineParticipant = () => {
    return (
        <Root>
            <UserOctagon
                size="16"
                color="#262626"
                style={{
                    flexShrink: 0
                }}
            />
            <Count>
                9999
            </Count>
        </Root>
    );
};

const Root = styled.div`
  width: 74px;
  height: 24px;
  background: #FFFFFF;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  overflow: hidden;
  padding: 0 10px;
`;
const Count = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #262626;
  flex-shrink: 1;
  text-overflow: ellipsis;
  overflow: hidden;
`;

