/**
 * Created by Hennadiy on 13.02.2023
 */

import {useContext, useEffect, useState} from 'react';
import {Context as globalContext} from 'shared/lib/context';

import {Context} from './context';
import {useParams} from 'react-router';

type QuizSessionParams = {
    session_id: string;
};


export const useGetQuizSessionRole = () => {
    const {store} = useContext(Context);
    const global = useContext(globalContext);
    const abortController = new AbortController();
    const {session_id} = useParams<QuizSessionParams>();
    const [notFound, setNotFound] = useState<boolean>(false);

    useEffect(() => {
        if (session_id !== undefined && session_id !== null && !isNaN(Number(session_id))) {
            store.getIsQuizSessionCreator.request(abortController, {session_id: Number(session_id)}).then(r => {
                console.log(r);
                if (r?.status !== 200 && r?.status !== 400) {
                    setNotFound(true);
                    if (!abortController.signal.aborted) {
                        global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                    }
                }
            });
        } else {
            setNotFound(true);
            store.getIsQuizSessionCreator.loader.set(false);
        }

        return () => {
            store.getIsQuizSessionCreator.loader.set(true);
            abortController.abort();
        };

    }, []);


    return {
        notFound
    };
};