import React, {useContext, useEffect, useState} from 'react';

import {Context as globalContext} from 'shared/lib/context';
import {Context} from './context';
import {LoginParams} from '../api/types';
import {Props} from './props';

export const useCheckUser = (props: Props) => {
    const abortController = new AbortController();
    const {store} = useContext(Context);
    const global = useContext(globalContext);

    const [error, setError] = useState(false);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        store.postCheckUser.request(abortController, {login_params: 'phone', phone: props.phone})
            .then(r => {
                console.log(r);
                if (r?.status === 200) {
                    setError(true);
                    global.store.alert.set(true, 'error', 'Пользователь существует');
                } else if (r?.status === 201) {
                    if (store.postCheckUser.get().status) {
                        global.store.alert.set(true, 'error', `Пользователь существует`);
                        setError(true);
                    } else {
                        setError(false);
                        props.next();
                    }
                } else {
                    if (!abortController.signal.aborted) {
                        global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                    }
                    setError(true);
                }
            });
    };

    return {
        error,
        loader: store.postCheckUser.loader.get(),
        handleSubmit
    };
};