/**
 * Created by Hennadiy on 14.02.2023
 */
import styled from '@emotion/styled';

import Switch, {SwitchProps} from '@mui/material/Switch';
import React from 'react';

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
    width: 42,
    height: 26,
    padding: 0,
    margin: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                background: 'linear-gradient(165.92deg, #1DA1F2 3.35%, #003CC5 72.58%)',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#FFFFFF',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: '#FFFFFF',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        background: 'linear-gradient(165.92deg, #1DA1F2 3.35%, #003CC5 72.58%)',
        opacity: 0.25,
        transition: 'opacity 0.5s',
    },
}));

type Props = {
    checked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    required?: boolean;
}

export function MySwitch({checked, onChange, required}: Props) {
    return (
        <IOSSwitch required={required} onChange={onChange} sx={{m: 1}} checked={checked}/>
    );
}
