import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {SplideSlide} from '@splidejs/react-splide';
import {Skeleton} from '@mui/material';
import styled from 'styled-components';

import {InfiniteScroll} from 'shared/ui/components/general';
import {HorizontalScroll} from 'shared/ui/components/general';
import {IsNumber} from 'shared/lib/helpers';

import {useList} from '../lib/hooks';
import {Context} from '../lib/context';
import {Props} from '../lib/props';
import {StyledTab} from './StyledTab';

export const QuestionNumberList = observer((props: Props) => {

    const {store} = useContext(Context);
    const {loading, question_id, changeQuestion, goToCreate} = useList(props);
    const loaders = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    return (
        <Root>
            <HorizontalScroll>
                <InfiniteScroll offset={store.offset} nextOffset={store.getQuizQuestionList.get().next_offset}
                                setOffset={(offset) => store.setOffset(offset)}
                                isLoading={store.getQuizQuestionList.loader.get()}
                                totalCount={store.getQuizQuestionList.get().count}>

                    {
                        loading ? <>
                            {loaders.map((e, i) => <SplideSlide key={i}>
                                <Skeleton sx={{borderRadius: '50%', transform: 'none'}} width={32} height={32}/>
                            </SplideSlide>)}
                        </> : <>

                            {store.getList().map((e) =>
                                <SplideSlide key={e.id} onClick={() => changeQuestion(e.id)}>
                                    <StyledTab
                                        style={Number(question_id) === e.id ? {
                                            background: 'linear-gradient(143.49deg, #1DA1F2 0%, #003CC5 125.35%)',
                                            color: 'white',
                                        } : {
                                            color: 'var(--black)',
                                            background: `white`,
                                        }}>
                                        {`${e.index + 1}`}
                                    </StyledTab>
                                </SplideSlide>
                            )}

                            <SplideSlide onClick={() => goToCreate()}>
                                <StyledTab style={!IsNumber(question_id) ? {
                                    background: 'linear-gradient(143.49deg, #1DA1F2 0%, #003CC5 125.35%)',
                                    color: 'white',
                                } : {
                                    color: 'var(--gray)',
                                    background: `white`,
                                }}>
                                    {`${store.getList().length + 1}`}
                                </StyledTab>
                            </SplideSlide>

                            {store.getQuizQuestionList.loader.get() &&
                                loaders.map((e, i) => <SplideSlide key={i}>
                                    <Skeleton sx={{borderRadius: '50%', transform: 'none'}} width={32} height={32}/>
                                </SplideSlide>)}
                        </>
                    }

                </InfiniteScroll>
            </HorizontalScroll>
        </Root>
    );
});

const Root = styled.div`
  width: 100%;
  overflow: hidden;
`;