/**
 * Created by Hennadiy on 13.02.2023
 */

import * as React from 'react';
import {Global} from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {Dispatch, SetStateAction, useEffect} from 'react';
import styled from '@emotion/styled';

import drawer_header from '../assets/DrawerHeader.svg';

const drawerBleeding = 0;

interface Props {
    open: boolean;
    children: React.ReactNode;
    setOpen: Dispatch<SetStateAction<boolean>>;
    label?: string;
}


const StyledBox = styled(Box)(({theme}) => ({
    backgroundColor: '#fff',
}));

export function SwipeableEdgeDrawer({open, children, setOpen, label}: Props) {
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    // This is used only for the example
    const container = document.body;

    useEffect(() => {
        if (open) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'unset';
        }
    }, [open]);

    return (
        <div className={'MAX_WIDTH_PAGE_FIXED'}>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(50% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                    },
                }}
            />

            <MySwipeableDrawer
                className={'MAX_WIDTH_PAGE_FIXED'}
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    '.MuiBackdrop-root': {
                        background: 'rgba(38, 38, 38, 0.5)'
                    },
                    '.MuiPaper-root': {
                        background: 'rgba(255,255,255,0)',
                        boxShadow: 'none'
                    }
                }}
            >
                <ImageBox src={drawer_header}>
                    {/*<DrawerHeader src={drawer_header} draggable={false}/>*/}
                </ImageBox>

                <StyledBox
                    sx={{
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                        backgroundColor: 'rgba(255,255,255,0) !important'
                    }}
                >

                    {label !== undefined && open &&
                        <Typography
                            sx={{
                                padding: '0 0 24px 0',
                                color: 'text.primary',
                                textAlign: 'center',
                                fontWeight: '700',
                                fontSize: '24px',
                                backgroundColor: 'white',
                            }}>
                            {label}
                        </Typography>
                    }

                </StyledBox>
                <StyledBox
                    sx={{
                        position: 'relative',
                        // marginTop: '76px',
                        height: '100%',
                        overflowY: 'scroll',
                        padding: '0px 16px 100px 16px'
                    }}
                >
                    {children}
                </StyledBox>

            </MySwipeableDrawer>
        </div>
    );
}

const MySwipeableDrawer = styled(SwipeableDrawer)`
  .MuiDrawer-paper {
    border-radius: 25px 25px 0 0 !important;
    overflow-x: hidden
  }

  z-index: 999999999;
`;

const DrawerHeader = styled.img`
  width: 100%;
  height: 100%;

  user-select: none;
  -webkit-user-select: none;

  position: absolute;
  top: 0;
  left: 0;
  //transform:translate(-50%,-50%);
  object-fit: contain;
`;

type ImageBoxProps = {
    src: string;
}

const ImageBox = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 24px;

  flex-shrink: 0;

  background-image: url(${({src}: ImageBoxProps) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
`;
