import {ServiceStore} from 'gears-react';
import {makeAutoObservable} from 'mobx';

import {
    PublicRegionPathService,
    PublicRegionService,
    PublicRegionTypeService} from './services';
import {
    ID,
    IGetPublicRegionListParams,
    IGetPublicRegionPathParams,
    IGetPublicRegionPathResponse,
    IGetPublicRegionResponse,
    IGetPublicRegionTypeResponse,
    IListResponse,
    IPublicRegionListItemResponse,
} from './types';



class PublicRegionStore {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly list = new ServiceStore<[IGetPublicRegionListParams], IListResponse<IPublicRegionListItemResponse>>(PublicRegionService.list, false);
    public readonly get = new ServiceStore<[ID], IGetPublicRegionResponse>(PublicRegionService.get, false);

}

class PublicRegionPathStore {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly get = new ServiceStore<[IGetPublicRegionPathParams], IGetPublicRegionPathResponse[]>(PublicRegionPathService.get, false);

}

class PublicRegionTypeStore {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly list = new ServiceStore<[], IListResponse<IGetPublicRegionTypeResponse>>(PublicRegionTypeService.list, false);
    public readonly get = new ServiceStore<[ID], IGetPublicRegionTypeResponse>(PublicRegionTypeService.get, false);
}


export class Store {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly publicRegion = new PublicRegionStore();
    public readonly publicRegionPath = new PublicRegionPathStore();
    public readonly publicRegionType = new PublicRegionTypeStore();
}