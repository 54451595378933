/**
 * Created by Hennadiy on 13.02.2023
 */

import {useContext, useEffect, useState} from 'react';

import {Context as globalContext} from 'shared/lib/context';

import {Context} from './context';

export const useList = () => {
    const {store} = useContext(Context);
    const global = useContext(globalContext);

    const abortController = new AbortController();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            store.setList([]);
            store.setLoading(true);
            store.setOffset(0);
            store.setSearch('');

            store.getQuizCategoryList.request(abortController, {
                limit: store.limit,
                offset: 0,
            }).then(r => {
                console.log(r);
                store.setLoading(false);
                if (r?.status === 200) {
                    store.setList(store.getQuizCategoryList.get().results);
                } else {
                    if (!abortController.signal.aborted) {
                        global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                    }
                }
            });

            return () => {
                store.setList([]);
                store.setLoading(true);
                store.setOffset(0);
                store.setSearch('');
                abortController.abort();
            };
        }
    }, [open]);

    useEffect(() => {
        if (open) {
            if (!store.getQuizCategoryList.loader.get()) {
                reload();
            }
        }
    }, [store.offset, open]);

    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                if (!store.getQuizCategoryList.loader.get()) {
                    store.setList([]);
                    store.setOffset(0);
                    store.getQuizCategoryList.request(abortController, {
                        limit: store.limit,
                        offset: 0,
                        search: store.search,
                    }).then(r => {
                        console.log(r);
                        if (r?.status === 200) {
                            store.setList(store.getQuizCategoryList.get().results);
                        } else {
                            if (!abortController.signal.aborted) {
                                global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                            }
                        }
                    });
                }
            }, 1000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [store.search, open]);

    const reload = () => {
        if (open) {
            store.getQuizCategoryList.request(abortController, {
                limit: store.limit,
                offset: store.offset,
                search: store.search,
            }).then(r => {
                console.log(r);
                if (r?.status === 200) {
                    const new_list = store.getQuizCategoryList.get().results.filter((category) =>
                        store.getList().every(item => item.id !== category.id));

                    if (store.offset <= 0) {
                        store.setList(store.getQuizCategoryList.get().results);
                    } else {
                        store.setList([...store.getList(), ...new_list]);
                    }
                } else {
                    if (!abortController.signal.aborted) {
                        global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                    }
                }
            });
        }
    };

    return {
        open,
        setOpen
    };
};