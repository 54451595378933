/**
 * Created by Hennadiy on 13.02.2023
 */
import {makeAutoObservable} from 'mobx';
import {ServiceStore} from 'gears-react';

import Services from '../api/services';
import * as types from '../api/types';

export class Store {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly postCheckPvc = new ServiceStore<[AbortController, types.ICheckPvcParams], types.ICheckPvcResponse>
    (Services.postCheckPvc, false);
}