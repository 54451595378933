import styled from '@emotion/styled';
import {LoadingButton} from '@mui/lab';

export const Button = styled(LoadingButton)`
  padding: 12px 30px;
  font-weight: 500;
  font-size: 16px;

  height: 48px;
  border-radius: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-transform: initial;
  transition: all .2s;


  background: linear-gradient(143.49deg, #1DA1F2 0%, #003CC5 125.35%);
  color: #fff;
  opacity: 1;

  span {
    color: rgba(255, 255, 255, 0);

    * {
      color: rgb(255, 255, 255);
    }
  }

  :hover {
    background-color: rgba(255, 255, 255, 0);
  }


  
  &.MuiLoadingButton-loading {
    :disabled {
      opacity: 0.8;
      background: linear-gradient(143.49deg, #1DA1F2 0%, #003CC5 125.35%);
      color: transparent;
    }
  }

  :disabled {
    opacity: 1;
    background: var(--gray);
    color: white;
  }
`;