/**
 * Created by Hennadiy on 28.02.2023
 */
import {getRoutes} from 'gears-react';

export const client_routes = getRoutes([
    ['*', [<div>client_routes</div>]],

]);

export const creator_routes = getRoutes([
    ['*', [<div>creator_routes</div>]],

]);
