import {AxiosResponse} from 'axios';

import {$ums_account, $ums_another_account, $ums_create_account} from './instances';
import {
    AccountCreateResponse,
    AccountDetail,
    AuthResponse, ChangePasswordRequest,
    CheckUserNameParams,
    CheckUserNameResponse,
    CheckUserResponse
} from './types';
import {AccountCreateRequest, AuthParams, AuthRequest} from './types';
import {account} from './urls';


export class Account {
    static async get(): Promise<AxiosResponse<AccountDetail>> {
        return await $ums_account.get<AccountDetail>(account.get());
    }

    static async get_another_user(): Promise<AxiosResponse<AccountDetail>> {
        return await $ums_another_account.get<AccountDetail>(account.get());
    }

    static async auth({login_params}: AuthParams,
        {
            phone,
            pvc,
            username,
            qrcode,
            password,
            passport_series,
            passport_number,
            orgslug
        }: AuthRequest): Promise<AxiosResponse<AuthResponse>> {
        const body = new FormData();
        if (phone !== undefined) {
            body.append('phone', phone);
        }
        if (pvc !== undefined) {
            body.append('pvc', pvc);
        }
        if (username !== undefined) {
            body.append('username', username);
        }
        if (qrcode !== undefined) {
            body.append('qrcode', qrcode);
        }
        if (password !== undefined) {
            body.append('password', password);
        }
        if (passport_series !== undefined) {
            body.append('passport_series', passport_series);
        }
        if (passport_number !== undefined) {
            body.append('passport_number', passport_number);
        }

        return await $ums_account.post<AuthResponse>(account.auth(), body, {
            params: {
                login_params
            },
            headers: {
                orgslug
            }
        });
    }

    static async check_user(
        {
            login_params
        }: AuthParams,
        {
            phone,
            passport_series,
            passport_number
        }: AuthRequest): Promise<AxiosResponse<CheckUserResponse>> {

        const body = new FormData();
        if (phone !== undefined) {
            body.append('phone', phone);
        }
        if (passport_series !== undefined) {
            body.append('passport_series', passport_series);
        }
        if (passport_number !== undefined) {
            body.append('passport_number', passport_number);
        }

        return await $ums_account.post<CheckUserResponse>(account.check_user(), body, {
            params: {
                login_params
            }
        });
    }

    static async check_username(
        {
            username
        }: CheckUserNameParams): Promise<AxiosResponse<CheckUserNameResponse>> {

        return await $ums_account.get<CheckUserNameResponse>(account.check_username(username));
    }

    static async create(
        {
            lastname,
            region,
            name,
            username,
            phone,
            gender,
            surname,
            main_cat,
            birthday,
            passport_series,
            passport_number
        }: AccountCreateRequest): Promise<AxiosResponse<AccountCreateResponse>> {

        const body = new FormData();

        if (name !== undefined) {
            body.append('name', name);
        }
        if (lastname !== undefined) {
            body.append('lastname', lastname);
        }

        if (region !== undefined) {
            body.append('region', region.toString());
        }
        if (username !== undefined) {
            body.append('username', username);
        }
        if (phone !== undefined) {
            body.append('phone', phone);
        }
        if (gender !== undefined) {
            body.append('gender', gender);
        }
        if (surname !== undefined) {
            body.append('surname', surname);
        }
        if (main_cat !== undefined) {
            body.append('main_cat', main_cat.toString());
        }
        if (birthday !== undefined) {
            body.append('birthday', birthday.toString());
        }
        if (passport_series !== undefined) {
            body.append('passport_series', passport_series.toString());
        }
        if (passport_number !== undefined) {
            body.append('passport_number', passport_number.toString());
        }
        return await $ums_account.post<AccountCreateResponse>(account.create(), body);
    }

    static async refresh_token(
        {refresh}: { refresh: string }): Promise<AxiosResponse<AuthResponse>> {
        return await $ums_account.post<AccountCreateResponse>(account.refresh_token(), {
            refresh
        });
    }

    static async change_password({password1, password2, access}: ChangePasswordRequest): Promise<AxiosResponse> {
        return await $ums_create_account.post(account.change_password(), {
            password1,
            password2
        }, {
            headers: {
                Authorization: `Bearer ${access}`
            }
        });
    }

}