import styled from 'styled-components';

export const DebugRoot = styled.div`
  background-color: var(--bg-gray);
  width: 100%;
  top: 0;
  left: 0;
  padding: 16px;
  min-height: 100vh;
`;

// Components Card
export const CC = styled.div`
  width: 100%;
  border: 1px dashed var(--black);
  padding: 16px;
  margin-bottom: 16px;
`;

// Card Title
export const CT = styled.div`
  color: var(--black);
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 16px;
  transition: color .3s;
`;

// Card Body
export const CB = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;