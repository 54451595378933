import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {CircularProgress, InputAdornment} from '@mui/material';
import {CloseCircle, TickSquare} from 'iconsax-react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';

import {Button, Input} from 'shared/ui/components/client';


type Props = {
    error?: boolean;
    loader?: boolean;
    handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
    value: string;
    setValue: Dispatch<SetStateAction<string>>;
    is_existed: boolean | undefined;
    disabled: boolean;
}

export const Form = observer(({handleSubmit, loader, value, setValue, error, is_existed, disabled}: Props) => {
    return (
        <Root onSubmit={handleSubmit}>
            <Input
                error={error}
                required={true}
                label={'Enter Username'}
                value={value.toLowerCase()}

                onChange={(e) => {
                    setValue(e.target.value.toLowerCase());
                }}

                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        {loader ? <CircularProgress size="24px" color="primary"/> :
                            is_existed === undefined ? '' : !is_existed ?
                                <TickSquare size="24" color="#2BCBBA"/> :
                                <CloseCircle size="24" color="#FF5A5F"/>
                        }
                    </InputAdornment>
                }}
            />

            <Button
                disabled={disabled}
                fullWidth
                type={'submit'}
                variant={'contained'}
                color={'primary'}
                disableElevation>
                NEXT
            </Button>
        </Root>
    );
});

const Root = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;