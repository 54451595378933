
import React from 'react';

import {Context} from '../../shared/lib/context';
import Store from '../../shared/lib/store/store';


const store = new Store();

export const withStore = (component: () => React.ReactNode) => () => (
    <Context.Provider value={{store}}>
        {component()}
    </Context.Provider>
);