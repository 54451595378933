/**
 * Created by Hennadiy on 13.02.2023
 */

import React, {useContext, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {ArrowLeft2, CloseCircle, SearchNormal1} from 'iconsax-react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';

import {Context} from '../lib/context';

export const SearchButton = observer(() => {
        const ref = useRef<HTMLInputElement>(null);
        const [fieldWidth, setFieldWidth] = useState(0);
        const navigate = useNavigate();
        const {store} = useContext(Context);

        return (
            <Root>
                <Row onClick={() => navigate('/')} style={{
                    cursor: 'pointer',
                }}>
                    <ArrowLeft2
                        size="24"
                        color={`#262626`}
                        style={{
                            flexShrink: 0
                        }}
                    />
                    <p style={{color: '#262626'}}>My Quiz</p>
                </Row>

                <SearchNormal1
                    style={{
                        flexShrink: 0,
                        cursor: 'pointer'
                    }}
                    size="24"
                    color="#7F92A0"
                    variant="Outline"
                    onClick={() => {
                        if (ref.current) {
                            ref.current.focus();
                        }
                        setFieldWidth(100);
                    }}
                />


                <Field width={fieldWidth}>

                    <MyInput
                        ref={ref}
                        value={store.search}
                        onChange={(e) => {
                            if (!store.getQuizList.loader.get()) {
                                store.setSearch(e.target.value);
                            }
                        }}
                        placeholder={'Поиск...'}
                    />

                    <CloseCircle
                        size="24"
                        color="#7F92A0"
                        variant="Outline"
                        style={{
                            flexShrink: 0,
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            setFieldWidth(0);
                            store.setSearch('');
                        }}
                    />
                </Field>

            </Root>
        );
    }
);

const Root = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const MyInput = styled.input`
  width: calc(100% - 40px);
  height: 40px;
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  outline: none;
  color: var(--black);
  font-size: 16px;

  ::placeholder {
    color: var(--gray);
    opacity: 1;
  }

`;

type FieldProps = {
    width: number;
}

const Field = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  overflow: hidden;
  background-color: #ffffff;
  height: 40px;
  display: flex;
  align-items: center;
  width: ${({width}: FieldProps) => width}%;
  justify-content: space-between;
  transition: width .3s;
  z-index: 9999999;
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--black);
  font-weight: 500;
  font-size: 16px;
`;