/**
 * Created by Hennadiy on 17.02.2023
 */
import {useContext, useEffect, useState} from 'react';
import {Context} from 'shared/api/ums/account';
export const useRefreshToken = () => {
    const account = useContext(Context);

    const [seconds, setSeconds] = useState(account.store.auth.get().expire_date || 0);

    useEffect(() => {
        if (!account.store.getIsAuth()) {
            setSeconds(account.store.auth.get().expire_date || 0);
        }
    }, [account.store]);

    useEffect(() => {
        setTimeout(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);
    });

    useEffect(() => {
        if (seconds <= 0) {
            if (localStorage.getItem('refresh') !== null) {
                account.store.refresh_token.request({refresh: localStorage.getItem('refresh')!}).then(r => {
                    console.log(r);
                    if (r!.status === 200) {
                        localStorage.setItem('access', r!.data.access);
                        localStorage.setItem('refresh', r!.data.refresh);
                        setSeconds(r!.data.expire_date!);
                    }
                });
            }
        }
    }, [seconds, account.store.refresh_token]);
};
