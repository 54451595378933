import React, {useContext, useEffect, useState} from 'react';

import {Context as globalContext} from 'shared/lib/context';
import {Context} from './context';
import {Props} from './props';

export const useCheckPvc = (props: Props) => {
    const abortController = new AbortController();
    const {store} = useContext(Context);
    const global = useContext(globalContext);

    const [error, setError] = useState(false);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        store.postCheckPvc.request(abortController, {
            pvc: props.pvc,
            phone: props.phone,
        }).then(r => {
            if (r?.status === 201) {
                if(store.postCheckPvc.get().is_new_user){
                    props.next();
                }
            } else {
                if (!abortController.signal.aborted) {
                    global.store.alert.set(true, 'error', `Неверный pvc`);
                }
                setError(true);
            }
        });
    };

    return {
        error,
        loader: store.postCheckPvc.loader.get(),
        handleSubmit
    };
};