import React from 'react';
import styled from 'styled-components';
import {Button} from '../../Button';

type Props = {
    src?: string;
    title?: string;
    subtitle?: string;
    onClick?: () => void;
    button_title?: string;
    img_width?: string;
}

export const NoData = ({src, title, subtitle, onClick, button_title, img_width}: Props) => {

    return (
        <Root>
            <Image src={src} img_width={img_width}/>

            <Titles>
                <Title>
                    {title}
                </Title>
                <Subtitle>
                    {subtitle}
                </Subtitle>
            </Titles>

            {
                button_title && <Button
                    fullWidth
                    variant="contained"
                    onClick={onClick}
                >
                    {button_title}
                </Button>
            }


        </Root>
    );
};


const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  min-height: 100%;
`;

type ImageProps = {
    img_width?: string;
}

const Image = styled.img`
  max-width: ${({img_width}: ImageProps) => img_width || '100%'};
  width: 100%;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
`;

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  color: var(--black);
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: var(--gray);
`;