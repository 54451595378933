/**
 * Created by Hennadiy on 13.02.2023
 */
import {action, makeAutoObservable} from 'mobx';
import {ServiceStore} from 'gears-react';

import Services from '../api/services';
import * as types from '../api/types';

export class Store {
    constructor() {
        makeAutoObservable(this);
    }

    private list: types.IGetQuizCategoryAllResponse[] = [];

    @action
    public setList(list: types.IGetQuizCategoryAllResponse[]) {
        this.list = list;
    }

    public getList() {
        return this.list;
    }

    //offset
    public offset: number = 0;

    @action
    public setOffset(offset: number) {
        this.offset = offset;
    }

    //search
    public search: string = '';

    @action
    public setSearch(search: string) {
        this.search = search;
    }

    //limit
    public limit: number = 50;

    @action
    public setLimit(limit: number) {
        this.limit = limit;
    }

    //loading
    public loading: boolean = true;

    @action
    public setLoading(loading: boolean) {
        this.loading = loading;
    }

    public readonly getQuizCategoryList = new ServiceStore<[AbortController, types.IGetQuizCategoryAllParams],
        types.IListDataResponse<types.IGetQuizCategoryAllResponse>>(Services.getQuizCategoryList, false);

}