/**
 * Created by Hennadiy on 28.02.2023
 */
import styled from 'styled-components';

export const StyledTab = styled.div`
  padding: 5px;
  height: 32px;
  max-height: 32px;
  min-width: 32px;
  border-radius: 100px;

  font-weight: 400;
  font-size: 16px;

  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition-property: background, color;
  transition-duration: .3s;

  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  justify-content: center;
  align-items: center;
`;