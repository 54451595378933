/**
 * Created by Hennadiy on 16.02.2023
 */

import React from 'react';
import {observer} from 'mobx-react-lite';

import {Header} from 'shared/ui/components/general/Header';
import {SubHeader} from 'shared/ui/components/general/SubHeader';

import {withProviders} from '../providers';

import {SearchButton} from './SearchButton';
import {QuizFilters} from './QuizFilters';
import {QuizCategories} from './QuizCategories';

const QuizHeader = observer(() => {
    return (
        <React.Fragment>
            <Header>
                <SearchButton/>
                <QuizFilters/>
            </Header>
            <SubHeader>
                <QuizCategories/>
            </SubHeader>
        </React.Fragment>
    );
});

const Lazy = () => {
    return <QuizHeader/>;
};

export default withProviders(Lazy);
