/**
 * Created by Hennadiy on 16.02.2023
 */

import {$axios} from './instances';
import {AxiosResponse} from 'axios';

import {urls} from './urls';
import * as types from './types';

export default class Services {
    static async postCreate(
        {
            signal
        }: AbortController,
        {
            gender,
            birthday,
            lastname,
            name,
            main_cat,
            region,
            surname,
            phone,
            passport_number,
            password,
            username,
            passport_series,
            avatar
        }: types.IPostCreateParams,
    ): Promise<AxiosResponse<types.IPostCreateResponse>> {

        const body = new FormData();

        body.append('name', name);

        if (phone !== undefined) {
            body.append('phone', phone);
        }

        if (surname !== undefined) {
            body.append('surname', surname);
        }

        if (region !== undefined) {
            body.append('region', region.toString());
        }

        if (main_cat !== undefined) {
            body.append('main_cat', main_cat.toString());
        }

        if (username !== undefined) {
            body.append('username', username);
        }

        if (password !== undefined) {
            body.append('password', password);
        }

        if (passport_series !== undefined) {
            body.append('passport_series', passport_series);
        }
        if (passport_number !== undefined) {
            body.append('passport_number', passport_number);
        }

        if (gender !== undefined) {
            body.append('gender', gender);
        }

        if (birthday !== undefined) {
            body.append('birthday', birthday);
        }

        if (lastname !== undefined) {
            body.append('lastname', lastname);
        }

        if (avatar !== undefined) {
            body.append('avatar', avatar);
        }


        return await $axios.post<types.IPostCreateResponse>(urls.postCreate(), body, {
            signal: signal,
        });
    }
}