import {useContext, useEffect} from 'react';
import {Context as globalContext} from 'shared/lib/context';

import {Context} from './context';

export const useGetQuizSessionCount = () => {

    const {store} = useContext(Context);

    const global = useContext(globalContext);

    useEffect(() => {
        const abortController = new AbortController();

        store.getQuizSessionList.request(abortController).then(r => {
            if (r?.status !== 200 && !abortController.signal) {
                global.store.alert.set(true, 'error', `${r?.data[0].message}`);
            }
        });

        return () => {
            abortController.abort();
        };

    }, []);

};