/**
 * Created by Hennadiy on 17.02.2023
 */
import {useContext, useEffect} from 'react';
import {Context} from 'shared/api/ums/account';

export const useCheckAccountAccess = () => {
    const account = useContext(Context);

    useEffect(() => {
        account.store.details.request().then(r => {
            if (r.status !== 200) {
                account.store.setIsAuth(false);
            } else {
                account.store.setIsAuth(true);
            }
        });
    }, []);
};

