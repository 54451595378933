import React from 'react';
import {Dialog, Slide} from '@mui/material';
import {TransitionProps} from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
    children?: React.ReactNode;
    open: boolean;
}

export const FullScreenDialog = ({children, open}: Props) => {
    return <Dialog
        className={'MAX_WIDTH_PAGE'}
        fullScreen
        open={open}
        hideBackdrop={true}
        // TransitionComponent={Transition}
    >
        {children}
    </Dialog>;
};