/**
 * Created by Hennadiy on 20.02.2023
 */

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import {Dispatch, SetStateAction} from "react";
import {DialogContent, DialogTitle, IconButton} from "@mui/material";
import styled from "styled-components";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface Props {
    open: boolean;
    children: React.ReactNode;
    setOpen: Dispatch<SetStateAction<boolean>>;
    label?: string;
}


export const DesktopDialog = ({open, setOpen, label, children}: Props) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <MyDialog
                open={open}
                onClose={handleClose}
            >
                <Title>
                    <Row>
                        <Label>{label}</Label>
                        <IconButton onClick={handleClose}>
                            <CloseRoundedIcon color={'secondary'}/>
                        </IconButton>
                    </Row>
                </Title>

                <Content>
                    {children}
                </Content>

            </MyDialog>
        </React.Fragment>
    );
}
const MyDialog = styled(Dialog)`
  .MuiDialog-container {
    .MuiPaper-root {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }

  z-index: 999999999 !important;

`;

const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;
const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--black);
`;
const Title = styled(DialogTitle)`
  padding: 16px !important;
`;
const Content = styled(DialogContent)`
  padding: 0 16px !important;
`;
