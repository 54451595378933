/**
 * Created by Hennadiy on 05.03.2023
 */


import React, {useEffect, useState} from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/uz';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import {observer} from 'mobx-react-lite';

type Props = {
    children: React.ReactNode;
}
export const LocalizationDate = observer(({children}: Props) => {

    const [language, setLanguage] = useState('ru');

    // useEffect(() => {
    //
    // }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
            {children}
        </LocalizationProvider>
    );
});