/**
 * Created by Hennadiy on 05.03.2023
 */
import React, {useEffect, useState} from 'react';


type RecursiveMapProps = {
    children: React.ReactNode[];
    next: boolean;
}

export function StepperRender({children, next}: RecursiveMapProps) {
    const [index, setIndex] = useState(-1);

    useEffect(() => {
        setIndex(index + 1);
    }, [next]);

    return <>
        {children[index]}
    </>;
}