/**
 * Created by Hennadiy on 14.02.2023
 */

import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
// import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';

import {Context} from 'shared/lib/context';

import gallery from '../assets/gallery.svg';

type Props = {
    preview_link?: string;
    image: any,
    setImage: Dispatch<SetStateAction<any>>;
}

export const ImageUpload = observer(({image, setImage, preview_link}: Props) => {
    // const {t} = useTranslation();
    const [preview, setPreview] = useState<any>('');
    const {store} = useContext(Context);

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!image) {
            setPreview('');
            return;
        }
        const objectUrl = URL.createObjectURL(image);
        setPreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [image]);

    const onSelectFile = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setImage(null);
            return;
        }
        // console.log(e.target.files[0].size);
        if (e.target.files[0].size <= 2097152) {
            setImage(e.target.files[0]);
        } else {
            store.alert.set(true, 'error', `Слишком большой размер файла. Макс 2мг.`);
        }

        // console.log(e.target.files);
    };

    useEffect(() => {
        setPreview(preview_link);
    }, [preview_link]);

    return (
        <Root>
            <Dropzone>

                {image === null && <>
                    <Icon src={gallery}/>
                    <Text variant={'body2'} color={'text.secondary'}>Добавьте фото</Text>
                </>}

                <Preview style={{
                    backgroundImage: `url(${preview})`
                }}/>

                <FileInput type={'file'} accept="image/png, image/jpeg" onChange={onSelectFile}/>

            </Dropzone>
        </Root>
    );
});

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  border-radius: 20px;
`;

const Dropzone = styled.div`
  position: relative;
  overflow: hidden;

  width: 100%;
  height: 150px;
  background-color: white;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  border-radius: 20px;
  cursor: pointer;
`;

const Text = styled(Typography)`
  text-align: center;

  user-select: none;
  -webkit-user-select: none;
  pointer-events: none;


  font-weight: 400;
  font-size: 16px !important;

  /* Blue */
  background: linear-gradient(165.92deg, #1DA1F2 3.35%, #003CC5 72.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Preview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  border-radius: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

`;

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 125px;
  opacity: 0;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
`;