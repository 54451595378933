/**
 * Created by Hennadiy on 13.02.2023
 */

import React, {Dispatch, SetStateAction} from 'react';
import styled from 'styled-components';

import {Dialog} from 'shared/ui/components/general';

import filter_img from '../assets/filter.svg';
import filter_active_img from '../assets/filter_active.svg';

type Props = {
    children?: React.ReactNode,
    active: boolean;
    label?: string;
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export const FilterDrawer = ({children, active, label, open, setOpen}: Props) => {
    return (
        <Root>

            <Dialog button={<Icon src={active ? filter_active_img : filter_img} onClick={() => setOpen(true)}/>}
                    open={open} setOpen={setOpen} label={label}>
                {children}
            </Dialog>

        </Root>
    );
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 24px;
  cursor: pointer;
`;