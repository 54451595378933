import axios from 'axios';

import {baseUrl} from './const';



export const $ums_account = axios.create({
    baseURL: baseUrl,
});

$ums_account.interceptors.request.use((config) => {
    if (!config?.headers) {
        throw new Error('Expected \'config\' and \'config.headers\' not to be undefined');
    }
    if (localStorage.getItem('access')) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('access')!}`;
    }
    if (localStorage.getItem('language')) {
        config.headers['Accept-Language'] = localStorage.getItem('language')!.split('"')[1];
    }
    return config;
});


export const $ums_create_account = axios.create({
    baseURL: baseUrl,
});
$ums_account.interceptors.request.use((config) => {
    if (!config?.headers) {
        throw new Error('Expected \'config\' and \'config.headers\' not to be undefined');
    }

    if (localStorage.getItem('language')) {
        config.headers['Accept-Language'] = localStorage.getItem('language')!.split('"')[1];
    }
    return config;
});

export const $ums_another_account = axios.create({
    baseURL: baseUrl,
});

$ums_another_account.interceptors.request.use((config) => {
    if (!config?.headers) {
        throw new Error('Expected \'config\' and \'config.headers\' not to be undefined');
    }
    if (localStorage.getItem('new_user_access')) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('new_user_access')!}`;
    }
    if (localStorage.getItem('language')) {
        config.headers['Accept-Language'] = localStorage.getItem('language')!.split('"')[1];
    }
    return config;
});