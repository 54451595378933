import React from 'react';
import styled from 'styled-components';

type Props = {
    children?: React.ReactNode;
}

export const Header = ({children}: Props) => {
    return <Root className={'MAX_WIDTH_PAGE_FIXED'}>
        {children}
    </Root>;
};

const Root = styled.div`
  width: 100%;
  z-index: 999999;
  top: 0;
  left: 0;
  position: sticky;
  position: -webkit-sticky;
  min-height: var(--header-height);
  background-color: #ffffff;
  //box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  gap: 16px;
  flex-direction: row;
`;
