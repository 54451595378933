/**
 * Created by Hennadiy on 24.02.2023
 */

import React from 'react';

import {PrizeArea, DividerLabel, Terms, PublicQuizDetails} from 'shared/ui/components/quiz';
import styled from 'styled-components';
import {Footer, GetImage, Header} from 'shared/ui/components/general';
import {path} from 'shared/const';
import {ArrowLeft2} from 'iconsax-react';
import {useNavigate} from 'react-router-dom';
import {WhiteButton} from 'shared/ui/components/client';
import {useParams} from 'react-router';
import {PublicQuizDrawer} from 'features/quiz';

type QuizQuestionsParams = {
    quiz_id: string;
};

export const PublicQuiz = () => {
    const navigate = useNavigate();
    const {quiz_id} = useParams<QuizQuestionsParams>();

    return (
        <>
            <Header>
                <Row
                    // onClick={() => navigate(`${path.main}${path.list}/${quiz_id}${path.questions}`)}
                    style={{
                        cursor: 'pointer',
                    }}>
                    <ArrowLeft2
                        size="24"
                        color={`#262626`}
                        style={{
                            flexShrink: 0
                        }}
                    />
                    <p style={{color: '#262626'}}>
                        Gift from NVS IPhone
                    </p>
                </Row>
            </Header>
            <Root>
                <GetImage
                    src={'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'}
                    style={{
                        width: '100%',
                        height: '175px',
                        borderRadius: '8px',
                        flexShrink: 0,
                        margin: '0 auto 24px auto',
                    }}
                />

                <PublicQuizDetails.Creator>
                    "O‘ZBEKISTON TEMIR YO‘LLARI" АJ SSX
                </PublicQuizDetails.Creator>

                <PublicQuizDetails.Title>
                    Gift from NVS iPhone
                </PublicQuizDetails.Title>

                <PublicQuizDetails.Subtitle style={{marginBottom: '32px'}}>
                    This game 1 step of the Grand Prize 2 home flat with 76kv metres, Получи шанс выиграть айфон часы...
                </PublicQuizDetails.Subtitle>

                <PrizeArea style={{marginBottom: '32px'}} text={'Grand Prize: IPHONE 14 MAX '}/>

                <DividerLabel style={{marginBottom: '12px'}} title={'Quiz Terms'}/>

                <Terms titles={[
                    ' Win place: 3',
                    'Answer time: 40 second',
                    'Participant: 2000 maximum',
                    'Game type: free room'
                ]}/>
            </Root>

            <Footer style={{height: 'auto'}}>
                <BottomPos>
                    <PublicQuizDrawer quiz_id={Number(quiz_id)}/>

                    <WhiteButton
                        // onClick={() => navigate(`${path.main}${path.list}/${quiz_id}`)}
                        fullWidth
                        variant={'contained'}
                        color={'primary'}
                        disableElevation>
                        Edit Quiz
                    </WhiteButton>
                </BottomPos>
            </Footer>
        </>

    );
};

const Root = styled.div`
  padding: 16px 16px 176px 16px;

`;

const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--black);
  font-weight: 500;
  font-size: 16px;
`;

const BottomPos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 32px 16px;
`;
