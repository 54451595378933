/**
 * Created by Hennadiy on 13.02.2023
 */

import React, {Dispatch, SetStateAction, useContext, useState} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react-lite';

import {InputDrawer} from 'entities/general';

import {withProviders} from '../providers';
import {Context} from '../lib/context';
import {IQuestionTimeDrawerValue} from '../lib/types';

type Props = {
    value: IQuestionTimeDrawerValue;
    setValue: (value: IQuestionTimeDrawerValue) => void | Dispatch<SetStateAction<IQuestionTimeDrawerValue>>;

    error?: boolean;
    required?: boolean;
}

const QuestionTimeDrawer = observer(({value, setValue, error, required}: Props) => {
    const {store} = useContext(Context);
    const [open, setOpen] = useState<boolean>(false);

    return (
        <InputDrawer open={open} setOpen={setOpen} value={value.value ? `${value.value} секунд` : ''}
                     required={required} error={error}
                     label={'Question time'}>
            {store.getList().map((e) =>
                <StyledTab key={e.id} onClick={() => {
                    setValue(e);
                }} style={value.id === e.id ? {
                    background: 'linear-gradient(143.49deg, #1DA1F2 0%, #003CC5 125.35%)',
                    color: 'white',
                    border: '0px solid white'
                } : {
                    color: 'var(--gray)',
                    background: `white`,
                    border: '1px solid #D5E5FB'
                }}>
                    {e.value} сек.
                </StyledTab>
            )}
        </InputDrawer>
    );
});

const Lazy = ({setValue, value, error, required}: Props) => {
    return <QuestionTimeDrawer error={error} required={required} value={value} setValue={setValue}/>;
};

export default withProviders(Lazy);

const StyledTab = styled.div`
  padding: 13px 16px;
  min-width: 91px;
  height: 42px;
  border-radius: 100px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition-property: background, color;
  transition-duration: .3s;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;