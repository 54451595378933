/**
 * Created by Hennadiy on 13.02.2023
 */

import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';

import {Context as globalContext} from 'shared/lib/context';
import {checkNull, IsNumber} from 'shared/lib/helpers';
import {path} from 'shared/const';

import {IQuizQuestionRequestBodyAddAnswers} from '../api/types';

import {Context} from './context';
import {Props} from './props';

type QuizQuestionsParams = {
    quiz_id: string;
    question_id: string;
};

export const useGetQuizQuestion = (props: Props) => {
    const {store} = useContext(Context);
    const global = useContext(globalContext);
    const navigate = useNavigate();

    const abortController = new AbortController();
    const [loading, setLoading] = useState<boolean>(true);

    const {quiz_id, question_id} = useParams<QuizQuestionsParams>();
    const [notFound, setNotFound] = useState<boolean>(false);

    const [question_index, setQuestionIndex] = useState<number | undefined>(undefined);

    const [previewLink, setPreviewLink] = useState<string | undefined>(undefined);
    const [image, setImage] = useState<any>(null);
    const [question_text, setQuestionText] = useState<string>('');
    const [type, setType] = useState<number>(1);

    const [answers, setAnswers] = useState<IQuizQuestionRequestBodyAddAnswers[]>([]);
    const [answer, setAnswer] = useState<IQuizQuestionRequestBodyAddAnswers[]>([]);

    useEffect(() => {
        reloadQuestionDetails();

        if (!props.findFirstQuestionLoading) {
            if (IsNumber(quiz_id) && IsNumber(question_id)) {
                setLoading(true);

                store.getQuizQuestion.request(abortController, {
                    quiz_id: Number(quiz_id),
                    id: Number(question_id),
                }).then(r => {
                    console.log(r);

                    if (!abortController.signal.aborted) {
                        setLoading(false);
                    }

                    if (r?.status === 200) {
                        setNotFound(false);
                        setQuestionText(store.getQuizQuestion.get().text);
                        setQuestionIndex(store.getQuizQuestion.get().index);
                        setType(store.getQuizQuestion.get().type);
                        setPreviewLink(store.getQuizQuestion.get().image);

                        if (store.getQuizQuestion.get().type === 1) {
                            if (store.getQuizQuestion.get().answers.length > 0) {
                                setAnswers(store.getQuizQuestion.get().answers);
                            }
                        } else {
                            setAnswer(store.getQuizQuestion.get().answers);
                        }

                    } else {
                        if (!abortController.signal.aborted) {
                            setNotFound(true);
                            global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                        }
                    }
                });
            } else {
                setLoading(false);
            }
        }

        return () => {
            abortController.abort();
        };

    }, [quiz_id, question_id, props.findFirstQuestionLoading]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (IsNumber(quiz_id) && IsNumber(question_id)) {
            patch();
        } else {
            post();
        }
    };

    const post = () => {
        if (
            quiz_id !== undefined && quiz_id !== null && !isNaN(Number(quiz_id))
        ) {
            store.postQuizQuestion.request({quiz_id: Number(quiz_id)}, {
                text: question_text,
                type: type,
                image: checkNull(image),
                add_answers: type === 1 ? answers : answer
            }).then(r => {
                console.log(r);
                if (r?.status === 201) {
                    if (props.reloadQuestionList) {
                        props.reloadQuestionList();
                    }
                    reloadQuestionDetails();
                }
            });
        }
    };

    const patch = () => {
        if (
            quiz_id !== undefined && quiz_id !== null &&
            question_id !== undefined && question_id !== null &&
            !isNaN(Number(quiz_id)) && !isNaN(Number(question_id))
        ) {
            store.patchQuizQuestion.request({quiz_id: Number(quiz_id), id: Number(question_id)}, {
                text: question_text,
                type: type,
                image: checkNull(image),
                add_answers: type === 1 ? answers : answer
            }).then(r => {
                console.log(r);
                if (r?.status === 200) {
                    if (question_index !== undefined && props.findNextQuestionIdByIndex !== undefined) {
                        props.findNextQuestionIdByIndex(question_index);
                    }
                }
            });
        }

    };

    const onChange = (e: any, index: number) => {
        e.preventDefault();
        let newArr = [...answers];
        newArr[index].text = e.target.value;
        setAnswers(newArr);
    };

    const onChangeAnswer = (e: any, index: number) => {
        e.preventDefault();
        let newArr = [...answer];
        newArr[index].text = e.target.value;
        setAnswer(newArr);
    };

    const onChangeCorrectAnswer = (e: any, index: number) => {
        e.preventDefault();
        let array = answers.filter(function (v1, i) {
            if (i === index) {
                v1.correct_answer = !v1.correct_answer;
            } else {
                v1.correct_answer = false;
            }
            return v1;
        });

        setAnswers(array);
    };

    const isCorrectAnswerExists = () => {
        return answers.some((answer) => answer.correct_answer);
    };

    const reloadQuestionDetails = () => {
        setNotFound(false);
        setQuestionText('');
        setType(1);
        setImage(null);
        setPreviewLink('');
        setAnswer([
            {
                text: '',
                correct_answer: false,
            },
        ]);

        setAnswers([
            {
                text: '',
                correct_answer: false,
            },
            {
                text: '',
                correct_answer: false,
            },
            {
                text: '',
                correct_answer: false,
            },
            {
                text: '',
                correct_answer: false,
            }
        ]);

    };

    const goToPublicQuiz = () => {
        navigate(path.quiz.getPublic(quiz_id));
    };

    return {
        notFound,
        handleSubmit,
        question_text, setQuestionText,
        image, setImage, previewLink,
        answers, answer,
        type, setType,
        onChange,
        onChangeCorrectAnswer,
        onChangeAnswer,
        isCorrectAnswerExists,
        goToPublicQuiz,
        loading
    };
};