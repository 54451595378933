import {ServiceStore} from 'gears-react';
import {makeAutoObservable} from 'mobx';

import * as types from './types';
import * as services from './services';

class Quiz {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly getQuizAll = new ServiceStore<[types.IGetQuizAllParams],
        types.IListDataResponse<types.IGetQuizResponseAll>>(services.Quiz.getQuizAll, false);
    public readonly create = new ServiceStore<[types.IPostQuizRequestBody], types.IPostQuizResponse>(services.Quiz.postQuiz, false);
    public readonly get = new ServiceStore<[types.ID], types.IGetQuizResponse>(services.Quiz.getQuiz, false);
    public readonly patch = new ServiceStore<[types.ID, types.IPatchQuizRequestBody],
        types.IPatchQuizResponse>(services.Quiz.patchQuiz, false);
    public readonly delete = new ServiceStore<[types.ID],
        types.IDeleteQuizResponse>(services.Quiz.deleteQuiz, false);
    public readonly postQuizNewSession = new ServiceStore<[types.ID, types.IPostQuizNewSessionRequestBody],
        types.IPostQuizNewSessionResponse>(services.Quiz.postQuizNewSession, false);
}

class QuizCategory {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly getQuizCategoryAll = new ServiceStore<[types.IGetQuizCategoryAllParams],
        types.IListDataResponse<types.IGetQuizCategoryAllResponse>>(services.QuizCategory.getQuizCategoryAll, false);
    public readonly getQuizCategory = new ServiceStore<[types.ID],
        types.IGetQuizCategoryAllResponse>(services.QuizCategory.getQuizCategory, false);

}

class QuizQuestion {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly getQuizQuestionAll = new ServiceStore<[types.ID, types.IGetQuizQuestionAllParams],
        types.IListDataResponse<types.IQuizQuestionResponse>>(services.QuizQuestion.getQuizQuestionAll, false);
    public readonly postQuizQuestion = new ServiceStore<[types.ID, types.IQuizQuestionRequestBody],
        types.IQuizQuestionResponse>(services.QuizQuestion.postQuizQuestion, false);
    public readonly getQuizQuestion = new ServiceStore<[types.ID, types.ID],
        types.IQuizQuestionResponse>(services.QuizQuestion.getQuizQuestion, false);
    public readonly patchQuizQuestion = new ServiceStore<[types.ID, types.ID, types.IQuizQuestionRequestBody],
        types.IQuizQuestionResponse>(services.QuizQuestion.patchQuizQuestion, false);
    public readonly deleteQuizQuestion = new ServiceStore<[types.ID, types.ID],
        types.IDeleteQuizResponse>(services.QuizQuestion.deleteQuizQuestion, false);
}

class QuizQuestionAnswer {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly getQuizQuestionAnswerAll = new ServiceStore<[types.ID, types.ID, types.IGetQuizQuestionAnswerAllParams],
        types.IListDataResponse<types.IGetQuizQuestionAnswerAll>>(services.QuizQuestionAnswer.getQuizQuestionAnswerAll, false);
    public readonly postQuizQuestionAnswer = new ServiceStore<[types.ID, types.ID, types.IPostQuizQuestionAnswerRequestBody],
        types.IPostQuizQuestionAnswer>(services.QuizQuestionAnswer.postQuizQuestionAnswer, false);
    public readonly getQuizQuestionAnswer = new ServiceStore<[types.ID, types.ID, types.ID],
        types.IGetQuizQuestionAnswer>(services.QuizQuestionAnswer.getQuizQuestionAnswer, false);
    public readonly patchQuizQuestionAnswer = new ServiceStore<[types.ID, types.ID, types.ID, types.IPatchQuizQuestionAnswerRequestBody],
        types.IPatchQuizQuestionAnswer>(services.QuizQuestionAnswer.patchQuizQuestionAnswer, false);
    public readonly deleteQuizQuestionAnswer = new ServiceStore<[types.ID, types.ID, types.ID],
        types.IDeleteQuizResponse>(services.QuizQuestionAnswer.deleteQuizQuestionAnswer, false);

}

class QuizSession {
    constructor() {
        makeAutoObservable(this);
    }

    private list: types.IGetQuizSession[] = [];

    public setList(list: types.IGetQuizSession[]) {
        this.list = list;
    }

    public getList() {
        return this.list;
    }

    public readonly getQuizSessionAll = new ServiceStore<[AbortController, types.IGetQuizSessionAllParams],
        types.IListDataResponse<types.IGetQuizSession>>(services.QuizSession.getQuizSessionAll, false);
    public readonly getQuizSession = new ServiceStore<[types.ID, types.IGetQuizSessionParams],
        types.IGetQuizSession>(services.QuizSession.getQuizSession, false);
    public readonly patchQuizSessionCalculatePlaces = new ServiceStore<[types.ID, types.IPatchQuizSessionCalculatePlacesRequestBody],
        types.IPatchQuizSessionCalculatePlacesResponse>(services.QuizSession.patchQuizSessionCalculatePlaces, false);
    public readonly patchQuizSessionCloseRegistration = new ServiceStore<[types.ID],
        types.IPatchQuizSessionCalculatePlacesResponse>(services.QuizSession.patchQuizSessionCloseRegistration, false);
    public readonly patchQuizSessionClose = new ServiceStore<[types.ID],
        types.IPatchQuizSessionCalculatePlacesResponse>(services.QuizSession.patchQuizSessionClose, false);
    public readonly postQuizSessionConnect = new ServiceStore<[types.ID, types.IPostQuizSessionConnectRequestBody],
        types.IPostQuizSessionConnectResponse>(services.QuizSession.postQuizSessionConnect, false);
    public readonly getQuizSessionIsCreator = new ServiceStore<[types.ID],
        types.IGetQuizSessionIsCreatorResponse>(services.QuizSession.getQuizSessionIsCreator, false);
    public readonly patchQuizSessionParticipant = new ServiceStore<[types.ID, types.IPatchQuizSessionParticipantRequestBody],
        types.IPostQuizSessionConnectResponse>(services.QuizSession.patchQuizSessionParticipant, false);
    public readonly getQuizSessionReconnect = new ServiceStore<[types.ID, types.IPatchQuizSessionParticipantRequestBody],
        types.IPostQuizSessionConnectResponse>(services.QuizSession.getQuizSessionReconnect, false);
    public readonly getQuizSessionShowMyResult = new ServiceStore<[types.ID, types.IGetQuizSessionParams],
        types.IGetQuizSessionShowMyResultResponse>(services.QuizSession.getQuizSessionShowMyResult, false);
    public readonly getQuizSessionShowQuestionResult = new ServiceStore<[types.ID],
        types.IPatchQuizSessionCalculatePlacesResponse>(services.QuizSession.getQuizSessionShowQuestionResult, false);
    public readonly getQuizSessionShowResult = new ServiceStore<[types.ID, types.IGetQuizSessionAllParams],
        types.IListDataResponse<types.IGetQuizSessionShowMyResultResponse>>(services.QuizSession.getQuizSessionShowResult, false);
    public readonly getQuizSessionShowWinner = new ServiceStore<[types.ID, types.IGetQuizSessionParams],
        types.IGetQuizSessionShowMyResultResponse>(services.QuizSession.getQuizSessionShowWinner, false);
}

class QuizSessionSync {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly postQuizSessionSync = new ServiceStore<[types.ID, types.IPostQuizSessionSyncRequestBody],
        types.IPostQuizSessionSyncResponse>(services.QuizSessionSync.postQuizSessionSync, false);
    public readonly getQuizSessionSyncCheckAnswers = new ServiceStore<[types.ID],
        types.IGetQuizSessionSyncCheckAnswersResponse>(services.QuizSessionSync.getQuizSessionSyncCheckAnswers, false);
    public readonly getQuizSessionSyncCheckTimer = new ServiceStore<[types.ID],
        types.IGetQuizSessionSyncCheckTimerResponse>(services.QuizSessionSync.getQuizSessionSyncCheckTimer, false);
    public readonly getQuizSessionSyncIsAnswered = new ServiceStore<[types.ID],
        types.IGetQuizSessionSyncCheckAnswersResponse>(services.QuizSessionSync.getQuizSessionSyncIsAnswered, false);
    public readonly patchQuizSessionSyncNextQuestion = new ServiceStore<[types.ID],
        types.IPatchQuizSessionSyncNextQuestionResponse>(services.QuizSessionSync.patchQuizSessionSyncNextQuestion, false);
    public readonly getQuizSessionSyncShowQuestion = new ServiceStore<[types.ID, types.IGetQuizSessionSyncShowQuestionParams],
        types.IPatchQuizSessionSyncNextQuestionResponse>(services.QuizSessionSync.getQuizSessionSyncShowQuestion, false);
    public readonly patchQuizSessionSyncStartTimer = new ServiceStore<[types.ID],
        types.IPatchQuizSessionSyncStartTimerResponse>(services.QuizSessionSync.patchQuizSessionStartTimer, false);
}

class QuizSessionAsync {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly postQuizSessionAsync = new ServiceStore<[types.ID, types.IPostQuizSessionSyncRequestBody],
        types.IPostQuizSessionSyncResponse>(services.QuizSessionAsync.postQuizSessionAsync, false);
    public readonly getQuizSessionAsyncCheckTimer = new ServiceStore<[types.ID],
        types.IGetQuizSessionSyncCheckTimerResponse>(services.QuizSessionAsync.getQuizSessionAsyncCheckTimer, false);
    public readonly getQuizSessionAsyncIsAnswered = new ServiceStore<[types.ID],
        types.IGetQuizSessionSyncCheckAnswersResponse>(services.QuizSessionAsync.getQuizSessionAsyncIsAnswered, false);
    public readonly patchQuizSessionAsyncNextQuestion = new ServiceStore<[types.ID],
        types.IPatchSessionAsyncNextQuestionResponse>(services.QuizSessionAsync.patchQuizSessionAsyncNextQuestion, false);
    public readonly getQuizSessionAsyncShowQuestion = new ServiceStore<[types.ID, types.IGetQuizSessionSyncShowQuestionParams],
        types.IGetSessionAsyncShowQuestionResponse>(services.QuizSessionAsync.getQuizSessionAsyncShowQuestion, false);
    public readonly patchQuizSessionAsyncStartTimer = new ServiceStore<[types.ID],
        types.IPatchQuizSessionSyncStartTimerResponse>(services.QuizSessionAsync.patchQuizSessionAsyncStartTimer, false);
}

class QuizImage {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly getQuizImageAll = new ServiceStore<[types.ID, types.IGetQuizQuestionAnswerAllParams],
        types.IListDataResponse<types.IGetQuizImage>>(services.QuizImage.getQuizImageAll, false);
    public readonly postQuizImage = new ServiceStore<[types.ID, types.IPostQuizImageRequestBody],
        types.IGetQuizImage>(services.QuizImage.postQuizImage, false);
    public readonly getQuizImage = new ServiceStore<[types.ID, types.ID], types.IGetQuizImage>(services.QuizImage.getImage, false);
    public readonly patchQuizImage = new ServiceStore<[types.ID, types.ID, types.IPostQuizImageRequestBody],
        types.IGetQuizImage>(services.QuizImage.patchImage, false);
    public readonly deleteQuizImage = new ServiceStore<[types.ID, types.ID],
        types.IDeleteQuizResponse>(services.QuizImage.deleteImage, false);
}

class Session {
    constructor() {
        makeAutoObservable(this);
    }

    //Session
    public readonly getSessionCreator = new ServiceStore<[], types.IGetSessionCreatorsResponse>(services.Session.getSession, false);
}

class QuizStat {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly getQuizStatAll = new ServiceStore<[types.IGetStatAllParams],
        types.IListDataResponse<types.IGetStatAllResponseResult>>(services.QuizStat.getStatAll, false);
    public readonly getQuizStat = new ServiceStore<[types.ID, types.IGetQuizStatParams],
        types.IGetStatAllResponseResult>(services.QuizStat.getStat, false);
    public readonly getQuizMyStat = new ServiceStore<[],
        types.IGetQuizStatMyResponse>(services.QuizStat.getMyStat, false);
}

export class Store {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly quiz = new Quiz();
    public readonly quizQuestion = new QuizQuestion();
    public readonly quizCategory = new QuizCategory();
    public readonly quizQuestionAnswer = new QuizQuestionAnswer();
    public readonly quizSession = new QuizSession();
    public readonly quizSessionSync = new QuizSessionSync();
    public readonly quizSessionAsync = new QuizSessionAsync();
    public readonly quizImage = new QuizImage();
    public readonly session = new Session();
    public readonly quizStat = new QuizStat();

}