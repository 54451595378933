/**
 * Created by Hennadiy on 10.03.2023
 */

import React, {CSSProperties} from 'react';
import styled from 'styled-components';
import {Avatar} from '@mui/material';

type Props = {
    src?: string;
    title?: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    style?: CSSProperties;
}

export const UserShortDetails = ({src, title, subtitle, style}: Props) => {
    return (
        <Root style={style}>
            <Avatar variant={'circular'} src={src}/>
            <Column>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </Column>
        </Root>
    );
};

const Root = styled.div`
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 80%;
`;

const Column = styled.div`
  min-width: 0;
  //flex-basis: 50%;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: var(--black);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
`;

const Subtitle = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: var(--black);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;