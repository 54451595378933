import styled from '@emotion/styled';
import {LoadingButton} from '@mui/lab';

export const WhiteButton = styled(LoadingButton)`
  padding: 12px 30px;
  font-weight: 500;
  font-size: 16px;

  height: 48px;
  border-radius: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-transform: initial;
  transition: all .2s;
  position: relative;


  background: linear-gradient(165.92deg, #1DA1F2 3.35%, #003CC5 72.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: -1;
  }

  opacity: 1;

  border: 1px solid #003CC5;  
  
  span {
    color: rgba(255, 255, 255, 0);

    * {
      color: var(--gray);
    }
  }

  :hover {
    background-color: rgba(255, 255, 255, 0);
  }


  &.MuiLoadingButton-loading {
    :disabled {
      opacity: 1;
      background: #FFFFFF;
      color: transparent;
      border: 1px var(--gray) solid;
    }
  }

  :disabled {
    opacity: 1;

    background: var(--gray);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    
    color: var(--gray);
    border: 1px var(--gray) solid;
  }
`;