
import {observer} from 'mobx-react-lite';
import React from 'react';

import {Actions} from './Actions';
import {BaseCard} from './BaseCard';
import {Content} from './Content';
import {CardProps} from './types';


export const Card = observer(({isLoading, backgroundColor, contentProps, actionsProps, onClick}: CardProps) => {
    return <BaseCard
        onClick={onClick}
        isLoading={isLoading}
        backgroundColor={backgroundColor}
        content={<Content {...contentProps}/>}
        actions={<Actions {...actionsProps}/>}
    />;
});



