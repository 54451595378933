/**
 * Created by Hennadiy on 13.02.2023
 */

import React from 'react';
import styled from 'styled-components';
import {Splide} from '@splidejs/react-splide';

type Props = {
    children: React.ReactNode;
    fullWidth?: boolean;
}
export const HorizontalScroll = ({children, fullWidth}: Props) => {
    return (<Root>
            {fullWidth ? <MySplideMinWidth

                    options={{
                        autoWidth: true,
                        pagination: false,
                        arrows: false,
                        perMove: 5,
                        gap: 8,
                        width: '100%',
                    }}
                >
                    {children}
                </MySplideMinWidth>
                :
                <MySplide
                    options={{
                        autoWidth: true,
                        pagination: false,
                        arrows: false,
                        perMove: 5,
                        gap: 8,
                        width: '100%',

                    }}

                >
                    {children}
                </MySplide>
            }
        </Root>
    );
};

const Root = styled.div`
  width: 100%;
  overflow-x: hidden;
  padding: 0 16px;
`;

const MySplide = styled(Splide)`
  .splide__list {
    display: flex;

    li {
      list-style-type: none;
    }
  }

  .splide__track {
    .splide__sr {
      display: none;
    }
  }
`;

const MySplideMinWidth = styled(Splide)`
  .splide__list {
    display: flex;

    li {
      list-style-type: none;
      //min-width: 343px;
      min-width: 100%;
    }
  }

  .splide__track {
    .splide__sr {
      display: none;
    }
  }
`;