import axios from 'axios';

import {baseUrl} from './const';



export const $gms_public = axios.create({
    baseURL: baseUrl,
});

$gms_public.interceptors.request.use((config) => {
    if (!config?.headers) {
        throw new Error('Expected \'config\' and \'config.headers\' not to be undefined');
    }
    if (localStorage.getItem('access')) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('access')!}`;
    }
    if (localStorage.getItem('language')) {
        config.headers['Accept-Language'] = localStorage.getItem('language')!.split('"')[1];
    }
    return config;
});