import React from 'react';

import {CheckAccountAccess} from 'processes';

import {withProviders} from './providers';
import {protectedRoutes, authRoutes} from './routes';

import './styles/index.css';
import './styles/vars.css';
import 'shared/assets/fonts/SFPro/stylesheet.css';

const App = () => {
    return <CheckAccountAccess authRoutes={authRoutes} protectedRoutes={protectedRoutes}/>;
};

export default withProviders(App);