/**
 * Created by Hennadiy on 17.02.2023
 */

import React from 'react';

import {TranslationButtonV2} from 'features/general';
import {Auth3PU} from 'features/auth';

import {LoginLayout} from 'entities/auth';

export const Login = () => {
    return <LoginLayout leftTop={<TranslationButtonV2/>}>
        <Auth3PU/>
    </LoginLayout>;
};

