/**
 * Created by Hennadiy on 13.02.2023
 */

import React, {Dispatch, SetStateAction, useContext} from 'react';
import styled from 'styled-components';
import {Skeleton} from '@mui/material';
import {observer} from 'mobx-react-lite';

import {InfiniteScroll} from 'shared/ui/components/general';

import {withProviders} from '../providers';
import {Context} from '../lib/context';
import {useList} from '../lib/hooks';
import {ICategoryDrawerValue} from '../lib/types';
import {InputDrawer} from '../../../../../entities/general';


type Props = {
    value: ICategoryDrawerValue;
    setValue: (value: ICategoryDrawerValue) => void | Dispatch<SetStateAction<ICategoryDrawerValue>>;

    error?: boolean;
    required?: boolean;
}

const CategoryDrawer = observer(({setValue, value, error, required}: Props) => {
    const {store} = useContext(Context);
    const {open, setOpen} = useList();
    const loaders = [1, 2, 3];

    return (
        <InputDrawer error={error} setOpen={setOpen} open={open} value={value.value} required={required}
                     label={'Quiz Categories'}>
            <InfiniteScroll offset={store.offset} nextOffset={store.getQuizCategoryList.get().next_offset}
                            setOffset={(offset) => {
                                store.setOffset(offset);
                            }}
                            isLoading={store.getQuizCategoryList.loader.get()}
                            totalCount={store.getQuizCategoryList.get().count}>

                {
                    store.loading ? <>
                        {loaders.map((e, i) =>
                            <Skeleton key={i} sx={{borderRadius: '100px', transform: 'none'}} width={'100%'}
                                      height={42}/>
                        )}
                    </> : <>
                        {store.getList().map((e) =>
                            <StyledTab key={e.id} onClick={() => {
                                setValue({
                                    value: e.name,
                                    id: e.id
                                });
                            }} style={value.id === e.id ? {
                                background: 'linear-gradient(143.49deg, #1DA1F2 0%, #003CC5 125.35%)',
                                color: 'white',
                                border: '0px solid white'
                            } : {
                                color: 'var(--gray)',
                                background: `white`,
                                border: '1px solid #D5E5FB'
                            }}>
                                {e.name}
                            </StyledTab>
                        )}

                        {store.getQuizCategoryList.loader.get() && loaders.map((e, i) =>
                            <Skeleton key={i} sx={{borderRadius: '100px', transform: 'none'}} width={'100%'}
                                      height={42}/>
                        )}
                    </>
                }

            </InfiniteScroll>
        </InputDrawer>
    );
});

const Lazy = ({setValue, value, error, required}: Props) => {
    return <CategoryDrawer error={error} required={required} value={value}
                           setValue={setValue}/>;
};

export default withProviders(Lazy);

const StyledTab = styled.div`
  padding: 13px 16px;
  min-width: 91px;
  height: 42px;
  border-radius: 100px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition-property: background, color;
  transition-duration: .3s;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;