/**
 * Created by Hennadiy on 13.02.2023
 */

import {action, makeAutoObservable} from 'mobx';
import {ServiceStore} from 'gears-react';

import Services from '../api/services';
import * as types from '../api/types';

export class Store {
    constructor() {
        makeAutoObservable(this);
    }

    private list: types.IQuizQuestionResponse[] = [];

    @action
    public setList(list: types.IQuizQuestionResponse[]) {
        this.list = list;
    }

    public getList() {
        return this.list;
    }

    //offset
    public offset: number = 0;

    @action
    public setOffset(offset: number) {
        this.offset = offset;
    }

    //limit
    public limit: number = 50;

    public readonly getQuizQuestionList = new ServiceStore<[AbortController, types.IGetQuizQuestionAllParams],
        types.IListDataResponse<types.IQuizQuestionResponse>>(Services.getQuizQuestionList, true);
}