import {GearStore} from 'gears-react';
import {makeObservable} from 'mobx';

import {translations} from '../translations';

export default class Store extends GearStore {
    constructor() {
        super();
        makeObservable(this);
        this.lang.init(translations, 'ru');
    }

}