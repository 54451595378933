/**
 * Created by Hennadiy on 15.02.2023
 */

import {$axios} from './instances';
import {AxiosResponse} from 'axios';

import {urls} from './urls';
import * as types from './types';

export default class Services {
    static async getQuizList(
        {
            signal
        }: AbortController,
        {
            category,
            limit,
            offset,
            ordering,
            question_time_max,
            question_time_min,
            search
        }: types.IGetQuizAllParams): Promise<AxiosResponse<types.IListDataResponse<types.IGetQuizResponseAll>>> {

        return await $axios.get<types.IListDataResponse<types.IGetQuizResponseAll>>(urls.getQuizList(), {
            signal: signal,
            params: {
                category,
                limit,
                offset,
                ordering,
                question_time_max,
                question_time_min,
                search
            }
        });
    }
}