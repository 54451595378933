/**
 * Created by Hennadiy on 04.03.2023
 */

import React from 'react';
import {observer} from 'mobx-react-lite';

import {Props} from '../lib/props';

import {Form} from './Form';
import {useCreateUser} from '../lib/hooks';

import {LogoWithTitle} from 'shared/ui/components/client';

export const CreateUser = observer((props: Props) => {
    const {setName, name, error, handleSubmit, loader} = useCreateUser(props);

    return (<React.Fragment>
            <LogoWithTitle
                logoTitle={'Create account'}
                subLogoTitle={'Introduce yourself'}
                subtitle={'Tell about you all It take some times.'}
            />
            <Form
                handleSubmit={handleSubmit}
                error={error}
                loader={loader}
                name={name}
                setName={setName}
            />
        </React.Fragment>
    );
});