import React from 'react';
import {observer} from 'mobx-react-lite';

import {QuestionNumberList} from 'features/quiz';

import {ChangeQuestionForm, ChangeQuestionLayout} from 'entities/quiz';

import {useFindFirstQuestion} from '../lib/hooks';
import {Props} from '../lib/props';

export const ChangeQuestion = observer((props: Props) => {

    const {
        notFound,
        findNextQuestionIdByIndex,
        setFindNextQuestionIdByIndex,
        findFirstQuestionLoading
    } = useFindFirstQuestion(props);


    return (
        <ChangeQuestionLayout
            subheader={!notFound &&
                <QuestionNumberList
                    findFirstQuestionLoading={findFirstQuestionLoading}
                    setFindNextQuestionIdByIndex={setFindNextQuestionIdByIndex}
                />
            }
        >

            {notFound ? <>404 Not Found</> :
                <ChangeQuestionForm
                    findFirstQuestionLoading={findFirstQuestionLoading}
                    findNextQuestionIdByIndex={findNextQuestionIdByIndex?.request}
                    reloadQuestionList={findNextQuestionIdByIndex?.reload}
                />
            }

        </ChangeQuestionLayout>
    );
});