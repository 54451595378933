
import React from 'react';

import {Context} from '../lib/context';
import {Store} from '../lib/store';

const store = new Store();

export const withStore = (component: () => React.ReactNode) => () => (
    <Context.Provider value={{store}}>
        {component()}
    </Context.Provider>
);