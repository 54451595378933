import {ILang} from 'gears-react';

import en from './en.json';
import ru from './ru.json';
import uz from './uz.json';

export const translations: ILang[] = [
    {value: 'uz', label: 'O\'zbek', JSON: uz},
    {value: 'ru', label: 'Русский', JSON: ru},
    {value: 'en', label: 'English', JSON: en},
];