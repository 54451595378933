import React from 'react';
import {Skeleton} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {GetImage} from 'shared/ui/components/general';
import {TimeUsers} from 'shared/ui/components/quiz/TimeUsers';
import styled from 'styled-components';

import {ContentProps} from './types';

export const Content = observer(({src, title, creator, question_number, category, users, time, type}: ContentProps) => {
    return <Root>
        <Row>
            <GetImage
                src={src}
                style={{width: '72px', height: '72px', borderRadius: '8px', flexShrink: 0}}
            />
            <FullWidth style={{flexShrink: 1}}>
                <Title>
                    {title || <Skeleton sx={{transform: 'none'}} variant={'rounded'}/>}
                </Title>
                <Creator>
                    {creator || <Skeleton sx={{transform: 'none'}} variant={'rounded'}/>}
                </Creator>
                <Subtitles>
                    {category !== undefined && <>
                        <Subtitle style={{flexShrink: 2}}>
                            {category}
                        </Subtitle>
                    </>}

                    {question_number !== undefined && <>
                        <Circle/>
                        <Subtitle style={{flexShrink: 1}}>
                            {question_number} questions
                        </Subtitle>
                    </>}

                    {type !== undefined && <>
                        <Circle/>
                        <Subtitle style={{flexShrink: 1}}>
                            {type === 1 ? 'Синхронный' : type === 2 ? 'Асинхронный' : '...'}
                        </Subtitle>
                    </>}
                </Subtitles>
                <TimeUsers time={time} users={users}/>
            </FullWidth>
        </Row>
    </Root>;
});

const Root = styled.div`
  width: 100%;
  overflow: hidden;
`;

const FullWidth = styled.div`
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: var(--black);
  margin-bottom: 4px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
const Creator = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: var(--gray);
  margin-bottom: 4px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
const Subtitles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  width: 100%;
  margin-bottom: 10px;
`;
const Subtitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: var(--gray);

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Circle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--gray);
  flex-shrink: 0;
`;