/**
 * Created by Hennadiy on 13.02.2023
 */
import {makeAutoObservable} from 'mobx';
import * as types from './types';

export class Store {
    constructor() {
        makeAutoObservable(this);
    }

    public getList() {
        let list: types.IQuestionTimeDrawerValue[] = [];
        let value = 0;
        for (let i = 0; i < 60; i++) {
            value += 5;
            list.push({id: i + 1, value: value});
        }
        return list;
    }

}