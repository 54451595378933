/**
 * Created by Hennadiy on 10.03.2023
 */


import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';

import {UserShortDetails, Dialog, PostImage} from 'shared/ui';

import styled from 'styled-components';
import video from '../assets/video.mp4';
import more from '../assets/more.svg';

import {PostVideoPlayer} from 'shared/ui';
import {Splide} from '@splidejs/react-splide';
import {SplideSlide} from '@splidejs/react-splide';
import {Message, Heart, Send2} from 'iconsax-react';
import BottomNavigation from '@mui/material/BottomNavigation';
import {Badge} from '@mui/material';
import {path} from '../../../../../shared/const';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';


type Props = {}

export const PostCard = ({}: Props) => {
    const [muted, setMuted] = useState<boolean>(true);
    const [fullText, setFullText] = useState<boolean>(false);
    const [like, setLike] = useState<boolean>(false);

    const like2 = useRef<HTMLDivElement>(null);

    return (
        <Root>
            <Header>
                <UserShortDetails
                    src={'https://s2.stc.all.kpcdn.net/afisha/msk/wp-content/uploads/sites/5/2023/01/kadr-iz-filma-avatar-put-vody.jpg'}
                    title={'Gennadiy Kharatyan С Очень длинным именем , короче очень длинное'}
                    subtitle={'Frontend Developer, Backend Developer, Еще какой-то Developer'}
                />
                <More src={more} draggable={false}/>
            </Header>
            <Relative onClick={(e) => {
                e.stopPropagation();
                if (e.detail === 2) {
                    setLike(true);
                    if (like2.current) {
                        like2.current.style.animationName = 'none';

                        requestAnimationFrame(() => {
                            setTimeout(() => {
                                if (like2.current) {
                                    like2.current.style.animationName = 'Like2';
                                }
                            }, 0);
                        });
                    }
                }
            }}>
                <Horizontal>
                    <MySplide
                        options={{
                            autoWidth: true,
                            pagination: false,
                            arrows: false,
                            perMove: 5,
                            width: '100%',
                            // perPage: 1,
                        }}
                    >
                        <SplideSlide>
                            <PostVideoPlayer setMuted={setMuted} muted={muted} url={video}/>
                        </SplideSlide>
                        <SplideSlide>
                            <PostImage
                                src={'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Mona_Lisa%2C_by_Leonardo_da_Vinci%2C_from_C2RMF_retouched.jpg/250px-Mona_Lisa%2C_by_Leonardo_da_Vinci%2C_from_C2RMF_retouched.jpg'}
                            />
                        </SplideSlide>
                        <SplideSlide>
                            <PostVideoPlayer setMuted={setMuted} muted={muted} url={video}/>
                        </SplideSlide>
                    </MySplide>
                </Horizontal>

                <Count>
                    1/4
                </Count>

                <Like2 ref={like2}>
                    <Heart
                        size="128"
                        color={'#fff'}
                        variant={'Bold'}
                    />
                </Like2>

            </Relative>
            <Body onClick={(e) => {
                e.stopPropagation();
                setFullText(true);
            }} fullText={fullText}>
                asdddddddddddddddddddddddddddddddddddd
                asdddddddddddddddddddddddddddddddddddd
                asdddddddddddddddddddddddddddddddddddd
                asdddddddddddddddddddddddddddddddddddd
                asdddddddddddddddddddddddddddddddddddd
                asdddddddddddddddddddddddddddddddddddd
                asdddddddddddddddddddddddddddddddddddd
                asdddddddddddddddddddddddddddddddddddd
                asdddddddddddddddddddddddddddddddddddd
                asdddddddddddddddddddddddddddddddddddd
            </Body>
            <Statistic>
                <span>
                    22.5K Likes
                </span>
                <span>
                    1K Comments
                </span>
            </Statistic>
            <Bar>
                <BottomNavigation showLabels>
                    <StyledBottomNavigationAction onClick={(e) => {
                        e.stopPropagation();
                        setLike(!like);
                    }} value={'/'} icon={
                        <Badge badgeContent={0} color="error">
                            <Heart
                                size="24"
                                color={like ? 'var(--error)' : 'var(--black)'}
                                variant={like ? 'Bold' : 'Outline'}
                            />
                        </Badge>
                    }/>

                    <StyledBottomNavigationAction value={path.client.services} icon={
                        <Badge badgeContent={0} color="error">
                            <Message
                                size="24"
                                color={'#262626'}
                                variant={'Outline'}
                            />
                        </Badge>
                    }/>

                    <StyledBottomNavigationAction value={path.client.services} icon={
                        <Badge badgeContent={0} color="error">
                            <Send2
                                size="24"
                                color={'#262626'}
                                variant={'Outline'}
                            />
                        </Badge>
                    }/>
                </BottomNavigation>
            </Bar>
        </Root>
    );
};

const More = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  flex-shrink: 0;
  cursor: pointer;
`;

const Root = styled.div`
  width: 100%;
  background-color: var(--white);
  margin-bottom: 8px;
  border-top: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;

`;

const Header = styled.div`
  padding: 12px 16px 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

type BodyProps = {
    fullText: boolean;
}

const Body = styled.div`
  width: 100%;
  margin: 12px auto;
  padding: 0 16px;
  max-height: ${({fullText}: BodyProps) => fullText ? 'none' : '42px'};
  font-weight: 400;
  font-size: 12px;
  color: var(--black);

  word-break: break-word;
  display: ${({fullText}: BodyProps) => fullText ? 'block' : ' -webkit-box'};
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: ${({fullText}: BodyProps) => fullText ? 'auto' : 'hidden'};
  text-overflow: ellipsis;
`;

const Horizontal = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: var(--black);
`;

const MySplide = styled(Splide)`
  .splide__list {
    display: flex;

    li {
      list-style-type: none;
      //min-width: 343px;
      min-width: 100%;
    }
  }

  .splide__track {
    .splide__sr {
      display: none;
    }
  }

  min-width: 100%;
`;

const Count = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  color: var(--white);
  font-weight: 400;
  font-size: 11px;
  height: 24px;
  width: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Relative = styled.div`
  position: relative;
  width: 100%;
`;

const Bar = styled.div`
  position: relative;
  width: 100%;
  border-top: 1px solid #F2F2F2;
`;

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  min-width: auto;

  .MuiTouchRipple-root {
    * {
      color: rgba(38, 38, 38, 0.24);
    }
  }
`;

const Like2 = styled.div`
  position: absolute;
  pointer-events: none;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%) scale(0);
  opacity: 0;

  animation-name: none;
  animation-duration: 1000ms;
  animation-timing-function: ease-out;

  @keyframes Like2 {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }
    20% {
      transform: translate(-50%, -50%) scale(0.9);
    }
    40% {
      transform: translate(-50%, -50%) scale(0.8);
    }

    90% {
      transform: translate(-50%, -50%) scale(0.8);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(0.1);
      opacity: 0;
    }
  }
`;

const Statistic = styled.div`
  width: 100%;
  padding: 0 16px 16px 16px;
  font-weight: 400;
  font-size: 10px;
  color: #7F92A0;
  display: flex;
  align-items: center;
  gap: 8px;
`;