import {getRoutes} from 'gears-react';

import {questions} from 'pages/quiz';

import {path} from 'shared/const';

export const question_routers = getRoutes([
    ['*', [<div>error</div>]],
    ['/', questions],
    [`/:${path.quiz.question_id}`, questions],
]);
