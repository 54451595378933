/**
 * Created by Hennadiy on 15.02.2023
 */

import {$axios} from './instances';
import {AxiosResponse} from 'axios';

import {urls} from './urls';
import * as types from './types';

export default class Services {
    static async getQuizQuestionList(
        {
            signal
        }: AbortController,
        {
            quiz_id,
            offset,
            ordering,
            search,
            type,
            limit,
        }: types.IGetQuizQuestionAllParams): Promise<AxiosResponse<types.IListDataResponse<types.IQuizQuestionResponse>>> {
        return await $axios.get<types.IListDataResponse<types.IQuizQuestionResponse>>(urls.getQuizQuestionList(quiz_id), {
            signal: signal,
            params: {
                offset,
                ordering,
                search,
                type,
                limit,
            }
        });
    }
}