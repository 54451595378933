
import {AxiosResponse} from 'axios';

import {$gms_public} from './instances';
import {
    ID,
    IGetPublicRegionListParams,
    IGetPublicRegionPathParams,
    IGetPublicRegionPathResponse,
    IGetPublicRegionResponse,
    IGetPublicRegionTypeResponse,
    IListResponse,
    IPublicRegionListItemResponse,
} from './types';
import {publicRegion,publicRegionPath, publicRegionType} from './urls';


export class PublicRegionService {
    static async list({
        list,
        ordering,
        type,
        parent
    }: IGetPublicRegionListParams): Promise<AxiosResponse<IListResponse<IPublicRegionListItemResponse>>> {
        return await $gms_public.get<IListResponse<IPublicRegionListItemResponse>>(publicRegion.list(), {
            params: {
                limit: list?.limit,
                offset: list?.offset,
                search: list?.search,
                ordering,
                type,
                parent
            }
        });
    }

    static async get({id}: ID): Promise<AxiosResponse<IGetPublicRegionResponse>> {
        return await $gms_public.get<IGetPublicRegionResponse>(publicRegion.get(id));
    }
}

export class PublicRegionPathService {
    static async get({
        search,
        ordering,
        id
    }: IGetPublicRegionPathParams): Promise<AxiosResponse<IGetPublicRegionPathResponse[]>> {
        return await $gms_public.get<IGetPublicRegionPathResponse[]>(publicRegionPath.get(id), {
            params: {
                search,
                ordering
            }
        });
    }
}

export class PublicRegionTypeService {
    static async list(): Promise<AxiosResponse<IListResponse<IGetPublicRegionTypeResponse>>> {
        return await $gms_public.get<IListResponse<IGetPublicRegionTypeResponse>>(publicRegionType.list());
    }

    static async get({id}: ID): Promise<AxiosResponse<IGetPublicRegionTypeResponse>> {
        return await $gms_public.get<IGetPublicRegionTypeResponse>(publicRegionType.get(id));
    }
}

