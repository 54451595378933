/**
 * Created by Hennadiy on 27.02.2023
 */

import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {Routing} from 'gears-react';

import {PageLoaderWithLogo} from 'shared/ui/components/general';

import {Context} from '../lib/context';
import {useGetQuizSessionRole} from '../lib/hooks';
import {Props} from '../lib/props';

export const CheckQuizSessionRole = observer((props: Props) => {

    const {store} = useContext(Context);

    const {notFound} = useGetQuizSessionRole();

    if (store.getIsQuizSessionCreator.loader.get()) return <PageLoaderWithLogo/>;

    if (notFound) return <>404 not found</>;

    return <Routing fallback={<PageLoaderWithLogo/>}
                    routes={store.getIsQuizSessionCreator.get().is_creator ? props.creator_routes : props.client_routes}/>;
});