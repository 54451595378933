/**
 * Created by Hennadiy on 10.03.2023
 */
import React, {useEffect, useState} from 'react';
import {TPages} from 'gears-react';

import {PostCard} from 'entities/post';

const Feed = () => {

    return (
        <div>
            <PostCard/>
            <PostCard/>
            <PostCard/>
            <PostCard/>
            <PostCard/>
        </div>
    );
};

export default Feed;

export const feed: TPages = [
    <Feed/>
];
