import {AxiosResponse} from 'axios';

import {$qms_account} from './instances';
import {
    ID,
    IDeleteQuizResponse,
    IGetQuizAllParams,
    IGetQuizCategoryAllParams,
    IGetQuizCategoryAllResponse,
    IGetQuizImage,
    IGetQuizQuestionAllParams,
    IGetQuizQuestionAnswer,
    IGetQuizQuestionAnswerAll,
    IGetQuizQuestionAnswerAllParams,
    IGetQuizResponse,
    IGetQuizResponseAll,
    IGetQuizSession,
    IGetQuizSessionAllParams,
    IGetQuizSessionIsCreatorResponse,
    IGetQuizSessionParams,
    IGetQuizSessionShowMyResultResponse,
    IGetQuizSessionSyncCheckAnswersResponse,
    IGetQuizSessionSyncCheckTimerResponse,
    IGetQuizSessionSyncShowQuestionParams,
    IGetQuizStatMyResponse,
    IGetQuizStatParams,
    IGetSessionAsyncShowQuestionResponse,
    IGetSessionCreatorsResponse,
    IGetStatAllParams,
    IGetStatAllResponseResult,
    IListDataResponse,
    IPatchQuizQuestionAnswer,
    IPatchQuizQuestionAnswerRequestBody,
    IPatchQuizRequestBody,
    IPatchQuizResponse,
    IPatchQuizSessionCalculatePlacesRequestBody,
    IPatchQuizSessionCalculatePlacesResponse,
    IPatchQuizSessionParticipantRequestBody,
    IPatchQuizSessionSyncNextQuestionResponse,
    IPatchQuizSessionSyncStartTimerResponse,
    IPatchSessionAsyncNextQuestionResponse,
    IPostQuizImageRequestBody,
    IPostQuizNewSessionRequestBody,
    IPostQuizNewSessionResponse,
    IPostQuizQuestionAnswer,
    IPostQuizQuestionAnswerRequestBody,
    IPostQuizRequestBody,
    IPostQuizResponse,
    IPostQuizSessionConnectRequestBody,
    IPostQuizSessionConnectResponse,
    IPostQuizSessionSyncRequestBody,
    IPostQuizSessionSyncResponse,
    IQuizQuestionRequestBody,
    IQuizQuestionRequestBodyAddAnswers,
    IQuizQuestionResponse,
} from './types';
import {
    quiz, quiz_categori,
    quiz_image,
    quiz_question, quiz_question_answer,
    quiz_session, quiz_session_async,
    quiz_session_sync, quiz_stat, session
} from './urls';


export class Quiz {

    static async getQuizAll(
        {
            category,
            limit,
            offset,
            ordering,
            question_time_max,
            question_time_min,
            search
        }: IGetQuizAllParams): Promise<AxiosResponse<IListDataResponse<IGetQuizResponseAll>>> {


        return await $qms_account.get<IListDataResponse<IGetQuizResponseAll>>(quiz.get_all(), {
            params: {
                category,
                limit,
                offset,
                ordering,
                question_time_max,
                question_time_min,
                search
            }
        });
    }

    static async postQuiz({
        add_images,
        name,
        description,
        question_time,
        reward,
        category
    }: IPostQuizRequestBody): Promise<AxiosResponse<IPostQuizResponse>> {

        let body = new FormData();
        body.append('name', name);

        if (description !== undefined) {
            body.append('description', description);
        }

        if (question_time !== undefined) {
            body.append('question_time', question_time.toString());
        }
        if (reward !== undefined) {
            body.append('reward', reward.toString());
        }
        if (category !== undefined) {
            body.append('category', category.toString());
        }

        if (add_images !== undefined) {
            for (let i = 0; i < add_images.length; i++) {
                body.append('add_images', add_images[i]);
            }
        }

        return await $qms_account.post<IPostQuizResponse>(quiz.post(), body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static async getQuiz({id}: ID): Promise<AxiosResponse<IGetQuizResponse>> {
        return await $qms_account.post<IGetQuizResponse>(quiz.get_quiz(id), {
            params: {}
        });
    }

    static async patchQuiz({id}: ID, {
        add_images,
        send_question,
        send_answer,
        name,
        description,
        question_time,
        reward,
        category
    }: IPatchQuizRequestBody): Promise<AxiosResponse<IPatchQuizResponse>> {
        return await $qms_account.patch<IPatchQuizResponse>(quiz.patch_quiz(id), {
            params: {
                add_images,
                send_question,
                send_answer,
                name,
                description,
                question_time,
                reward,
                category
            }
        });
    }

    static async deleteQuiz({id}: ID): Promise<AxiosResponse<IDeleteQuizResponse>> {
        return await $qms_account.delete<IDeleteQuizResponse>(quiz.delete_quiz(id), {
            params: {}
        });
    }

    static async postQuizNewSession({id}: ID, {
        type,
        expected_date,
        use_code,
        expected_participant,
        expected_end_date,
        winner_number,
        reward,
        question_time,
        send_question,
        send_answer
    }: IPostQuizNewSessionRequestBody): Promise<AxiosResponse<IPostQuizNewSessionResponse>> {
        return await $qms_account.post<IPostQuizNewSessionResponse>(quiz.post_quiz(id), {
            params: {
                type,
                expected_date,
                use_code,
                expected_participant,
                expected_end_date,
                winner_number,
                reward,
                question_time,
                send_question,
                send_answer
            }
        });
    }
}

export class QuizCategory {
    static async getQuizCategoryAll({
        limit,
        offset,
        ordering,
        search
    }: IGetQuizCategoryAllParams): Promise<AxiosResponse<IListDataResponse<IGetQuizCategoryAllResponse>>> {
        return await $qms_account.get<IListDataResponse<IGetQuizCategoryAllResponse>>(quiz_categori.get_all(), {
            params: {
                limit,
                offset,
                ordering,
                search
            }
        });
    }

    static async getQuizCategory({id}: ID): Promise<AxiosResponse<IGetQuizCategoryAllResponse>> {
        return await $qms_account.get<IGetQuizCategoryAllResponse>(quiz_categori.get_categori(id), {
            params: {}
        });
    }
}

export class QuizQuestion {
    static async getQuizQuestionAll({id}: ID, {
        limit,
        offset, ordering,
        search, type,
        quiz_id
    }: IGetQuizQuestionAllParams): Promise<AxiosResponse<IListDataResponse<IQuizQuestionResponse>>> {
        return await $qms_account.get<IListDataResponse<IQuizQuestionResponse>>(quiz_question.get_all(id), {
            params: {
                limit,
                offset, ordering,
                search, type,
                quiz_id
            }
        });
    }

    static async postQuizQuestion({id}: ID, {
        add_answers,
        text,
        type,
        image
    }: IQuizQuestionRequestBody): Promise<AxiosResponse<IQuizQuestionResponse>> {
        return await $qms_account.post<IQuizQuestionResponse>(quiz_question.post(id), {
            params: {
                add_answers,
                text,
                type,
                image
            }
        });
    }

    static async getQuizQuestion({id: quiz_id}: ID, {id}: ID): Promise<AxiosResponse<IQuizQuestionResponse>> {
        return await $qms_account.get<IQuizQuestionResponse>(quiz_question.get_question(quiz_id, id), {
            params: {}
        });
    }

    static async patchQuizQuestion({id: quiz_id}: ID, {id}: ID, {
        add_answers,
        text,
        type,
        image
    }: IQuizQuestionRequestBody): Promise<AxiosResponse<IQuizQuestionResponse>> {
        return await $qms_account.patch<IQuizQuestionResponse>(quiz_question.patch_question(quiz_id, id), {
            params: {
                add_answers,
                text,
                type,
                image
            }
        });
    }

    static async deleteQuizQuestion({id: quiz_id}: ID, {id}: ID): Promise<AxiosResponse<IDeleteQuizResponse>> {
        return await $qms_account.delete<IDeleteQuizResponse>(quiz_question.delete_question(quiz_id, id), {
            params: {}
        });
    }
}

export class QuizQuestionAnswer {
    static async getQuizQuestionAnswerAll({id: quiz_id}: ID, {id: question_id}: ID, {
        limit,
        offset,
        ordering,
        search
    }: IGetQuizQuestionAnswerAllParams): Promise<AxiosResponse<IGetQuizQuestionAnswerAll>> {
        return await $qms_account.get<IGetQuizQuestionAnswerAll>(quiz_question_answer.get_all(quiz_id, question_id), {
            params: {
                limit,
                offset,
                ordering,
                search
            }
        });
    }

    static async postQuizQuestionAnswer({id: quiz_id}: ID, {id: question_id}: ID, {
        text,
        correct_answer
    }: IPostQuizQuestionAnswerRequestBody): Promise<AxiosResponse<IPostQuizQuestionAnswer>> {
        return await $qms_account.post<IPostQuizQuestionAnswer>(quiz_question_answer.post(quiz_id, question_id), {
            params: {
                text,
                correct_answer
            }
        });
    }

    static async getQuizQuestionAnswer({id: quiz_id}: ID, {id: question_id}: ID, {id}: ID): Promise<AxiosResponse<IGetQuizQuestionAnswer>> {
        return await $qms_account.get<IGetQuizQuestionAnswer>(quiz_question_answer.get_answer(quiz_id, question_id, id), {
            params: {}
        });
    }

    static async patchQuizQuestionAnswer({id: quiz_id}: ID, {id: question_id}: ID, {id}: ID, {
        text,
        correct_answer
    }: IPatchQuizQuestionAnswerRequestBody): Promise<AxiosResponse<IPatchQuizQuestionAnswer>> {
        return await $qms_account.patch<IPatchQuizQuestionAnswer>(quiz_question_answer.patch_answer(quiz_id, question_id, id), {
            params: {
                text,
                correct_answer
            }
        });
    }

    static async deleteQuizQuestionAnswer({id: quiz_id}: ID, {id: question_id}: ID, {id}: ID): Promise<AxiosResponse<IDeleteQuizResponse>> {
        return await $qms_account.delete<IDeleteQuizResponse>(quiz_question_answer.delete_answer(quiz_id, question_id, id), {
            params: {}
        });
    }
}

export class QuizSession {
    static async getQuizSessionAll(
        {
            signal
        }: AbortController,
        {
            category,
            creator,
            history,
            i_org,
            i_part,
            limit,
            offset,
            ordering,
            question_time,
            search,
            type
        }: IGetQuizSessionAllParams): Promise<AxiosResponse<IListDataResponse<IGetQuizSession>>> {
        return await $qms_account.get<IListDataResponse<IGetQuizSession>>(quiz_session.get_all(), {
            signal: signal,
            params: {
                category,
                creator,
                history,
                i_org,
                i_part,
                limit,
                offset,
                ordering,
                question_time,
                search,
                type
            }
        });
    }

    static async getQuizSession({id}: ID, {history}: IGetQuizSessionParams): Promise<AxiosResponse<IGetQuizSession>> {
        return await $qms_account.get<IGetQuizSession>(quiz_session.get(id), {
            params: {
                history
            }
        });
    }

    static async patchQuizSessionCalculatePlaces({id}: ID, {
        participant_number,
        participant_finish_number,
        quiz,
        question_time,
        send_question,
        send_answer,
        finish_date,
        is_opened,
        creator,
        type,
        expected_date,
        expected_participants,
        expected_end_date,
        winner_number,
        question_number,
        is_code,
        reward
    }: IPatchQuizSessionCalculatePlacesRequestBody): Promise<AxiosResponse<IPatchQuizSessionCalculatePlacesResponse>> {
        return await $qms_account.patch<IPatchQuizSessionCalculatePlacesResponse>(quiz_session.patch_calculate_places(id), {
            params: {
                participant_number,
                participant_finish_number,
                quiz,
                question_time,
                send_question,
                send_answer,
                finish_date,
                is_opened,
                creator,
                type,
                expected_date,
                expected_participants,
                expected_end_date,
                winner_number,
                question_number,
                is_code,
                reward
            }
        });
    }

    static async patchQuizSessionCloseRegistration({id}: ID): Promise<AxiosResponse<IPatchQuizSessionCalculatePlacesResponse>> {
        return await $qms_account.patch<IPatchQuizSessionCalculatePlacesResponse>(quiz_session.patch_close_registration(id), {
            params: {}
        });
    }

    static async patchQuizSessionClose({id}: ID): Promise<AxiosResponse<IPatchQuizSessionCalculatePlacesResponse>> {
        return await $qms_account.patch<IPatchQuizSessionCalculatePlacesResponse>(quiz_session.patch_close_session(id), {
            params: {}
        });
    }

    static async postQuizSessionConnect({id}: ID, {
        code,
        name
    }: IPostQuizSessionConnectRequestBody): Promise<AxiosResponse<IPostQuizSessionConnectResponse>> {
        return await $qms_account.post<IPostQuizSessionConnectResponse>(quiz_session.post_connect(id), {
            params: {
                code,
                name
            }
        });
    }

    static async getQuizSessionIsCreator({id}: ID): Promise<AxiosResponse<IGetQuizSessionIsCreatorResponse>> {
        return await $qms_account.get<IGetQuizSessionIsCreatorResponse>(quiz_session.get_is_creator(id), {
            params: {}
        });
    }

    static async patchQuizSessionParticipant({id}: ID, {
        name
    }: IPatchQuizSessionParticipantRequestBody): Promise<AxiosResponse<IPostQuizSessionConnectResponse>> {
        return await $qms_account.patch<IPostQuizSessionConnectResponse>(quiz_session.patch_participant(id), {
            params: {
                name
            }
        });
    }

    static async getQuizSessionReconnect({id}: ID): Promise<AxiosResponse<IPostQuizSessionConnectResponse>> {
        return await $qms_account.get<IPostQuizSessionConnectResponse>(quiz_session.get_reconnect(id), {
            params: {}
        });
    }

    static async getQuizSessionShowMyResult({id}: ID, {history}: IGetQuizSessionParams): Promise<AxiosResponse<IGetQuizSessionShowMyResultResponse>> {
        return await $qms_account.get<IGetQuizSessionShowMyResultResponse>(quiz_session.get_show_my_result(id), {
            params: {
                history
            }
        });
    }

    static async getQuizSessionShowQuestionResult({id}: ID): Promise<AxiosResponse<IPatchQuizSessionCalculatePlacesResponse>> {
        return await $qms_account.get<IPatchQuizSessionCalculatePlacesResponse>(quiz_session.get_show_question_result(id), {
            params: {}
        });
    }

    static async getQuizSessionShowResult({id}: ID, {
        category,
        creator,
        history,
        i_org,
        i_part,
        limit,
        offset,
        ordering,
        question_time,
        search,
        type
    }: IGetQuizSessionAllParams): Promise<AxiosResponse<IListDataResponse<IGetQuizSessionShowMyResultResponse>>> {
        return await $qms_account.get<IListDataResponse<IGetQuizSessionShowMyResultResponse>>(quiz_session.get_show_result(id), {
            params: {
                category,
                creator,
                history,
                i_org,
                i_part,
                limit,
                offset,
                ordering,
                question_time,
                search,
                type
            }
        });
    }

    static async getQuizSessionShowWinner({id}: ID, {
        history
    }: IGetQuizSessionParams): Promise<AxiosResponse<IGetQuizSessionShowMyResultResponse>> {
        return await $qms_account.get<IGetQuizSessionShowMyResultResponse>(quiz_session.get_show_winner(id), {
            params: {
                history
            }
        });
    }
}

export class QuizSessionSync {
    static async postQuizSessionSync({id}: ID, {
        answer,
        start_time,
        answer_time,
        spend_time,
        correct,
        participant,
        question
    }: IPostQuizSessionSyncRequestBody): Promise<AxiosResponse<IPostQuizSessionSyncResponse>> {
        return await $qms_account.post<IPostQuizSessionSyncResponse>(quiz_session_sync.post(id), {
            params: {
                answer,
                start_time,
                answer_time,
                spend_time,
                correct,
                participant,
                question
            }
        });
    }

    static async getQuizSessionSyncCheckAnswers({id}: ID): Promise<AxiosResponse<IGetQuizSessionSyncCheckAnswersResponse>> {
        return await $qms_account.get<IGetQuizSessionSyncCheckAnswersResponse>(quiz_session_sync.get_check_answers(id), {
            params: {}
        });
    }

    static async getQuizSessionSyncCheckTimer({id}: ID): Promise<AxiosResponse<IGetQuizSessionSyncCheckTimerResponse>> {
        return await $qms_account.get<IGetQuizSessionSyncCheckTimerResponse>(quiz_session_sync.get_check_timer(id), {
            params: {}
        });
    }

    static async getQuizSessionSyncIsAnswered({id}: ID): Promise<AxiosResponse<IGetQuizSessionSyncCheckAnswersResponse>> {
        return await $qms_account.get<IGetQuizSessionSyncCheckAnswersResponse>(quiz_session_sync.get_is_answered(id), {
            params: {}
        });
    }

    static async patchQuizSessionSyncNextQuestion({id}: ID): Promise<AxiosResponse<IPatchQuizSessionSyncNextQuestionResponse>> {
        return await $qms_account.patch<IPatchQuizSessionSyncNextQuestionResponse>(quiz_session_sync.patch_next_question(id), {
            params: {}
        });
    }

    static async getQuizSessionSyncShowQuestion({id}: ID, {
        main,
        show_answers
    }: IGetQuizSessionSyncShowQuestionParams): Promise<AxiosResponse<IPatchQuizSessionSyncNextQuestionResponse>> {
        return await $qms_account.get<IPatchQuizSessionSyncNextQuestionResponse>(quiz_session_sync.get_show_question(id), {
            params: {
                main,
                show_answers
            }
        });
    }

    static async patchQuizSessionStartTimer({id}: ID): Promise<AxiosResponse<IPatchQuizSessionSyncStartTimerResponse>> {
        return await $qms_account.patch<IPatchQuizSessionSyncStartTimerResponse>(quiz_session_sync.patch_start_timer(id), {
            params: {}
        });
    }
}

export class QuizSessionAsync {
    static async postQuizSessionAsync({id}: ID, {
        answer,
        start_time,
        answer_time,
        spend_time,
        correct,
        participant,
        question
    }: IPostQuizSessionSyncRequestBody): Promise<AxiosResponse<IPostQuizSessionSyncResponse>> {
        return await $qms_account.post<IPostQuizSessionSyncResponse>(quiz_session_async.post(id), {
            params: {
                answer,
                start_time,
                answer_time,
                spend_time,
                correct,
                participant,
                question
            }
        });
    }

    static async getQuizSessionAsyncCheckTimer({id}: ID): Promise<AxiosResponse<IGetQuizSessionSyncCheckTimerResponse>> {
        return await $qms_account.get<IGetQuizSessionSyncCheckTimerResponse>(quiz_session_async.get_check_timer(id), {
            params: {}
        });
    }

    static async getQuizSessionAsyncIsAnswered({id}: ID): Promise<AxiosResponse<IGetQuizSessionSyncCheckAnswersResponse>> {
        return await $qms_account.get<IGetQuizSessionSyncCheckAnswersResponse>(quiz_session_async.get_is_answered(id), {
            params: {}
        });
    }

    static async patchQuizSessionAsyncNextQuestion({id}: ID): Promise<AxiosResponse<IPatchSessionAsyncNextQuestionResponse>> {
        return await $qms_account.patch<IPatchSessionAsyncNextQuestionResponse>(quiz_session_async.patch_next_question(id), {
            params: {}
        });
    }

    static async getQuizSessionAsyncShowQuestion({id}: ID, {
        main,
        show_answers
    }: IGetQuizSessionSyncShowQuestionParams): Promise<AxiosResponse<IGetSessionAsyncShowQuestionResponse>> {
        return await $qms_account.get<IGetSessionAsyncShowQuestionResponse>(quiz_session_async.get_show_question(id), {
            params: {
                main,
                show_answers
            }
        });
    }

    static async patchQuizSessionAsyncStartTimer({id}: ID): Promise<AxiosResponse<IPatchQuizSessionSyncStartTimerResponse>> {
        return await $qms_account.patch<IPatchQuizSessionSyncStartTimerResponse>(quiz_session_async.patch_start_timer(id), {
            params: {}
        });
    }
}

export class QuizImage {
    static async getQuizImageAll({id: quiz_id}: ID, {
        limit,
        offset,
        ordering,
        search
    }: IGetQuizQuestionAnswerAllParams): Promise<AxiosResponse<IListDataResponse<IGetQuizImage>>> {
        return await $qms_account.get<IListDataResponse<IGetQuizImage>>(quiz_image.get_all(quiz_id), {
            params: {
                limit,
                offset,
                ordering,
                search
            }
        });
    }

    static async postQuizImage({id: quiz_id}: ID, {
        file
    }: IPostQuizImageRequestBody): Promise<AxiosResponse<IGetQuizImage>> {
        return await $qms_account.post<IGetQuizImage>(quiz_image.post(quiz_id), {
            params: {
                file
            }
        });
    }

    static async getImage({id: quiz_id}: ID, {id}: ID): Promise<AxiosResponse<IGetQuizImage>> {
        return await $qms_account.get<IGetQuizImage>(quiz_image.get(quiz_id, id), {
            params: {}
        });
    }

    static async patchImage({id: quiz_id}: ID, {id}: ID, {
        file
    }: IPostQuizImageRequestBody): Promise<AxiosResponse<IGetQuizImage>> {
        return await $qms_account.patch<IGetQuizImage>(quiz_image.patch(quiz_id, id), {
            params: {
                file
            }
        });
    }

    static async deleteImage({id: quiz_id}: ID, {id}: ID): Promise<AxiosResponse<IDeleteQuizResponse>> {
        return await $qms_account.delete<IDeleteQuizResponse>(quiz_image.delete(quiz_id, id), {
            params: {}
        });
    }
}

export class Session {
    static async getSession(): Promise<AxiosResponse<IGetSessionCreatorsResponse>> {
        return await $qms_account.get<IGetSessionCreatorsResponse>(session.get(), {
            params: {}
        });
    }
}

export class QuizStat {
    static async getStatAll({
        limit,
        offset,
        ordering,
        search,
        who
    }: IGetStatAllParams): Promise<AxiosResponse<IListDataResponse<IGetStatAllResponseResult>>> {
        return await $qms_account.get<IListDataResponse<IGetStatAllResponseResult>>(quiz_stat.get_all(), {
            params: {
                limit,
                offset,
                ordering,
                search,
                who
            }
        });
    }

    static async getStat({id}: ID, {
        who
    }: IGetQuizStatParams): Promise<AxiosResponse<IGetStatAllResponseResult>> {
        return await $qms_account.get<IGetStatAllResponseResult>(quiz_stat.get(id), {
            params: {
                who
            }
        });
    }

    static async getMyStat(): Promise<AxiosResponse<IGetQuizStatMyResponse>> {
        return await $qms_account.get<IGetQuizStatMyResponse>(quiz_stat.get_my_stat(), {
            params: {}
        });
    }
}