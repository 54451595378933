/**
 * Created by Hennadiy on 21.02.2023
 */
import {FallbackProps} from 'react-error-boundary';

export function ErrorFallback({resetErrorBoundary, error}: FallbackProps) {
    return (
        <div role="alert">
            <p>Something went wrong</p>
            <pre>{error.name}: {error.message}</pre>
        </div>
    );
}