import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import style from './AnimText.module.css'
import styled from "styled-components";

export const AnimText = () => {

    const ref1 = useRef<HTMLDivElement>(null);

    const [width, setWidth] = useState(0);

    const [styled, setStyled] = useState(0);


    useEffect(() => {
        function getRandomArbitrary(min: number, max: number) {
            return Math.random() * (max - min) + min;
        }

        const timer = setTimeout(function () {
            const i = getRandomArbitrary(0, 15)
            setStyled(Math.trunc(i))
        }, 2300);
        return () => {
            clearTimeout(timer)
        }
    }, [styled])

    useLayoutEffect(() => {
        if (ref1.current) {
            setWidth(ref1.current.offsetWidth);
            // console.log(ref1.current.offsetWidth)
        }
    }, [ref1.current]);

    return (
        <div className={style.animText}>
            <div className={style.animTextTitle}>
                Infinity App, Welcome to future
            </div>
            <div className={style.animTextTitleContent}>
                <div className={style.animeText1}>
                    <Anim1 ref={ref1} width={width}>
                        <AnimTextItem text={'Company'} id={1} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'KPI'} id={2} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Online payment'} id={3} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Chat'} id={4} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Videocall'} id={5} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Desktop'} id={6} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'CRM'} id={7} styled={styled} setOn={setStyled}/>
                    </Anim1>

                    <Anim2 width={width}>
                        <AnimTextItem text={'Company'} id={1} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'KPI'} id={2} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Online payment'} id={3} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Chat'} id={4} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Videocall'} id={5} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Desktop'} id={6} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'CRM'} id={7} styled={styled} setOn={setStyled}/>
                    </Anim2>
                </div>
            </div>
            <div className={style.animTextTitleContent2}>
                <div className={style.animeText1}>
                    <Anim1 ref={ref1} width={width}>
                        <AnimTextItem text={'Stream'} id={8} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Warehouse'} id={9} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Quiz'} id={10} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Quest'} id={11} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Marketplace'} id={12} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Stories'} id={13} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Delivery'} id={14} styled={styled} setOn={setStyled}/>
                    </Anim1>

                    <Anim2 width={width}>
                        <AnimTextItem text={'Stream'} id={8} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Warehouse'} id={9} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Quiz'} id={10} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Quest'} id={11} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Marketplace'} id={12} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Stories'} id={13} styled={styled} setOn={setStyled}/>
                        <AnimTextItem text={'Delivery'} id={14} styled={styled} setOn={setStyled}/>
                    </Anim2>
                </div>
            </div>
        </div>
    )
}

type AnimTextItemProps = {
    text: string;
    styled: number;
    id: number;
    setOn: (a: number) => void;
}
const AnimTextItem = ({text, styled, id, setOn}: AnimTextItemProps) => {
    return (
        <div className={styled === id ? style.animeItemActive : style.animeItem} onMouseOver={() => {
            setOn(id)
        }}>
            {text}
        </div>
    )
}

type BlockProps = {
    width: number;
}

const Block = styled.div`
  min-width: 100%;
  min-height: 100%;

  position: absolute;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display: flex;
  top: 0;

  *{
    flex-shrink: 0;
  }
`;

const Anim1 = styled(Block)`
  animation-name: anim1;
  left: 0;
  @keyframes anim1 {
    from {
      left: 0;
      top: 0;
    }

    to {
      left: -${({width}: BlockProps) => width}px;
      top: 0;
    }
  }
`;

const Anim2 = styled(Block)`
  animation-name: anim2;

  left: ${({width}: BlockProps) => width}px;

  @keyframes anim2 {
    from {
      left: ${({width}: BlockProps) => width}px;
    }

    to {
      left: 0;
    }
  }

`;