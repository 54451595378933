
import React from 'react';
import styled from 'styled-components';

import img from '../assets/120348-waiting-sand.gif';


export const HourglassLoader = () => {
    return (
        <Root src={img}/>
    );
};

type RootProps = {
    src: string;
}

const Root = styled.div`
  background-image: url(${({src}: RootProps) => src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  
  width: 200px;
  height: 200px;
  margin: 0 auto;
`;
