import React, {Dispatch, SetStateAction, useState} from 'react';
import {InputAdornment} from '@mui/material';
import {Eye, EyeSlash,} from 'iconsax-react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';

import {Button, Input} from 'shared/ui/components/client';


type Props = {
    error?: boolean;
    loader?: boolean;
    handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;

    is_existed: boolean | undefined;
    disabled: boolean;

    password1: string;
    setPassword1: Dispatch<SetStateAction<string>>;
    password2: string;
    setPassword2: Dispatch<SetStateAction<string>>;
}

export const Form = observer(({
    handleSubmit,
    error,
    password2,
    setPassword1,
    setPassword2,
    password1,
    loader,
    disabled,
    is_existed
}: Props) => {

    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <Root onSubmit={handleSubmit}>
            <Input
                error={error}
                required={true}
                value={password1}
                onChange={(e) => setPassword1(e.target.value)}
                label={'New Password'}
                type={showPassword ? 'text' : 'password'}

                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        {
                            showPassword ?
                                <Eye
                                    size="24"
                                    color="#7F92A0"
                                    onClick={() => setShowPassword(false)}
                                />
                                :
                                <EyeSlash
                                    size="24"
                                    color="#7F92A0"
                                    onClick={() => setShowPassword(true)}
                                />
                        }
                    </InputAdornment>

                }}
            />
            <Input
                error={password2.trim().length > 0 && password1 !== password2}
                required={true}
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                label={'Confirm New Pasword'}
                type={'password'}
            />
            <Button
                disabled={disabled}
                fullWidth
                type={'submit'}
                loading={loader}
                variant={'contained'}
                color={'primary'}
                disableElevation>
                NEXT
            </Button>
        </Root>
    );
});

const Root = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;