
import React from 'react';
import styled from 'styled-components';

import logo from './assets/Logo.svg';


type Props = {
    title?: string | React.ReactNode;
    logoTitle?: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    subLogoTitle?: string | React.ReactNode;
}

export const LogoWithTitle = ({title, logoTitle, subtitle, subLogoTitle}: Props) => {

    return (
        <>
            <Logo src={logo}/>

            {title && <Title>{title}</Title>}

            {logoTitle && <LogoTitle>{logoTitle}</LogoTitle>}

            {subLogoTitle && <SubLogoTitle>{subLogoTitle}</SubLogoTitle>}

            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </>
    );
};


const Logo = styled.img`
  display: block;
  width: 100%;
  max-width: 112px;
  margin: 0 auto 16px auto;
  pointer-events: none;
`;

const Title = styled.div`
  width: 100%;
  text-align: center;

  font-weight: 600;
  font-size: 20px;
  color: #262626;
  margin-bottom: 4px;
`;

const LogoTitle = styled.div`
  width: 100%;
  text-align: center;

  font-weight: 700;
  font-size: 32px;
  color: #262626;
  margin-bottom: 32px;
`;

const Subtitle = styled.div`
  width: 74%;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #7F92A0;
  margin: 0 auto 24px auto;
`;

const SubLogoTitle = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #262626;
`;
