
import React from 'react';
import {Skeleton} from '@mui/material';
import styled from 'styled-components';

import {ActionsProps} from './types';


export const Actions = ({title, blue_label_title, entrance_fee}: ActionsProps) => {
    return <Root>
        <Row>
            <Title>
                {title}
            </Title>
            <Fee>
                {entrance_fee || <Skeleton sx={{transform: 'none'}} variant={'rounded'}/>}
            </Fee>
        </Row>
        <BlueLabel>
            {blue_label_title}
        </BlueLabel>
    </Root>;
};

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  width: 100%;
`;

const Row = styled.div`
  flex-shrink: 1;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 10px;
  color: var(--gray);
  margin-bottom: 2px;
`;

const Fee = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: var(--black);

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const BlueLabel = styled.div`
  background: linear-gradient(143.49deg, #1DA1F2 0%, #003CC5 125.35%);
  border-radius: 8px;
  width: 130px;
  height: 31px;
  flex-shrink: 0;

  font-weight: 500;
  font-size: 12px;
  color: white;

  padding: 8px;
  text-align: center;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
`;