import React, {useContext} from 'react';
import {IRoute, Routing} from 'gears-react';
import {observer} from 'mobx-react-lite';

import {Context} from 'shared/api/ums/account';

import {PageLoaderWithLogo} from 'shared/ui/components/general';

import {useCheckAccountAccess} from '../lib/hooks/useCheckAccountAccess';
import {useRefreshToken} from '../lib/hooks/useRefreshToken';

type Props = {
    protectedRoutes: IRoute[];
    authRoutes: IRoute[];
}

export const CheckAccountAccess = observer(({protectedRoutes, authRoutes}: Props) => {
    const account = useContext(Context);

    useCheckAccountAccess();
    useRefreshToken();

    if (account.store.details.loader.get()) return <PageLoaderWithLogo/>;

    return <React.Fragment>
        <Routing fallback={<PageLoaderWithLogo/>}
                 routes={account.store.getIsAuth() ? protectedRoutes : authRoutes}/>
    </React.Fragment>;
});