import React, {useContext, useEffect} from 'react';
import Alert from '@mui/material/Alert';
import Grow from '@mui/material/Grow';
import {observer} from 'mobx-react-lite';
import {Context} from 'shared/lib/context';
import styled from 'styled-components';

export const MyAlert = observer(() => {
    const {store} = useContext(Context);

    useEffect(() => {
        const timer = setTimeout(() => {
            store.alert.set(false, store.alert.get().type, store.alert.get().message);
        }, 5000);
        return () => clearTimeout(timer);
    }, [store.alert.get().isShow]);

    return (
        <Root>
            <Grow
                in={store.alert.get().isShow} {...(store.alert.get().isShow ? {timeout: 500} : {timeout: 500})}>
                <Alert variant="filled" severity={
                    store.alert.get().type === 'success' ? 'success' :
                        store.alert.get().type === 'error' ? 'error' :
                            store.alert.get().type === 'warning' ? 'warning' : 'info'
                } sx={{
                    color: 'white',
                    whiteSpace: 'pre-line'
                }}>
                    {store.alert.get().message}
                </Alert>
            </Grow>
        </Root>
    );
});

const Root = styled.div`
  position: fixed;
  top: 24px; // or bottom: 24px;
  left: 50%; // or left: 24px;
  transform: translateX(-50%);
  z-index: 9999999999999999999;
  pointer-events: none;
  width: 90%;
  max-width: 300px;
`;