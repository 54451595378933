import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';

export const TimerLine = observer(() => {

    const [seconds, setSeconds] = useState(5);

    return <Root>
        <Line seconds={seconds}/>
    </Root>;
});

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  background-color: rgba(127, 146, 160, 0.2);
  border-radius: 100px;
`;

type LineProps = {
    seconds: number;
}

const Line = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  background: linear-gradient(143.49deg, #1DA1F2 0%, #003CC5 125.35%);
  border-radius: 100px;
  transition: all 1s;

  animation-name: decrease;
  animation-duration: ${({seconds}: LineProps) => seconds}s;
  
  animation-iteration-count: infinite;
  
  @keyframes decrease {
    0% {
      width: 100%;
      background: #1DA1F2;
    }
    100% {
      width: 1%;
      background: #FF5A5F;
    }
  }

`;