/**
 * Created by Hennadiy on 24.02.2023
 */


import React, {CSSProperties} from 'react';
import styled from 'styled-components';


type Props = {
    titles?: React.ReactNode[] | string[];
    style?: CSSProperties;
}

export const Terms = ({titles, style}: Props) => {
    return (
        <Root style={style}>
            {titles?.map((title, i) => <TermContainer key={i}>
                <Term style={{fontSize: '24px'}}>·</Term>
                <Term>{title}</Term>
            </TermContainer>)}
        </Root>
    );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


const TermContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  max-height: 20px;

`;

const Term = styled.div`
  color: #7F92A0;
  font-weight: 400;
  font-size: 12px;
`;