/**
 * Created by Hennadiy on 16.02.2023
 */

import {$axios} from './instances';
import {AxiosResponse} from 'axios';

import {urls} from './urls';
import * as types from './types';


export default class Services {
    static async getQuizQuestion(
        {
            signal
        }: AbortController,
        {
            quiz_id,
            id
        }: types.IQuizQuestionParams): Promise<AxiosResponse<types.IQuizQuestionResponse>> {
        return await $axios.get<types.IQuizQuestionResponse>(urls.getQuizQuestion(quiz_id, id), {
            signal: signal,
        });
    }

    static async patchQuizQuestion({quiz_id, id}: types.IQuizQuestionParams, {
        add_answers,
        text,
        type,
        image
    }: types.IQuizQuestionRequestBody): Promise<AxiosResponse<types.IQuizQuestionResponse>> {
        let body = new FormData();

        if (text !== undefined) {
            body.append('text', text);
        }

        if (type !== undefined) {
            body.append('type', type.toString());
        }

        if (add_answers !== undefined) {
            body.append('add_answers', add_answers.toString());
        }

        if (image !== undefined) {
            body.append('add_answers', image.toString());
        }

        return await $axios.patch<types.IQuizQuestionResponse>(urls.patchQuizQuestion(quiz_id, id), {
            add_answers,
            text,
            type,
            image
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });


    }

    static async postQuizQuestion({quiz_id}: types.IPostQuizQuestionParams, {
        add_answers,
        text,
        type,
        image
    }: types.IQuizQuestionRequestBody): Promise<AxiosResponse<types.IQuizQuestionResponse>> {
        let body = new FormData();

        if (text !== undefined) {
            body.append('text', text);
        }

        if (type !== undefined) {
            body.append('type', type.toString());
        }

        if (add_answers !== undefined) {
            body.append('add_answers', add_answers.toString());
        }

        if (image !== undefined) {
            body.append('add_answers', image.toString());
        }

        return await $axios.post<types.IQuizQuestionResponse>(urls.postQuizQuestion(quiz_id), {
            add_answers,
            text,
            type,
            image
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}