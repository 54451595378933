export const account = {
    get() {
        return `/UMS/api/v1.0/account/`;
    },
    patch() {
        return `/UMS/api/v1.0/account/`;
    },
    get_personal_data() {
        return `/UMS/api/v1.0/account/personal_data/`;
    },
    patch_personal_data() {
        return `/UMS/api/v1.0/account/personal_data/`;
    },
    auth() {
        return `/UMS/api/v1.0/account/auth/`;
    },
    cent_token() {
        return `/UMS/api/v1.0/account/cent_token/`;
    },
    change_password() {
        return `/UMS/api/v1.0/account/change-password/`;
    },
    change_pvc() {
        return `/UMS/api/v1.0/account/change-pvc/`;
    },
    check_user() {
        return `/UMS/api/v1.0/account/check-user/`;
    },
    create() {
        return `/UMS/api/v1.0/account/create/`;
    },
    refresh_token() {
        return `/UMS/api/v1.0/account/refresh-token/`;
    },
    send_pvc() {
        return `/UMS/api/v1.0/account/send-pvc/`;
    },
    check_username(username: string) {
        return `/UMS/api/v1.0/account/check-username/${username}/`;
    },
};