/**
 * Created by Hennadiy on 16.02.2023
 */

import {$axios} from './instances';
import {AxiosResponse} from 'axios';

import {urls} from './urls';
import * as types from './types';

export default class Services {
    static async postCheckPvc(
        {
            signal
        }: AbortController,
        {
            phone,
            pvc
        }: types.ICheckPvcParams): Promise<AxiosResponse<types.ICheckPvcResponse>> {

        return await $axios.post<types.ICheckPvcResponse>(urls.postCheckPvc(), {
            phone,
            pvc,
        }, {
            signal:signal,
            params: {
                login_params: 'phone_pvc'
            }
        });
    }
}