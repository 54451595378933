/**
 * Created by Hennadiy on 15.02.2023
 */

import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';

import {Context} from '../lib/context';
import {CategoryTabs} from 'entities/quiz';

export const QuizCategories = observer(() => {
    const {store} = useContext(Context);

    const setCategory = (category: number) => {
        if (!store.getQuizList.loader.get()) {
            store.setCategory(category);
        }
    };

    return <CategoryTabs category={store.category} setCategory={setCategory}/>;
});