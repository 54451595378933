/**
 * Created by Hennadiy on 13.02.2023
 */

import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {Context as globalContext} from 'shared/lib/context';
import {path} from 'shared/const';

import {Context} from './context';
import {useNavigate} from 'react-router-dom';
import {IsNumber} from 'shared/lib/helpers';
import {Props} from './props';

type QuizQuestionsParams = {
    quiz_id: string;
    question_id: string;
};

type TRequest = {
    request?: ((index: number) => void) | undefined;
    reload?: () => void;
}

export const useFindFirstQuestion = (props: Props) => {
    const abortController = new AbortController();
    const {store} = useContext(Context);
    const {quiz_id, question_id} = useParams<QuizQuestionsParams>();

    const [notFound, setNotFound] = useState<boolean>(false);


    const [findNextQuestionIdByIndex, setFindNextQuestionIdByIndex] = useState<TRequest>({request: undefined});

    const global = useContext(globalContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (IsNumber(quiz_id)) {
            if (question_id === undefined) {
                store.getQuizQuestionList.request(abortController, {
                    limit: 1,
                    offset: 0,
                    quiz_id: Number(quiz_id),
                }).then(r => {
                    console.log(r);
                    if (r?.status === 200) {
                        setNotFound(false);
                        if (store.getQuizQuestionList.get().results.length > 0) {
                            goToQuestion(store.getQuizQuestionList.get().results[0].id);
                        } else {
                            goToCreate();
                        }
                    } else {
                        setNotFound(true);
                        if (!abortController.signal.aborted) {
                            global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                        }
                    }
                });
            } else {
                console.log('asd');
                store.getQuizQuestionList.loader.set(false);
            }
        } else {
            setNotFound(true);
            store.getQuizQuestionList.loader.set(false);
        }

        return () => {
            abortController.abort();
        };
    }, []);

    const goToQuestion = (question_id: number) => {
        navigate(path.quiz.getQuestion(quiz_id, question_id));
    };

    const goToCreate = () => {
        navigate(path.quiz.getQuestion(quiz_id, path.quiz.create));
    };


    return {
        notFound,
        findFirstQuestionLoading: store.getQuizQuestionList.loader.get(),
        findNextQuestionIdByIndex,
        setFindNextQuestionIdByIndex,
    };
};