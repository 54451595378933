/**
 * Created by Hennadiy on 16.02.2023
 */

import {$axios} from './instances';
import {AxiosResponse} from 'axios';

import {urls} from './urls';
import * as types from './types';

export default class Services {
    static async getCheckUsername(
        {
            signal
        }: AbortController,
        {
            username
        }: types.ICheckUserNameParams): Promise<AxiosResponse<types.ICheckUserNameResponse>> {
        return await $axios.get<types.ICheckUserNameResponse>(urls.getCheckUsername(username), {
            signal: signal,
        });
    }
}