/**
 * Created by Hennadiy on 28.02.2023
 */
import styled from 'styled-components';

export const PublicQuizDetails = {
    Creator: styled.div`
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: var(--gray);
      margin-bottom: 4px;
    `,

    Title: styled.div`
      font-weight: 600;
      font-size: 24px;
      color: var(--black);
      margin-bottom: 4px;
      text-align: center;
    `,
    Subtitle: styled.div`
      font-weight: 400;
      font-size: 12px;
      color: var(--gray);
      text-align: center;
    `,
};