
import React from 'react';
import {Skeleton} from '@mui/material';
import styled from 'styled-components';

import {useProgressiveImage} from './hooks/useProgressiveImage';


type Props = {
    style?: React.CSSProperties;
    src?: string;
}

export const GetImage = ({style, src}: Props) => {
    const loaded = useProgressiveImage(src!);

    return (loaded !== null ? <Root style={style} loaded={loaded}/> :
        <Skeleton sx={{transform: 'none'}} style={style}/>);
};


type RootProps = {
    loaded?: any;
}

const Root = styled.div`
  background-image: url(${({loaded}: RootProps) => loaded});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
