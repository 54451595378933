import React from 'react'
import styled from "styled-components";

import {Container} from "../../shared/StyledComponents";

import logo from "./assets/logo.svg";
import style from './Header.module.css'
import {useNavigate} from "react-router-dom";
import {path} from "shared/const";

export const Header = () => {
    const navigate = useNavigate();

    const {login, signup} = path.auth;


    return (
        <div className={style.header}>
            <Container>
                <div className={style.navbar}>
                    <a className={style.logo} href='#main'>
                        <Logo src={logo} />
                    </a>
                    <div className={style.loginForm}>
                        <div className={style.login} onClick={() => navigate(login)}>LOG IN</div>
                        <div className={style.loginRedirect} onClick={() => navigate(signup)}>SING UP</div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export const Logo = styled.img`
  width: 80%;
`;
