import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1792px;
  margin: 0 auto;
  padding: 0px 32px 0px 32px;

  @media (max-width: 1652px) {
    padding: 0 32px;
  }
  @media (max-width: 460px) {
    padding: 0 10px;
  }

`;
