
import {AxiosResponse} from 'axios';

import {$smms_public} from './instances';
import {
    D,
    D1,
    IDeletePostResponse,
    IGetCommentAllResponse,
    IGetPostAdvertParams,
    IGetPostAdvertResponse,
    IGetPostAllParams,
    IGetPostAllResponse,
    IGetPostAllResponseResults,
    IGetPostAllResponseResultsMedia,
    IGetPostAllResponseResultsProducts,
    IPatchPostResponse,
    IPostCommentRequestBody,
    IPostMediaRequestBody,
    IPostProductAllRequestBody,
    IPostResponse,
    IRequestBody} from './types';
import {comment, post, post_media, product_url} from './urls';


export class Post {
    static async getPostAll({
        limit,
        offset,
        ordering,
        search
    }: IGetPostAllParams): Promise<AxiosResponse<IGetPostAllResponse<IGetPostAllResponseResults>>> {
        return await $smms_public.get<IGetPostAllResponse<IGetPostAllResponseResults>>(post.get_all(), {
            params: {
                limit,
                offset,
                ordering,
                search
            }
        });
    }

    static async postPost({
        action,
        text,
        repost
    }: IRequestBody): Promise<AxiosResponse<IPostResponse>> {
        return await $smms_public.post<IPostResponse>(post.post(), {
            params: {
                action,
                text,
                repost
            }
        });
    }

    static async getPost({id}: D): Promise<AxiosResponse<IGetPostAllResponseResults>> {
        return await $smms_public.get<IGetPostAllResponseResults>(post.get(id), {
            params: {}
        });
    }

    static async patchPost({id}: D, {
        action,
        text,
        repost
    }: IRequestBody): Promise<AxiosResponse<IPatchPostResponse>> {
        return await $smms_public.patch<IPatchPostResponse>(post.patch(id), {
            params: {
                action,
                text,
                repost
            }
        });
    }

    static async deletePost({id}: D): Promise<AxiosResponse<IDeletePostResponse>> {
        return await $smms_public.delete<IDeletePostResponse>(post.delete(id), {
            params: {}
        });
    }

    static async patchPostLike({id}: D): Promise<AxiosResponse<IGetPostAllResponseResults>> {
        return await $smms_public.patch<IGetPostAllResponseResults>(post.patch_like(id), {
            params: {}
        });
    }

    static async postRepost({id}: D): Promise<AxiosResponse<IPatchPostResponse>> {
        return await $smms_public.post<IPatchPostResponse>(post.post_repost(id), {
            params: {}
        });
    }

    static async getPostAdvert({place}: IGetPostAdvertParams): Promise<AxiosResponse<IGetPostAdvertResponse>> {
        return await $smms_public.get<IGetPostAdvertResponse>(post.get_advert(), {
            params: {
                place
            }
        });
    }
}

export class Comment {
    static async getCommentAll({id}: D, {
        limit,
        offset,
        ordering,
        search
    }: IGetPostAllParams): Promise<AxiosResponse<IGetPostAllResponse<IGetCommentAllResponse>>> {
        return await $smms_public.get<IGetPostAllResponse<IGetCommentAllResponse>>(comment.get_all(id), {
            params: {
                limit,
                offset,
                ordering,
                search
            }
        });
    }

    static async postComment({id}: D, {
        user,
        org,
        text,
        post,
        reply_to
    }: IPostCommentRequestBody): Promise<AxiosResponse<IGetCommentAllResponse>> {
        return await $smms_public.post<IGetCommentAllResponse>(comment.post(id), {
            params: {
                user,
                org,
                text,
                post,
                reply_to
            }
        });
    }

    static async getComment({post_id, id}: D1): Promise<AxiosResponse<IGetCommentAllResponse>> {
        return await $smms_public.get<IGetCommentAllResponse>(comment.get(post_id, id), {
            params: {}
        });
    }

    static async patchComment({post_id, id}: D1, {
        user,
        org,
        text,
        post,
        reply_to
    }: IPostCommentRequestBody): Promise<AxiosResponse<IGetCommentAllResponse>> {
        return await $smms_public.patch<IGetCommentAllResponse>(comment.patch(post_id, id), {
            params: {
                user,
                org,
                text,
                post,
                reply_to
            }
        });
    }

    static async deleteComment({post_id, id}: D1): Promise<AxiosResponse<IDeletePostResponse>> {
        return await $smms_public.delete<IDeletePostResponse>(comment.delete(post_id, id), {
            params: {}
        });
    }

    static async patchCommentLike({post_id, id}: D1, {
        user,
        org,
        text,
        post,
        reply_to
    }: IPostCommentRequestBody): Promise<AxiosResponse<IGetCommentAllResponse>> {
        return await $smms_public.patch<IGetCommentAllResponse>(comment.patch_like(post_id, id), {
            params: {
                user,
                org,
                text,
                post,
                reply_to
            }
        });
    }

    static async postCommentReply({post_id, id}: D1, {
        user,
        org,
        text,
        post,
        reply_to
    }: IPostCommentRequestBody): Promise<AxiosResponse<IGetCommentAllResponse>> {
        return await $smms_public.post<IGetCommentAllResponse>(comment.post_reply(post_id, id), {
            params: {
                user,
                org,
                text,
                post,
                reply_to
            }
        });
    }
}

export class PostMedia {
    static async getPostMediaAll({id}: D, {
        limit,
        offset,
        ordering,
        search
    }: IGetPostAllParams): Promise<AxiosResponse<IGetPostAllResponse<IGetPostAllResponseResultsMedia>>> {
        return await $smms_public.get<IGetPostAllResponse<IGetPostAllResponseResultsMedia>>(post_media.get_all(id), {
            params: {
                limit,
                offset,
                ordering,
                search
            }
        });
    }

    static async postPostMedia({id}: D, {
        image,
        file,
        main,
        post
    }: IPostMediaRequestBody): Promise<AxiosResponse<IGetPostAllResponseResultsMedia>> {
        return await $smms_public.post<IGetPostAllResponseResultsMedia>(post_media.post(id), {
            params: {
                image,
                file,
                main,
                post
            }
        });
    }

    static async getPostMedia({post_id, id}: D1): Promise<AxiosResponse<IGetPostAllResponseResultsMedia>> {
        return await $smms_public.get<IGetPostAllResponseResultsMedia>(post_media.get(post_id, id), {
            params: {}
        });
    }

    static async patchPostMedia({post_id, id}: D1, {
        image,
        file,
        main,
        post
    }: IPostMediaRequestBody): Promise<AxiosResponse<IGetPostAllResponseResultsMedia>> {
        return await $smms_public.patch<IGetPostAllResponseResultsMedia>(post_media.patch(post_id, id), {
            params: {
                image,
                file,
                main,
                post
            }
        });
    }

    static async deletePostMedia({post_id, id}: D1): Promise<AxiosResponse<IDeletePostResponse>> {
        return await $smms_public.delete<IDeletePostResponse>(post_media.delete(post_id, id), {
            params: {}
        });
    }
}

export class Product {
    static async getProductAll({id}: D, {
        limit,
        offset,
        ordering,
        search
    }: IGetPostAllParams): Promise<AxiosResponse<IGetPostAllResponse<IGetPostAllResponseResultsProducts>>> {
        return await $smms_public.get<IGetPostAllResponse<IGetPostAllResponseResultsProducts>>(product_url.get_all(id), {
            params: {
                limit,
                offset,
                ordering,
                search
            }
        });
    }

    static async postProduct({id}: D, {
        product,
        qty,
        specialist,
        post
    }: IPostProductAllRequestBody): Promise<AxiosResponse<IGetPostAllResponseResultsProducts>> {
        return await $smms_public.post<IGetPostAllResponseResultsProducts>(product_url.post(id), {
            params: {
                product,
                qty,
                specialist,
                post
            }
        });
    }

    static async getProduct({post_id, id}: D1): Promise<AxiosResponse<IGetPostAllResponseResultsProducts>> {
        return await $smms_public.get<IGetPostAllResponseResultsProducts>(product_url.get(post_id, id), {
            params: {}
        });
    }

    static async patchProduct({post_id, id}: D1, {
        product,
        qty,
        specialist,
        post
    }: IPostProductAllRequestBody): Promise<AxiosResponse<IGetPostAllResponseResultsProducts>> {
        return await $smms_public.patch<IGetPostAllResponseResultsProducts>(product_url.patch(post_id, id), {
            params: {
                product,
                qty,
                specialist,
                post
            }
        });
    }

    static async deleteProduct({post_id, id}: D1): Promise<AxiosResponse<IDeletePostResponse>> {
        return await $smms_public.delete<IDeletePostResponse>(product_url.delete(post_id, id), {
            params: {}
        });
    }
}