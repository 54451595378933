/**
 * Created by Hennadiy on 13.02.2023
 */

import React, {useContext, useState} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
// import {useTranslation} from 'react-i18next';
import {MySwitch} from 'shared/ui/components/client';
import {FilterDrawer} from 'entities/general';

import {Context} from '../lib/context';

export const QuizFilters = observer(() => {
    // const {t} = useTranslation();
    const {store} = useContext(Context);
    const [open, setOpen] = useState<boolean>(false);

    const changeType = (type: number) => {
        if (!store.getQuizList.loader.get()) {
            if (store.type === type) {
                store.setType(undefined);
            } else {
                store.setType(type);
            }
        }
    };

    return (
        <FilterDrawer open={open} setOpen={setOpen} label={'Filters'} active={store.type !== undefined}>
            <StartLabel onClick={() => changeType(1)}>
                <StartLabelTitle>Синхронные квизы</StartLabelTitle>
                <MySwitch checked={store.type === 1}/>
            </StartLabel>
            <StartLabel onClick={() => changeType(2)}>
                <StartLabelTitle>Асинхронные квизы</StartLabelTitle>
                <MySwitch checked={store.type === 2}/>
            </StartLabel>
        </FilterDrawer>
    );
});


const StartLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;
const StartLabelTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: var(--black);
  user-select: none;
  -webkit-user-select: none;
`;