/**
 * Created by Hennadiy on 10.03.2023
 */

import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import {VolumeHigh, VolumeSlash} from 'iconsax-react';

type Props = {
    url: string;
    muted: boolean;
    setMuted: Dispatch<SetStateAction<boolean>>;
}

export const PostVideoPlayer = ({url, muted, setMuted}: Props) => {
    const [playing, setPlaying] = useState<boolean>(true);

    const ref = useRef<ReactPlayer>(null);
    const element = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let observerRefValue: HTMLDivElement | null = null;

        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;

            if (entry.isIntersecting) {
                setPlaying(true);

            } else {
                setPlaying(false);
            }
        }, {
            root: null,
            threshold: 0.78
        });

        if (element.current) {
            observer.observe(element.current);
            observerRefValue = element.current;
        }

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue);
        };

    }, [element]);


    useEffect(() => {
        let observerRefValue: HTMLDivElement | null = null; // <-- variable to hold ref value

        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;

            if (!entry.isIntersecting) {
                if (ref.current) {
                    ref.current.seekTo(0);
                }
            }
        }, {
            root: null,
        });

        if (element.current) {
            observer.observe(element.current);
            observerRefValue = element.current; // <-- save ref value
        }

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue); // <-- use saved value
        };

    }, [element]);

    return (
        <Root ref={element}>
            <ReactPlayer
                ref={ref}
                playing={playing}
                url={url}
                height={'100%'}
                width={'100%'}
                muted={muted}
                loop={true}
                pip={false}
                playsinline={true}
            />

            <Volume onClick={(e) => {
                e.stopPropagation();
                setMuted(!muted);
            }}>
                {
                    muted ? <VolumeSlash
                        size="16"
                        color="var(--white)"
                    /> : <VolumeHigh
                        size="16"
                        color="var(--white)"
                    />
                }
            </Volume>

        </Root>
    );
};

const Root = styled.div`
  width: 100%;
  min-height: 375px;
  height: 60vh;
  background: var(--black);
  position: relative;
  flex-shrink: 0;
`;

const Volume = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  //padding: 5px;
  position: absolute;
  right: 16px;
  bottom: 16px;
  cursor: pointer;
  height: 36px;
  width: 36px;
`;