/**
 * Created by Hennadiy on 13.02.2023
 */

import {useContext, useEffect, useState} from 'react';

import {Context as globalContext} from 'shared/lib/context';

import {Context} from './context';

export const useList = () => {
    const {store} = useContext(Context);
    const global = useContext(globalContext);

    const abortController = new AbortController();

    const [open, setOpen] = useState(false);

    const [type, setType] = useState<number>(1);

    const [useCode, setUseCode] = useState<boolean>(false);


    return {
        open,
        setOpen,
        type,
        setType,
        useCode,
        setUseCode
    };
};