/**
 * Created by Hennadiy on 11.03.2023
 */

import React, {CSSProperties} from 'react';
import styled from 'styled-components';


type Props = {
    src: string;
    style?: CSSProperties;
}

export const PostImage = ({src, style}: Props) => {
    return (
        <Root style={style} src={src}/>
    );
};

const Root = styled.div`
  width: 100%;
  min-height: 375px;
  height: 60vh;
  background: var(--white);
  position: relative;
  flex-shrink: 0;
  background-image: url(${({src}: Props) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

