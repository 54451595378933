import React from 'react';
import {TPages} from 'gears-react';
import {observer} from 'mobx-react-lite';
import {SessionHeader, IPartSessionList, SessionList} from 'features/quiz';

const Body = observer(() => {
    return <React.Fragment>
        <SessionHeader/>
        <IPartSessionList/>
        <SessionList/>
    </React.Fragment>;
});

export const rooms:TPages = [<Body/>];