/**
 * Created by Hennadiy on 15.02.2023
 */

import {$axios} from './instances';
import {AxiosResponse} from 'axios';

import {urls} from './urls';
import * as types from './types';

export default class Services {
    static async getIsQuizSessionCreator(
        {
            signal
        }: AbortController,
        {
            session_id
        }: types.IGetIsCreatorParams): Promise<AxiosResponse<types.IGetIsCreatorResponse>> {

        return await $axios.get<types.IGetIsCreatorResponse>(urls.getIsQuizSessionCreator(session_id), {
            signal: signal
        });
    }
}