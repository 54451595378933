/**
 * Created by Hennadiy on 13.02.2023
 */

import {useContext, useEffect} from 'react';
import {Context as globalContext} from 'shared/lib/context';

import {Context} from './context';

export const useList = () => {
    const abortController = new AbortController();
    const {store} = useContext(Context);

    const global = useContext(globalContext);

    useEffect(() => {
        store.setList([]);
        store.setLoading(true);
        store.setOffset(0);
        store.setType(undefined);
        store.setCategory(0);
        store.setSearch('');

        store.getQuizSessionList.request(abortController, {
            limit: store.limit,
            offset: 0,
            i_part: true,
            history: false,
        }).then(r => {
            console.log(r);
            store.setLoading(false);
            if (r?.status === 200) {
                store.setList(store.getQuizSessionList.get().results);
            } else {
                if (!abortController.signal.aborted) {
                    global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                }
            }
        });

        return () => {
            store.setList([]);
            store.setLoading(true);
            store.setOffset(0);
            store.setType(undefined);
            store.setCategory(0);
            store.setSearch('');
            abortController.abort();
        };

    }, []);

    useEffect(() => {
        if (!store.getQuizSessionList.loader.get()) {
            reload();
        }
    }, [store.offset]);

    useEffect(() => {
        const timer = setTimeout(() => {

            if (!store.getQuizSessionList.loader.get()) {
                store.setList([]);
                store.setOffset(0);
                store.setType(undefined);
                store.setCategory(0);
                store.getQuizSessionList.request(abortController, {
                    limit: store.limit,
                    offset: 0,
                    search: store.search,
                    i_part: true,
                    history: false,
                }).then(r => {
                    console.log(r);
                    if (r?.status === 200) {
                        store.setList(store.getQuizSessionList.get().results);
                    } else {
                        if (!abortController.signal.aborted) {
                            global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                        }
                    }
                });
            }
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [store.search]);

    useEffect(() => {
        if (!store.getQuizSessionList.loader.get()) {
            store.setList([]);
            store.setOffset(0);
            store.getQuizSessionList.request(abortController, {
                limit: store.limit,
                offset: 0,
                search: store.search,
                i_part: true,
                history: false,
                type: store.type,
                category: store.category !== 0 ? store.category : undefined,
            }).then(r => {
                console.log(r);
                if (r?.status === 200) {
                    store.setList(store.getQuizSessionList.get().results);
                } else {
                    if (!abortController.signal.aborted) {
                        global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                    }
                }
            });
        }
    }, [store.type, store.category]);
    
    const reload = () => {
        store.getQuizSessionList.request(abortController, {
            limit: store.limit,
            offset: store.offset,
            search: store.search,
            i_part: true,
            history: false,
            type: store.type,
            category: store.category !== 0 ? store.category : undefined,
        }).then(r => {
            console.log(r);
            if (r?.status === 200) {
                const new_list = store.getQuizSessionList.get().results.filter((session) =>
                    store.getList().every(item => item.id !== session.id));

                if (store.offset <= 0) {
                    store.setList(store.getQuizSessionList.get().results);
                } else {
                    store.setList([...store.getList(), ...new_list]);
                }
            } else {
                if (!abortController.signal.aborted) {
                    global.store.alert.set(true, 'error', `${r?.data[0].message}`);
                }
            }
        });
    };
};

