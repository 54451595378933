/**
 * Created by Hennadiy on 14.02.2023
 */
import React, {Dispatch, SetStateAction} from 'react';
import styled from 'styled-components';
import {InputAdornment} from '@mui/material';
import {ArrowDown2} from 'iconsax-react';

import {Dialog} from 'shared/ui/components/general';
import {Input} from 'shared/ui/components/client';

type Props = {
    children?: React.ReactNode,
    value: any;
    label?: string;
    error?: boolean;
    required?: boolean;
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>;
    icon?: React.ReactNode;
}

export const InputDrawer = ({value, label, children, error, required, open, setOpen, icon}: Props) => {

    return (
        <Root>
            <Dialog button={
                <Input
                    error={error}
                    onClick={(e) => {
                        setOpen(true);
                    }}
                    required={required}
                    InputProps={{
                        readOnly: true,
                        endAdornment: <InputAdornment position="end">
                            {icon || <ArrowDown2
                                size="24"
                                color="#7F92A0"
                            />}
                        </InputAdornment>,
                    }}
                    label={label}
                    value={value}
                    focused={false}
                />

            }
                    open={open}
                    setOpen={setOpen}
                    label={label}
            >
                {children}
            </Dialog>
        </Root>
    );
};

const Root = styled.div`
  width: 100%;
  cursor: pointer;

  * {
    cursor: pointer;
  }
`;
