import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';

import {Card, CardsLabel} from 'shared/ui/components/quiz';
import {InfiniteScroll} from 'shared/ui/components/general';
import {checkNull} from 'shared/lib/helpers';
import {NoData} from 'shared/ui/components/client';
import {path} from 'shared/const';

import {useList} from '../lib/hooks';
import {Context} from '../lib/context';
import {withProviders} from '../providers';
import no_data_img from '../assets/no_data_img.svg';

const List = observer(() => {
    const {store} = useContext(Context);
    useList();
    const loaders = [1, 2];
    const navigate = useNavigate();

    if (store.loading) {
        return <Root>
            <CardsLabel>
                {loaders.map((e, i) => <Card key={i} isLoading={true}/>)}
            </CardsLabel>
        </Root>;
    }

    return (
        <React.Fragment>
            <Root>
                <InfiniteScroll offset={store.offset} nextOffset={store.getQuizList.get().next_offset}
                                setOffset={(offset) => {
                                    store.setOffset(offset);
                                }}
                                isLoading={store.getQuizList.loader.get()}
                                totalCount={store.getQuizList.get().count}>

                    <CardsLabel>
                        {store.getList().map((item) =>
                            <Card
                                onClick={() => navigate(path.quiz.getQuestions(item.id))}
                                key={item.id}
                                isLoading={false}
                                contentProps={{
                                    src: `${checkNull(item.images[0]) ? item.images[0].file : ''}`,
                                    title: `${item.name}`,
                                    creator: `${checkNull(item.creator) || item.creator}`,
                                    category: `${item.category ? item.category.name : 'asd'}`,
                                    question_number: item.question_number,
                                    time: `${item.question_time} sec`,
                                    // users: `${item.}`,
                                }}
                                actionsProps={{
                                    title: 'Income',
                                    entrance_fee: `Free`,
                                    blue_label_title: `Game play: ${item.session_number}`
                                }}
                            />)}

                        {
                            store.getQuizList.loader.get() &&
                            loaders.map((e, i) => <Card key={i} isLoading={true}/>)
                        }
                    </CardsLabel>

                </InfiniteScroll>

                {
                    !store.getQuizList.loader.get() &&
                    store.getQuizList.get().results !== undefined &&
                    store.getQuizList.get().results.length <= 0 &&
                    <NoData
                        title={'You don`t have own Quiz'}
                        subtitle={'Create own Quiz or questionnaire'}
                        src={no_data_img}
                        img_width={'160px'}
                    />
                }
            </Root>
        </React.Fragment>
    );
});

const Lazy = () => {
    return <List/>;
};

export default withProviders(Lazy);

const Root = styled.div`
  width: 100%;
  padding: 16px 16px 112px 16px;
  position: relative;
`;