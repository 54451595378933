export const post = {
    get_all(){
        return `/SMMS/api/v1.0/public/post/`;
    },
    post(){
        return `/SMMS/api/v1.0/public/post/`;
    },
    get(id:number){
        return `/SMMS/api/v1.0/public/post/${id}/`;
    },
    patch(id:number){
        return `/SMMS/api/v1.0/public/post/${id}/`;
    },
    delete(id:number){
        return `/SMMS/api/v1.0/public/post/${id}/`;
    },
    patch_like(id:number){
        return `/SMMS/api/v1.0/public/post/${id}/like/`;
    },
    post_repost(id:number){
        return `/SMMS/api/v1.0/public/post/${id}/repost/`;
    },
    get_advert(){
        return `/SMMS/api/v1.0/public/post/advert/`;
    }
};
export const comment = {
    get_all(post_id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/comment/`;
    },
    post(post_id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/comment/`;
    },
    get(post_id:number, id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/comment/${id}/`;
    },
    patch(post_id:number, id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/comment/${id}/`;
    },
    delete(post_id:number, id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/comment/${id}/`;
    },
    patch_like(post_id:number, id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/comment/${id}/like/`;
    },
    post_reply(post_id:number, id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/comment/${id}/reply/`;
    }
};
export const post_media = {
    get_all(post_id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/media/`;
    },
    post(post_id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/media/`;
    },
    get(post_id:number, id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/media/${id}/`;
    },
    patch(post_id:number, id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/media/${id}/`;
    },
    delete(post_id:number, id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/media/${id}/`;
    }
};
export const product_url = {
    get_all(post_id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/product/`;
    },
    post(post_id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/product/`;
    },
    get(post_id:number, id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/product/${id}/`;
    },
    patch(post_id:number, id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/product/${id}/`;
    },
    delete(post_id:number, id:number){
        return `/SMMS/api/v1.0/public/post/${post_id}/product/${id}/`;
    }
};