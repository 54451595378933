

import {AxiosResponse} from 'axios';
import {ServiceStore} from 'gears-react';
import {makeAutoObservable} from 'mobx';

import {
    D,
    D1,
    IDeletePostResponse,
    IGetCommentAllResponse,
    IGetPostAdvertParams,
    IGetPostAdvertResponse,
    IGetPostAllParams,
    IGetPostAllResponse,
    IGetPostAllResponseResults,
    IGetPostAllResponseResultsMedia, IGetPostAllResponseResultsProducts,
    IPatchPostResponse,
    IPostCommentRequestBody, IPostMediaRequestBody, IPostProductAllRequestBody,
    IPostResponse,
    IRequestBody
} from './types';
import {Comment, Post, PostMedia, Product} from './services';


export class Store {
    constructor() {
        makeAutoObservable(this);
    }

    // public readonly advert = new ServiceStore<[IAdvertParams], IAdvertResponse>(Post.advert, true);

    //////////////////Post
    public readonly getPostAll = new ServiceStore<[IGetPostAllParams], IGetPostAllResponse<IGetPostAllResponseResults>>(Post.getPostAll, false);
    public readonly postPost = new ServiceStore<[IRequestBody], IPostResponse>(Post.postPost, false);
    public readonly getPost = new ServiceStore<[D], IGetPostAllResponseResults>(Post.getPost, false);
    public readonly patchPost = new ServiceStore<[D, IRequestBody], IPatchPostResponse>(Post.patchPost, false);
    public readonly deletePost = new ServiceStore<[D], IDeletePostResponse>(Post.deletePost, false);
    public readonly patchPostLike = new ServiceStore<[D], IGetPostAllResponseResults>(Post.patchPostLike, false);
    public readonly postRepost = new ServiceStore<[D], IPatchPostResponse>(Post.postRepost, false);
    public readonly getPostAdvert = new ServiceStore<[IGetPostAdvertParams], IGetPostAdvertResponse>(Post.getPostAdvert, false);
    //////////////////Comment
    public readonly getCommentAll = new ServiceStore<[D, IGetPostAllParams], IGetPostAllResponse<IGetCommentAllResponse>>(Comment.getCommentAll, false);
    public readonly postComment = new ServiceStore<[D, IPostCommentRequestBody], IGetCommentAllResponse>(Comment.postComment, false);
    public readonly getComment = new ServiceStore<[D1], IGetCommentAllResponse>(Comment.getComment, false);
    public readonly patchComment = new ServiceStore<[D1, IPostCommentRequestBody], IGetCommentAllResponse>(Comment.patchComment, false);
    public readonly deleteComment = new ServiceStore<[D1], IDeletePostResponse>(Comment.deleteComment, false);
    public readonly patchCommentLike = new ServiceStore<[D1, IPostCommentRequestBody], IGetCommentAllResponse>(Comment.patchCommentLike, false);
    public readonly postCommentReply = new ServiceStore<[D1, IPostCommentRequestBody], IGetCommentAllResponse>(Comment.postCommentReply, false);
    /////////////////PostMedia
    public readonly getPostMediaAll = new ServiceStore<[D, IGetPostAllParams], IGetPostAllResponse<IGetPostAllResponseResultsMedia>>(PostMedia.getPostMediaAll, false);
    public readonly postPostMedia = new ServiceStore<[D, IPostMediaRequestBody], IGetPostAllResponseResultsMedia>(PostMedia.postPostMedia, false);
    public readonly getPostMedia = new ServiceStore<[D1], IGetPostAllResponseResultsMedia>(PostMedia.getPostMedia, false);
    public readonly patchPostMedia = new ServiceStore<[D1, IPostMediaRequestBody], IGetPostAllResponseResultsMedia>(PostMedia.patchPostMedia, false);
    public readonly deletePostMedia = new ServiceStore<[D1], IDeletePostResponse>(PostMedia.deletePostMedia, false);
    /////////////////Product
    public readonly getProductAll = new ServiceStore<[D, IGetPostAllParams], IGetPostAllResponse<IGetPostAllResponseResultsProducts>>(Product.getProductAll, false);
    public readonly postProduct = new ServiceStore<[D, IPostProductAllRequestBody], IGetPostAllResponseResultsProducts>(Product.postProduct, false);
    public readonly getProduct = new ServiceStore<[D1], IGetPostAllResponseResultsProducts>(Product.getProduct, false);
    public readonly patchProduct = new ServiceStore<[D1, IPostProductAllRequestBody], IGetPostAllResponseResultsProducts>(Product.patchProduct, false);
    public readonly deleteProduct = new ServiceStore<[D1], IDeletePostResponse>(Product.deleteProduct, false);




}