/**
 * Created by Hennadiy on 16.02.2023
 */

import {$axios} from './instances';
import {AxiosResponse} from 'axios';

import {urls} from './urls';
import * as types from './types';

export default class Services {
    static async getQuizCategoryList(
        {
            signal
        }: AbortController,
        {
            limit,
            offset,
            ordering,
            search
        }: types.IGetQuizCategoryAllParams): Promise<AxiosResponse<types.IListDataResponse<types.IGetQuizCategoryAllResponse>>> {
        return await $axios.get<types.IListDataResponse<types.IGetQuizCategoryAllResponse>>(urls.getQuizCategoryList(), {
            signal: signal,
            params: {
                limit,
                offset,
                ordering,
                search
            }
        });
    }
}