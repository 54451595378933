/**
 * Created by Hennadiy on 05.03.2023
 */
import {getRoutes, Routing} from 'gears-react';
import * as page from 'pages';
import {path} from 'shared/const';

import {post} from '../post';

const servicesRoutes = getRoutes([
    ['*', [<div>error</div>]],
    ['/', page.client.main],
    [path.quiz.main, page.quiz.rooms],
]);

const publicRoutes = getRoutes([
    ['*', [<div>error</div>]],
    [`${path.post.main}/*`, [<Routing routes={post.publicRoutes}/>]],
    [path.stream.main, [<div>stream list</div>]],
]);

const protectedRoutes = getRoutes([
    ['/', page.post.feed],

    [`${path.post.main}/*`, [<Routing routes={post.protectedRoutes}/>]],
    [`${path.client.services}/*`, [<Routing routes={servicesRoutes}/>]],
    [`${path.profile.main}`, [<div>profile</div>]],
]).concat(publicRoutes);

export const client = {protectedRoutes, publicRoutes};