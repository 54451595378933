/**
 * Created by Hennadiy on 28.02.2023
 */
import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';

import {ErrorFallback} from 'shared/ui/components/general';

import {Props} from '../lib/props';
import {withProviders} from '../providers';
import {QuestionNumberList} from './QuestionNumberList';

const Lazy = ({...props}: Props) => {
    return <ErrorBoundary FallbackComponent={ErrorFallback}><QuestionNumberList {...props}/></ErrorBoundary>;
};

export default withProviders(Lazy);