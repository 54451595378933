import React, {useContext, useEffect} from 'react';
import {useLocation} from 'react-router';
import {useNavigate} from 'react-router-dom';
import styled from '@emotion/styled';
import {Avatar, Badge} from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import {Category2, Home} from 'iconsax-react';
import {observer} from 'mobx-react-lite';

import {ums} from 'shared/api/ums';
import {Footer} from 'shared/ui/components/general/Footer';
import {path} from 'shared/const';

export const BottomBar = observer(() => {
    const account = useContext(ums.account.Context);

    const location = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = React.useState<string>(location.pathname.split('/')[1]);

    useEffect(() => {
        if (location.pathname.split('/')[1] !== '') {
            setValue(`/${location.pathname.split('/')[1]}`);
        } else {
            setValue('/');
        }
    }, []);

    if (account.store.getIsAuth()) {
        return (
            <Footer>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                        navigate(newValue);
                    }}
                >
                    <StyledBottomNavigationAction value={'/'} icon={
                        <Badge badgeContent={0} color="error">
                            <Home
                                size="24"
                                color={value === '/' || value === path.post.main ? '#1DA1F2' : '#262626'}
                                variant={value === '/' || value === path.post.main ? 'Bold' : 'Outline'}
                            />
                        </Badge>
                    }/>

                    <StyledBottomNavigationAction value={path.client.services} icon={
                        <Badge badgeContent={0} color="error">
                            <Category2
                                size="24"
                                color={value === path.client.services ? '#1DA1F2' : '#262626'}
                                variant={value === path.client.services ? 'Bold' : 'Outline'}
                            />
                        </Badge>
                    }/>

                    <StyledBottomNavigationAction value={path.profile.main} icon={
                        <StyledAvatar/>
                    }/>

                </BottomNavigation>
            </Footer>
        );
    }

    return (
        <Footer style={{
            background: 'linear-gradient(143.49deg, #1DA1F2 0%, #003CC5 125.35%)'
        }}>
            <MyBottomNavigation>
                <NavOutlineButton onClick={() => navigate(path.auth.login)}>
                    Войти
                </NavOutlineButton>
                <NavWhiteButton onClick={() => navigate(path.auth.signup)}>
                    Зарегистрироваться
                </NavWhiteButton>
            </MyBottomNavigation>
        </Footer>
    );
});

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  min-width: auto;

  .MuiTouchRipple-root {
    * {
      color: rgba(38, 38, 38, 0.24);
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;

const NavButton = styled.div`
  border-radius: 9999px;
  font-weight: 500;
  font-size: 16px;
  padding-left: 16px;
  padding-right: 16px;
  height: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 78px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  transition: all .3s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NavOutlineButton = styled(NavButton)`
  color: var(--white);
  width: 30%;
  border: 1px white solid;

  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const NavWhiteButton = styled(NavButton)`
  color: var(--black);
  background-color: white;
  width: 70%;

  :hover {
    background: rgb(215, 215, 215);
  }
`;

const MyBottomNavigation = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  gap: 16px;
  max-width: 500px;
  margin: 0 auto;
`;