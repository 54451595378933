/**
 * Created by Hennadiy on 28.02.2023
 */
import React from 'react';

import {Props} from '../lib/props';
import {withProviders} from '../providers';

import {CheckQuizSessionRole} from './CheckQuizSessionRole';

const Lazy = ({...props}: Props) => {
    return <CheckQuizSessionRole {...props}/>;
};

export default withProviders(Lazy);