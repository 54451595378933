/**
 * Created by Hennadiy on 14.02.2023
 */

import React from 'react';
import {TPages} from 'gears-react';
import {observer} from 'mobx-react-lite';

import {QuizHeader, QuizList} from 'features/quiz';

import {ListQuizBottomBar} from 'entities/quiz';

const Body = observer(() => {
    return <React.Fragment>
        <QuizHeader/>
        <QuizList/>
        <ListQuizBottomBar/>
    </React.Fragment>;
});

export const list:TPages = [<Body/>];