import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';

import {CheckPhone, CheckPvc, CheckUsername, CheckPassword, CreateUser} from 'features/auth';
import {TranslationButtonV2} from 'features/general';

import {SignUpLayout} from 'entities/auth';

import {StepperRender} from 'shared/ui/components/general';

export const SignUp = observer(() => {
    const [next, setNext] = useState<boolean>(false);

    const [phone, setPhone] = useState<string>('');
    const [pvc, setPvc] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    return (
        <SignUpLayout leftTop={<TranslationButtonV2/>}>
            <StepperRender next={next}>
                <CheckPhone
                    phone={phone}
                    setPhone={setPhone}
                    next={() => setNext(!next)}
                />
                <CheckPvc
                    phone={phone}
                    pvc={pvc}
                    setPvc={setPvc}
                    next={() => setNext(!next)}
                />
                <CheckUsername
                    username={username}
                    setUsername={setUsername}
                    next={() => setNext(!next)}
                />
                <CheckPassword
                    password={password}
                    setPassword={setPassword}
                    next={() => setNext(!next)}
                />
                <CreateUser
                    username={username}
                    phone={phone}
                    password={password}
                />
            </StepperRender>
        </SignUpLayout>
    );
});