/**
 * Created by Hennadiy on 16.02.2023
 */

import {$axios} from './instances';
import {AxiosResponse} from 'axios';

import {urls} from './urls';
import * as types from './types';

export default class Services {
    static async postCheckUser(
        {
            signal
        }: AbortController,
        {
            phone,
            passport_series,
            passport_number,
            login_params
        }: types.ICheckUserParams): Promise<AxiosResponse<types.ICheckUserResponse>> {

        return await $axios.post<types.ICheckUserResponse>(urls.postCheckUser(), {
            phone,
            passport_series,
            passport_number,
        }, {
            signal:signal,
            params: {
                login_params
            }
        });
    }
}