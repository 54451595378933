/**
 * Created by Hennadiy on 15.02.2023
 */
import axios from 'axios';

export const $axios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

$axios.interceptors.request.use((config) => {
    if (!config?.headers) {
        throw new Error('Expected \'config\' and \'config.headers\' not to be undefined');
    }
    if (localStorage.getItem('access')) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('access')!}`;
    }
    if (localStorage.getItem('language')) {
        config.headers['Accept-Language'] = localStorage.getItem('language')!.split('"')[1];
    }
    return config;
});