
import React from 'react';
import styled from 'styled-components';

import logo from './assets/HeaderLogo.svg';


export const HeaderLogo = () => {
    return <Root src={logo}/>;
};

const Root = styled.img`
  width: 64px;
  user-select: none;
  pointer-events: none;
  -webkit-user-select: none;
`;