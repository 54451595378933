/**
 * Created by Hennadiy on 18.02.2023
 */
import styled from "styled-components";

export const Backdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;

  animation-name: growBackdrop;
  animation-duration: .2s;

  @keyframes growBackdrop {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;