import moment from 'moment';

export const withoutSlash = (path: string) => {
    return path.split('/')[1];
};

export const checkNull = (value: any) => {
    if (value === null) return undefined;
    return value;
};

export const checkIsPhone = (value: string) => {
    return value[0] === '+';
};

export const getFormatDate = (date: string) => {
    return `${moment.parseZone(date).format('DD.MM.YYYY')} at ${moment.parseZone(date).format('HH:mm')}`;
};

export const IsNumber = (value: any) => {
    return value !== undefined && value !== null && !isNaN(Number(value));
};

export function containsNumbers(str: string) {
    return /\d/.test(str);
}

export function containsOnlyNumbers(str: string) {
    return /^[0-9]+$/.test(str);
}