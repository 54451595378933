/**
 * Created by Hennadiy on 04.03.2023
 */

import React from 'react';
import {observer} from 'mobx-react-lite';

import {LogoWithTitle} from 'shared/ui/components/client';

import {useCheckUser} from '../lib/hooks';
import {Props} from '../lib/props';

import {Form} from './Form';

export const CheckPhone = observer((props: Props) => {
    const {error, loader, handleSubmit} = useCheckUser(props);

    return (<React.Fragment>
            <LogoWithTitle
                title={'Connect with your friends today!'}
                logoTitle={'Create account'}
                subtitle={'Enter the mobile number'}
            />
            <Form
                handleSubmit={handleSubmit}
                loader={loader}
                value={props.phone}
                setValue={props.setPhone}
                error={error}
            />
        </React.Fragment>
    );
});