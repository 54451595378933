/**
 * Created by Hennadiy on 16.02.2023
 */

import {$axios} from './instances';
import {AxiosResponse} from 'axios';

import {urls} from './urls';
import * as types from './types';

export default class Services {
    static async postNewQuizSession(
        {
            quiz_id,
            expected_participant,
            use_code,
            type,
            expected_date,
            reward,
            expected_end_date,
            winner_number,
            question_time
        }: types.IPostNewQuizSessionParams): Promise<AxiosResponse<types.IPostNewQuizSessionResponse>> {
        return await $axios.post<types.IPostNewQuizSessionResponse>(urls.postNewQuizSession(quiz_id), {
            expected_participant,
            use_code,
            type,
            expected_date,
            reward,
            expected_end_date,
            winner_number,
            question_time,
        });
    }
}