/**
 * Created by Hennadiy on 21.02.2023
 */


import React, {Dispatch, SetStateAction} from 'react';
import styled from 'styled-components';
import {useWindowSize} from 'usehooks-ts';

import {SwipeableEdgeDrawer} from './components/SwipeableEdgeDrawer';
import {DesktopDialog} from './components/DesktopDialog';


type Props = {
    children?: React.ReactNode,
    label?: string;

    button: React.ReactNode;
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export const Dialog = ({label, children, open, setOpen, button}: Props) => {

    const {width} = useWindowSize();

    return (
        <React.Fragment>
            {button}
            {
                width >= 500 ? <DesktopDialog open={open} setOpen={setOpen} label={label}>
                    <Block>
                        {children}
                    </Block>
                </DesktopDialog> : <SwipeableEdgeDrawer open={open} setOpen={setOpen} label={label}>
                    <Block>
                        {children}
                    </Block>
                </SwipeableEdgeDrawer>
            }

        </React.Fragment>
    );

};

const Block = styled.div`
  flex-direction: column;
  display: flex;
  gap: 24px;
`;