import React from 'react';
import {Divider, Skeleton} from '@mui/material';
import styled from 'styled-components';

import {BaseCardProps} from './types';

export const BaseCard = ({isLoading, content, actions, backgroundColor, onClick}: BaseCardProps) => {

    if (isLoading === false) {
        return <Root backgroundColor={backgroundColor || '#fff'} onClick={onClick}>
            <Content>
                {content}
            </Content>
            <Divider sx={{width: '100%', borderColor: '#F0F0F0', borderWidth: '1px'}}/>
            <Actions>
                {actions}
            </Actions>
        </Root>;
    }
    return <Skeleton sx={{borderRadius: '12px', transform: 'none'}} width={'100%'} height={171}/>;

};

type RootProps = {
    backgroundColor?: string;
}

const Root = styled.div`
  width: 100%;
  background-color: ${({backgroundColor}: RootProps) => backgroundColor};
  box-shadow: ${({backgroundColor}: RootProps) => backgroundColor ? '0px 0px 20px rgba(0, 0, 0, 0.08)' : 'none'};
  border-radius: 12px;
  cursor: pointer;

  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;

  * {
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
  }
`;
const Content = styled.div`
  padding: 12px;
  height: 114px;
`;
const Actions = styled.div`
  padding: 12px;
  height: 55px;
  align-items: center;
`;