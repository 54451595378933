
import React from 'react';

import {Context} from '../lib/context';
import {Store} from '../lib/store';
import {Props} from '../lib/props';

const store = new Store();

export const withStore = (component: ({...args}: Props) => React.ReactNode) => ({...args}: Props) => (
    <Context.Provider value={{store}}>
        {component({...args})}
    </Context.Provider>
);