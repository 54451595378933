/**
 * Created by Hennadiy on 14.02.2023
 */

import React from 'react';
import styled from 'styled-components';
import {ArrowLeft2} from 'iconsax-react';
import {useNavigate} from 'react-router-dom';

import {path} from 'shared/const';
import {Header} from 'shared/ui/components/general';


type Props = {
    children?: React.ReactNode;
}

export const CreateQuizLayout = ({children}: Props) => {
    const navigate = useNavigate();

    return (<>
            <Header>
                <Row onClick={() => navigate(path.quiz.getList())} style={{
                    cursor: 'pointer',
                }}>
                    <ArrowLeft2
                        size="24"
                        color={`#262626`}
                        style={{
                            flexShrink: 0
                        }}
                    />
                    <p style={{color: '#262626'}}>Create quiz</p>
                </Row>
            </Header>
            <Root>
                {children}
            </Root>
        </>
    );
};

const Root = styled.div`
  width: 100%;
  padding: 16px;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--black);
  font-weight: 500;
  font-size: 16px;
`;
